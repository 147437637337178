import { call, put, takeLatest } from "redux-saga/effects";
import * as Api from "../services/TransactionService";
import {
  ACTION_GET_TRANSACTIONS,
  setTransactionsPlaidExpenseError,
} from "../actions/transactionAction";
import store from "../store";
import { refreshUser } from "../actions/userAction";

function* fetchTransactions() {
  try {
    const { username } = yield store.getState().app.user;
    if (username) {
      const expenseErrors = yield call(Api.getErrorTransactions, username);
      yield put(setTransactionsPlaidExpenseError(expenseErrors));
    }
  } catch (e) {
    if (e.status === 401) {
      yield put(refreshUser(undefined));
    } else {
      console.error(e);
    }
  }
}

export default function* transactionsSaga() {
  yield takeLatest(ACTION_GET_TRANSACTIONS, fetchTransactions);
}
