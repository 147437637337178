const APP_SIGN_KEY = "exhut-web-signature";
const APP_TERMS_AND_COND = "exhut-web-terms";

export function shouldShowTermsConditions() {
  const termsFromLocalStorage = _getFromSessionStorage(APP_TERMS_AND_COND);
  return !termsFromLocalStorage;
}

export function hideShowTermsConditions() {
  _setInSessionStorage(APP_TERMS_AND_COND, "false");
}

export function getSignature() {
  return _getFromLocalStorage(APP_SIGN_KEY);
}

export function setSignature(value) {
  _setInLocalStorage(APP_SIGN_KEY, value);
}

function _getFromLocalStorage(key) {
  return localStorage.getItem(key);
}

function _setInLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

function _getFromSessionStorage(key) {
  return sessionStorage.getItem(key);
}

function _setInSessionStorage(key, value) {
  sessionStorage.setItem(key, value);
}
