import { ACTION_SET_SAVINGS_TOTAL } from "../actions/savingsAction";

const initState = {};

export default function savings(state = initState, action) {
  switch (action.type) {
    case ACTION_SET_SAVINGS_TOTAL:
      return {
        ...state,
        total: action.payload,
      };
    default:
      return state;
  }
}
