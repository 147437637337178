import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export function getLedgers() {
  const url = new FNXConnection().getURL() + "/ledgers";
  return executeService(url);
}

export function getLedgerById(id) {
  const url = new FNXConnection().getURL() + "/ledgers/" + id;
  return executeService(url);
}

export function createLedger(name, fromDate, toDate, destination) {
  const url = new FNXConnection().getURL() + "/ledgers";
  return executeService(url, "POST", { name, currency, shared }, 201, "json");
}

export function deleteLedger(id) {
  const url = `${new FNXConnection().getURL()}/ledgers/${id}`;
  return executeService(url, "DELETE", undefined, 204, "restrict");
}

export function getTripExpenses(groupid, tripid) {
  const url =
    new FNXConnection().getURL() +
    "/trips/" +
    groupid +
    "/" +
    tripid +
    "/expenses";
  return executeService(url);
}

export function getTripExpensesTotal(groupid, tripid) {
  const url =
    new FNXConnection().getURL() +
    "/trips/" +
    groupid +
    "/" +
    tripid +
    "/expenses/total";
  return executeService(url);
}

export function createTripExpense(username, name, date, amount, tripid) {
  const url = new FNXConnection().getURL() + "/expense";
  return executeService(
    url,
    "PUT",
    { username, name, date, amount, type: "trip", tripid },
    201,
    "json",
  );
}
