export const ACTION_SET_ACCOUNTS = "SET_ACCOUNTS";
export const ACTION_GET_ACCOUNTS = "GET_ACCOUNTS";
export const ACTION_DELETE_ACCOUNTS = "ACTION_DELETE_ACCOUNTS";
export const ACTION_ADD_ACCOUNTS = "ADD_ACCOUNTS";
export const ACTION_UPDATE_ACCOUNT = "ACTION_UPDATE_ACCOUNTS";
export const ACCOUNTS_ACTION_STATUS = "ACCOUNTS_ACTION_STATUS";
export const ACTION_EDIT_ACCOUNT = "ACTION_EDIT_ACCOUNT";

/**
 * Set accounts
 * @param {*} payload
 * @returns
 */
export function setAccounts(payload) {
  return {
    type: ACTION_SET_ACCOUNTS,
    payload,
  };
}

/**
 *
 * @returns
 */
export function getAccounts() {
  return {
    type: ACTION_GET_ACCOUNTS,
  };
}

/**
 *
 * @param {Object} payload - { username, ccid }
 * @returns
 */
export function deleteAccount(payload) {
  return {
    type: ACTION_DELETE_ACCOUNTS,
    payload,
  };
}

/**
 *
 * @param {Object} payload - { name }
 * @returns
 */
export function addAccount(payload) {
  return {
    type: ACTION_ADD_ACCOUNTS,
    payload,
  };
}

export function updateAccount(accid, newAccountName) {
  return {
    type: ACTION_UPDATE_ACCOUNT,
    payload: {
      id: accid,
      name: newAccountName,
    },
  };
}

export function editAccount(payload) {
  return {
    type: ACTION_EDIT_ACCOUNT,
    payload,
  };
}

export function setStatus(type, text) {
  return {
    type: ACCOUNTS_ACTION_STATUS,
    payload: {
      type,
      text,
    },
  };
}
