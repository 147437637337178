import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export async function getTillDateExpenses(groupid) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/analysis/tillDate`;
  return executeService(url);
}

export async function getAnalyticsByCategory(groupid) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/analysis/previous/6/category`;
  return executeService(url);
}

export async function getAnalyticsBySubCategory(groupid) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/analysis/previous/6/subcategory`;
  return executeService(url);
}

export async function getAnalyticsByUsers(groupid) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/analysis/previous/6/byUsers`;
  return executeService(url);
}

export async function getAnalyticsByAccounts(groupid) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/analysis/previous/6/byAccounts`;
  return executeService(url);
}

export async function getUpcomingExpenses(groupid) {
  return executeService(
    `${new FNXConnection().getURL()}/expense/${groupid}/analysis/upcoming`,
  );
}
