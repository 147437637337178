import React from "react";
import { connect } from "react-redux";
import { inflateGroup } from "../../services/GroupService";
import PropTypes from "prop-types";
import { getGroupsIcon, getWarningIcon } from "../../helpers/IconHelper";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

class GroupDisplayComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupname: undefined,
    };
    this.inflateGroupHandler = this.inflateGroupHandler.bind(this);
  }

  async componentDidMount() {
    await this.inflateGroupHandler();
  }

  async inflateGroupHandler() {
    try {
      const group = await inflateGroup(this.props.groupid);
      if (group && group.name) {
        this.setState({
          groupname:
            group.name.length > 20
              ? group.name.substring(0, 15) + "..."
              : group.name,
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.groupid !== this.props.groupid) {
      await this.inflateGroupHandler();
    }
  }

  render() {
    return (
      <Tooltip title="Current Group">
        <div
          id="groups-switch"
          style={{
            minWidth: "10%",
            maxWidth: "20%",
            backgroundColor: "black",
            float: "right",
            padding: "10px",
            borderRadius: "20px",
            height: "20px",
            cursor: "pointer",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <img src={getGroupsIcon()} width={20}></img>
          <Link
            style={{
              color: "#5dfd4b",
              margin: "0 10px",
              textDecoration: "none",
              fontWeight: "bold",
            }}
            to="/settings/groups"
          >
            {this.state.groupname && this.state.groupname}
            {!this.state.groupname && <img src={getWarningIcon()} width={20} />}
          </Link>
        </div>
      </Tooltip>
    );
  }
}

GroupDisplayComponent.propTypes = {
  groupid: PropTypes.string,
  groups: PropTypes.array,
};

function mapStateToProps(state) {
  const { groups, groupid } = state.group;
  return { groups, groupid };
}
export default connect(mapStateToProps)(GroupDisplayComponent);
