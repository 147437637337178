import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getGroupDetails, getGroupUsers } from "../../actions/groupsAction";
import * as DateHelper from "../../helpers/DateHelper";
import { getUsersInGroups2, inflateGroup } from "../../services/GroupService";
import { updateMyDefaultGroup } from "../../services/UserService";
import { leaveGroup } from "../../services/UserService2";
import store from "../../store";
import ModalDialogComponent from "../shared/ModalDialogComponent";
import GroupCreatorComponent from "./GroupCreatorComponent";
import { Link, useHistory } from "react-router-dom";

function mapStateToProps(state) {
  const { user } = state.app;
  const { groups, groupid } = state.group;
  return {
    groups,
    groupid,
    user,
  };
}

export default connect(mapStateToProps)(ViewGroupComponent);

function ViewGroupComponent({ user, groups, groupid }) {
  const { id } = useParams();
  const [group, setGroup] = useState();
  const [usersInGroup, setUsersInGroup] = useState([]);
  const history = useHistory();
  const [statusMsg, setStatusMsg] = useState();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    if (id) {
      inflateGroup(id).then((group) => {
        setGroup(group);
      });
    }
  }, [id]);

  useEffect(() => {
    if (group) {
      getUsersInGroups2(id).then((users) => {
        setUsersInGroup(users);
      });
    }
  }, [group]);

  const leaveGroupHandler = () => {
    leaveGroup(id)
      .then(() => {
        history.push("/settings/groups");
      })
      .catch((err) => {
        setStatusMsg({
          type: "error",
          text: "Whoops! Error trying to exit group. Please try again.",
        });
      });
  };

  const switchGroupHandler = () => {
    updateMyDefaultGroup(group.id)
      .then(() => {
        store.dispatch(getGroupUsers(group.id));

        setStatusMsg({
          type: "success",
          text: "Group successfully updated.",
        });
        store.dispatch(getGroupDetails());
      })
      .catch((err) => {
        console.error(err);
        setStatusMsg({
          type: "error",
          text: "Whoops! Error trying to exit group. Please try again.",
        });
      });
  };

  const renderDefaultGroupPanel = () => {
    if (groupid === group?.id) {
      return <span className="successMsg">This is your default group</span>;
    } else {
      return (
        <a
          className="hyperlink-no-color successMsg"
          onClick={switchGroupHandler}
        >
          Switch to this group{" "}
        </a>
      );
    }
  };

  const goBack = () => {
    history.push("/settings/groups");
  };

  if (group?.created_by === user.username) {
    return <GroupCreatorComponent user={user} group={group} onBack={goBack} />;
  }

  return (
    <div className="margin-center">
      <div className="title">Group : {group?.name}</div>

      <div className="subtitle">
        Created On : {DateHelper.getLocalizedDate(group?.created_at, user)}
      </div>

      <div style={{ marginLeft: "2%" }}>
        <Link to="/settings/groups">Back</Link>
      </div>

      <div style={{ marginTop: "5%" }}>
        {showDeleteConfirm && (
          <ModalDialogComponent
            title={"Leave Group"}
            message={
              "Are you sure? Your expenses and savings data will remain in this group."
            }
            onSuccessTitle={"Yes"}
            onCancelTitle={"No"}
            onSuccess={() => leaveGroupHandler()}
            onCancel={() => setShowDeleteConfirm(false)}
          />
        )}
      </div>

      {renderDefaultGroupPanel()}

      <div style={{ margin: "5%" }}>
        <table className="exhutTable">
          <thead>
            <tr>
              <th>#</th>
              <th>Member</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {usersInGroup.map((user, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>
                    {user.firstName && user.lastName
                      ? user.lastName + ", " + user.firstName
                      : user.email}
                  </td>
                  <td>
                    {group.created_by === user.username ? "Admin" : "Member"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div style={{ margin: "5%" }}>
        <input
          type="button"
          value="Leave"
          className="fnxRed"
          id="leave-group"
          disabled={group?.id === user.groupid}
          onClick={() => setShowDeleteConfirm(true)}
        />
        {group?.id === user.groupid && (
          <span className="errorMsg mediumText">
            {" "}
            To leave this group, you must set to another group as default.{" "}
          </span>
        )}
      </div>
    </div>
  );
}
