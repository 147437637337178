import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export function getIncomeForUser(groupid, username) {
  const url =
    new FNXConnection().getURL() + "/income/" + groupid + "/" + username;
  return executeService(url);
}

export function getIncomeForGroup(groupid) {
  const url = new FNXConnection().getURL() + "/income/" + groupid;
  return executeService(url);
}

export function addIncome(name, amount) {
  const url = new FNXConnection().getURL() + "/income";
  return executeService(url, "PUT", { name, amount }, 201, "restrict");
}

export function deleteIncome(groupid, username, id) {
  const url =
    new FNXConnection().getURL() +
    "/income/" +
    groupid +
    "/" +
    username +
    "/" +
    id;
  return executeService(url, "DELETE", undefined, 204, "restrict");
}
