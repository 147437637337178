import {
  ACTION_SET_SETTINGS,
  ACTION_SET_SETTINGS_ERROR,
} from "../actions/settingsAction";

const initState = {};

export default function settings(state = initState, action) {
  switch (action.type) {
    case ACTION_SET_SETTINGS:
      return {
        ...state,
        include_group_income: action.payload?.include_group_income,
        include_misc_expense_in_total:
          action.payload?.include_misc_expense_in_total,
        include_misc_expense_for_savings:
          action.payload?.include_misc_expense_for_savings,
      };
    case ACTION_SET_SETTINGS_ERROR:
      return {
        ...state,
        settingsError: action.payload,
      };
    default:
      return state;
  }
}
