import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { postSession } from "../../services/UserService";
import { Link } from "react-router-dom";
import { loginSuccess } from "../../actions/userAction";
import store from "../../store";

class SettingsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editUsername: undefined,
    };
  }

  async componentDidMount() {
    try {
      const result = await postSession();
      store.dispatch(loginSuccess(result));
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    return (
      <div className="margin-center">
        <div className="title">Settings</div>

        <div>
          <table style={{ borderSpacing: "4em" }}>
            <tbody>
              <tr>
                <td>
                  <Link to="/settings/groups">Groups</Link>
                </td>
                <td>
                  <span> Set expense preferences. </span>
                </td>
              </tr>

              {this.props.user.entitlement > 2 && (
                <tr>
                  <td>
                    <Link to="/settings/subscriptions">Subscriptions</Link>
                  </td>
                  <td>
                    <span> Set subscriptions or recurring expenses </span>
                  </td>
                </tr>
              )}

              <tr>
                <td>
                  <Link to="/settings/income">Income</Link>
                </td>
                <td>
                  <span> Set income and preferences. </span>
                </td>
              </tr>
              <tr>
                <td>
                  <Link to="/settings/accounts">Accounts</Link>
                </td>
                <td>
                  <span> Add or remove accounts. </span>
                </td>
              </tr>

              <tr>
                <td>
                  <Link to="/settings/security">Security</Link>
                </td>
                <td>
                  <span> Security Settings. </span>
                </td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://hub.expensehut.com/settings/profile"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Profile
                  </a>
                </td>
                <td>
                  <span> Change username and other settings. </span>
                </td>
              </tr>
              <tr>
                <td>
                  <Link
                    target="_blank"
                    to={{ pathname: "https://help.expensehut.com" }}
                  >
                    Help
                  </Link>
                </td>
                <td>
                  <span> Help content. </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, navigate } = state.app;
  return { user, navigate };
}

SettingsComponent.propTypes = {
  user: PropTypes.any.isRequired,
  doLogout: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(SettingsComponent);
