import React from "react";
import PropTypes from "prop-types";
// import store from '../../store';
// import {navigate} from '../../actions/appAction';
import "./AnalysisComponent.css";
import AnalysisTileComponent from "./AnalysisTileComponent";
import { getCurrencySymbol } from "../../helpers/CurrencyHelper";

export default class AnalysisCategoryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      categoryData: undefined,
      subcategoryData: undefined,
    };
  }

  fabricateAnalyticsDataToArray(payload, key) {
    const resultArray = [];
    for (const p of payload) {
      const rowArray = [];
      rowArray.push(p.category);
      rowArray.push(p.amount);
      resultArray.push(rowArray);
    }
    return resultArray;
  }

  render() {
    return (
      <div style={{ display: "flex", float: "left", flexFlow: "wrap" }}>
        {this.props.data.map((d, i) => {
          return (
            <AnalysisTileComponent
              key={i}
              payload={d}
              headerText={d.monthName + " " + d.year}
              subHeaderText={getCurrencySymbol(this.props.user) + " " + d.total}
              primaryColumn={"Category"}
              tableContents={this.fabricateAnalyticsDataToArray(d.data)}
            />
          );
        })}
      </div>
    );
  }
}

AnalysisCategoryComponent.propTypes = {
  data: PropTypes.array.isRequired,
  user: PropTypes.any.isRequired,
};
