export function getEstimatedSavings(payload, store) {
  let includeGroupIncome;
  let includeMiscInSavings;
  const appState = store.getState();
  if (payload) {
    const { include_group_income, include_misc_expense_for_savings } = payload;
    if (include_group_income !== undefined) {
      includeGroupIncome = include_group_income;
    } else {
      includeGroupIncome = appState.settings.include_group_income;
    }
    if (include_misc_expense_for_savings !== undefined) {
      includeMiscInSavings = include_misc_expense_for_savings;
    } else {
      includeMiscInSavings = appState.settings.include_misc_expense_for_savings;
    }
  } else {
    includeGroupIncome = appState.settings.include_group_income;
    includeMiscInSavings = appState.settings.include_misc_expense_for_savings;
  }
  const totalIncome =
    includeGroupIncome === true
      ? appState.income?.groupTotal
      : appState.income?.userTotal;
  let totalExpenses = appState.expense?.monthlyTotal ?? 0;
  // if include misc expenses to savings setting is true, then we must include misc expenses total as well
  if (includeMiscInSavings === true)
    totalExpenses += appState.expense?.misc?.total ?? 0;
  // console.log('total Expenses', totalExpenses);
  const estSavings = totalIncome - totalExpenses;
  return { estSavings, totalExpenses, totalIncome };
}
