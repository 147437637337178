import { call, put, takeLatest } from "redux-saga/effects";
import * as Api from "../services/IncomeService";
import {
  setGroupIncomeTotal,
  ACTION_GET_GROUP_INCOME_TOTAL,
  ACTION_GET_USER_INCOME_TOTAL,
  setUserIncomeTotal,
} from "../actions/incomeAction";
import store from "../store";
import { refreshUser } from "../actions/userAction";

function* fetchGroupIncomeTotal() {
  try {
    const groupid = yield store.getState().group?.groupid;
    if (groupid) {
      const incomes = yield call(Api.getIncomeForGroup, groupid);
      yield put(setGroupIncomeTotal(incomes.total));
    } else {
      console.debug("Cannot fetch group income total with groupid");
    }
  } catch (e) {
    if (e.status === 401) {
      yield put(refreshUser(undefined));
    } else {
      console.error(e);
      yield put(setGroupIncomeTotal(0));
    }
  }
}

function* fetchUserIncomeTotal() {
  try {
    const user = yield store.getState().app.user;
    const groupid = yield store.getState().group?.groupid;
    if (groupid) {
      const incomes = yield call(Api.getIncomeForUser, groupid, user.username);
      yield put(setUserIncomeTotal(incomes.total));
    } else {
      console.debug("Cannot fetch group income total with groupid");
    }
  } catch (e) {
    if (e.status === 401) {
      yield put(refreshUser(undefined));
    } else {
      console.error(e);
      yield put(setUserIncomeTotal(0));
    }
  }
}

export default function* incomeSaga() {
  yield takeLatest(ACTION_GET_GROUP_INCOME_TOTAL, fetchGroupIncomeTotal);
  yield takeLatest(ACTION_GET_USER_INCOME_TOTAL, fetchUserIncomeTotal);
}
