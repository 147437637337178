import React from "react";
import PropTypes from "prop-types";
import RadioButtonComponent from "../../shared/RadioButtonComponent";
import { logoutAll } from "../../../services/LoginService";
import { enableSSO } from "../../../services/SettingsService";
import store from "../../../store";
import { doRefreshUser } from "../../../actions/userAction";
import { connect } from "react-redux";

class OtherSecurityComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutAll: false,
    };
    this.performLogoutAll = this.performLogoutAll.bind(this);
  }

  async performLogoutAll() {
    try {
      await logoutAll();
      this.setState({
        logoutAll: true,
        statusMsg: {
          type: "success",
          text: "All devices are cleared and will be logged out.",
        },
      });
      setTimeout(
        () => this.setState({ logoutAll: false, statusMsg: undefined }),
        1500,
      );
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        console.error(err);
        this.setState({
          statusMsg: {
            type: "error",
            text: "Whoops! Something is not right. Please try again.",
          },
        });
      }
    }
  }

  render() {
    return (
      <div>
        <div className="smalltitle">Other Settings</div>

        <div style={styles.statusMsg}>
          {
            <span
              className={
                this.state.statusMsg?.type === "success"
                  ? "successMsg"
                  : "errorMsg"
              }
            >
              {this.state.statusMsg?.text}
            </span>
          }
        </div>

        <div>
          <div style={styles.container}>
            <div style={styles.label}>
              <div style={styles.labelTitle}>Logout from all devices</div>
              <div style={styles.subLabel}>
                <span>
                  {" "}
                  This will log out all devices that currently using ExpenseHut.{" "}
                </span>
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <RadioButtonComponent
                id="logout-all"
                onChange={this.performLogoutAll}
                checked={this.state.logoutAll}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OtherSecurityComponent.propTypes = {
  user: PropTypes.any,
  doLogout: PropTypes.func.isRequired,
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0px 25px 40px 25px",
  },
  statusMsg: {
    height: "2em",
    textAlign: "center",
    margin: "2% 0%",
  },
  containerPanel: {
    width: "50%",
  },
  label: {
    flex: 3,
  },
  labelTitle: {
    fontSize: "large",
    fontWeight: "bold",
  },
  subLabel: {
    fontSize: "medium",
    marginTop: "1em",
  },
};

function mapStateToProps(state) {
  const { user } = state.app;
  return { user };
}

export default connect(mapStateToProps)(OtherSecurityComponent);
