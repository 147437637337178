import PropTypes from "prop-types";
import React from "react";
import { getCurrencySymbolPerCountry } from "../../helpers/CurrencyHelper";
import { getSavingsEstimate } from "../../services/SavingsService";

export default class SavingsEstimatePanelComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      savingsEstimates: [],
      currentMonthSavingsTotal: 0,
    };
    this.fetchCurrencyEstimates = this.fetchCurrencyEstimates.bind(this);
  }

  async fetchCurrencyEstimates() {
    try {
      const result = await getSavingsEstimate(
        this.props.user.groupid,
        this.props.user.username,
      );
      this.setState({
        savingsEstimates: result?.estimates,
        currentMonthSavingsTotal: result.total,
      });
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        console.error(err);
      }
    }
  }

  async componentDidMount() {
    await this.fetchCurrencyEstimates();
  }

  returnTotal() {
    return (
      <span className="boldText">
        {getCurrencySymbolPerCountry(this.props.user.country_code) +
          this.state.currentMonthSavingsTotal}
      </span>
    );
  }

  returnEstimateAmount(amount) {
    if (!amount) {
      return getCurrencySymbolPerCountry(this.props.user.country_code) + "0";
    }
    const localeAmount =
      this.props.user.country_code === "IND"
        ? amount.toLocaleString("en-IN")
        : amount.toLocaleString("en-US");
    return (
      getCurrencySymbolPerCountry(this.props.user.country_code) + localeAmount
    );
  }

  render() {
    return (
      <div id="estdSavingsDataPanel" className="savings-data-panel">
        <div
          className="divSpread"
          style={{ marginBottom: "20px", marginTop: "0px" }}
        >
          <span className={"subtitle"}> Estimated Savings </span>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <span className={"infoMsg"}>
            {" "}
            Your estimated savings in 6, 9, 12... months are shown based on your
            current month&apos;s savings total of {this.returnTotal()}.
          </span>
        </div>
        <div>
          <table className="exhutTable" id="currencyTable">
            <thead>
              <tr>
                <th> </th>
                <th> In Months </th>
                <th> Amount </th>
                <th> Total = Amount + Current Savings </th>
              </tr>
            </thead>
            <tbody>
              {this.state.savingsEstimates.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item.duration}</td>
                    <td>{this.returnEstimateAmount(item.amount)}</td>
                    <td>
                      {getCurrencySymbolPerCountry(
                        this.props.user.country_code,
                      ) +
                        " " +
                        (item.amount + this.state.currentMonthSavingsTotal)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

SavingsEstimatePanelComponent.propTypes = {
  user: PropTypes.any.isRequired,
  doLogout: PropTypes.func.isRequired,
};
