import { call, put, takeLatest } from "redux-saga/effects";
import * as Api from "../services/LoanService";
import { ACTION_GET_LOANS, setLoans } from "../actions/loansAction";
import store from "../store";
import { refreshUser } from "../actions/userAction";

function* fetchLoans() {
  try {
    const { groupid } = yield store.getState().app.user;
    if (groupid) {
      const loans = yield call(Api.getLoans, groupid);
      yield put(setLoans(loans));
    } else {
      console.debug("Cannot fetch loans with groupid");
    }
  } catch (e) {
    if (e.status === 401) {
      yield put(refreshUser(undefined));
    } else {
      console.error(e);
    }
  }
}

export default function* loansSaga() {
  yield takeLatest(ACTION_GET_LOANS, fetchLoans);
}
