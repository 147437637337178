export const ACTION_SET_SELECTED_MONTH = "SET_SELECTED_MONTH";
export const ACTION_SET_SELECTED_YEAR = "SET_SELECTED_YEAR";
export const ACTION_SET_EDIT_EXPENSE_ID = "SET_EDIT_EXPENSE_ID";
export const ACTION_SET_INTELLIGENT_MODE = "SET_INTELLIGENT_MODE";
export const ACTION_SET_AI_JSON_DATA = "SET_EXPENSE_AI_JSON_DATA";
export const ACTION_GET_AI_JSON_DATA = "GET_EXPENSE_AI_JSON_DATA";
export const ACTION_GET_RELATED_EXPENSES = "GET_RELATED_EXPENSES";
export const ACTION_SET_RELATED_EXPENSES = "SET_RELATED_EXPENSES";
export const ACTION_SET_LEGACY_SELECTED_ACCOUNT = "SET_LEGACY_SELECTED_ACCOUNT";

export const ACTION_SET_AI_JSON_DATA_ERROR = "SET_AI_JSON_DATA_ERROR";
export const ACTION_GET_MISC_EXPENSES_TOTAL = "ACTION_GET_MISC_EXPENSES_TOTAL";
export const ACTION_SET_MISC_EXPENSES_TOTAL = "ACTION_SET_MISC_EXPENSES_TOTAL";

export const ACTION_GET_EXPENSES_TOTAL = "ACTION_GET_EXPENSES_TOTAL";
export const ACTION_SET_EXPENSES_TOTAL = "ACTION_SET_EXPENSES_TOTAL";

export function setSelectedMonth(payload) {
  return {
    type: ACTION_SET_SELECTED_MONTH,
    payload,
  };
}

export function setSelectedYear(payload) {
  return {
    type: ACTION_SET_SELECTED_YEAR,
    payload,
  };
}

/**
 *
 * @param {String} payload - edit expense guid.
 * @returns
 */
export function setEditExpenseId(payload) {
  return {
    type: ACTION_SET_EDIT_EXPENSE_ID,
    payload,
  };
}

export function setIntelligentMode(payload) {
  return {
    type: ACTION_SET_INTELLIGENT_MODE,
    payload,
  };
}

export function setValidatePhraseJSONData(payload) {
  return {
    type: ACTION_SET_AI_JSON_DATA,
    payload,
  };
}

export function getValidatePhraseJSONData(payload) {
  return {
    type: ACTION_GET_AI_JSON_DATA,
    payload,
  };
}

export function setValidatePhraseJSONDataError() {
  return {
    type: ACTION_SET_AI_JSON_DATA_ERROR,
  };
}

export function getRelatedExpenses(payload) {
  return {
    type: ACTION_GET_RELATED_EXPENSES,
    payload,
  };
}

export function setRelatedExpenses(payload) {
  return {
    type: ACTION_SET_RELATED_EXPENSES,
    payload,
  };
}

/**
 * Used to set the selected account/card in legacy mode so the previously selected card is used for the next
 * when the user presses the + button.
 * @param {*} paylod
 */
export function setSelectedAccount(payload) {
  return {
    type: ACTION_SET_LEGACY_SELECTED_ACCOUNT,
    payload,
  };
}

export function getMiscExpensesTotal(payload) {
  return {
    type: ACTION_GET_MISC_EXPENSES_TOTAL,
    payload,
  };
}

export function setMiscExpensesTotal(payload) {
  return {
    type: ACTION_SET_MISC_EXPENSES_TOTAL,
    payload,
  };
}

export function getExpensesTotal(payload) {
  return {
    type: ACTION_GET_EXPENSES_TOTAL,
    payload,
  };
}

export function setExpensesTotal(payload) {
  return {
    type: ACTION_SET_EXPENSES_TOTAL,
    payload,
  };
}
