import React from "react";
import "./MobileLinksComponent.css";

export default class MobileLinksComponent extends React.Component {
  render() {
    return (
      <div
        className={
          this.props.isMobile ? "divCenterAlign divColumn" : "mobile-links"
        }
      >
        <div>
          <span className="largeText">Now available in Android & iOS.</span>
        </div>
        <AndroidAppLink />
        <IOSAppLink />
      </div>
    );
  }
}

export function AndroidAppLink() {
  return (
    <div>
      <a
        href="https://play.google.com/store/apps/details?id=com.snarain.expensehut"
        rel="noreferrer"
        target="_blank"
      >
        <img src="assets/google-play-badge.png" width="150px" />
      </a>
    </div>
  );
}

export function IOSAppLink() {
  return (
    <div>
      <a
        href="https://apps.apple.com/us/app/expense-hut/id1562719499"
        rel="noreferrer"
        target="_blank"
      >
        <img src="assets/apple-store-badge.svg" width="130px" />
      </a>
    </div>
  );
}
