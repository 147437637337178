import { call, put, takeEvery, all } from "redux-saga/effects";
import * as Api from "../services/CreditCardService";
import {
  setAccounts,
  ACTION_GET_ACCOUNTS,
  ACTION_DELETE_ACCOUNTS,
  getAccounts,
  ACTION_ADD_ACCOUNTS,
  ACTION_UPDATE_ACCOUNT,
  setStatus,
  editAccount,
} from "../actions/accountsAction";
import { refreshUser } from "../actions/userAction";
import store from "../store";

function* fetchAccounts() {
  try {
    const user = yield store.getState().app.user;
    const accounts = yield call(Api.getCreditCards, user.username);
    yield put(setAccounts(accounts));
  } catch (e) {
    console.error(e);
    yield put(setAccounts([]));
  }
}

function* deleteAccount(action) {
  try {
    const user = yield store.getState().app.user;
    yield call(Api.deleteCreditCard, user.username, action.payload);
    yield all([
      put(setStatus("success", "Card successfully deleted.")),
      put(getAccounts()),
    ]);
  } catch (e) {
    console.error(e);
    if (e.status === 401) {
      yield put(refreshUser(undefined));
    } else {
      yield put(setStatus("error", e.message));
    }
  }
}

function* addAccount(action) {
  try {
    yield call(Api.createCreditCard, action.payload);
    yield all([
      put(setStatus("success", "Account successfully added.")),
      put(getAccounts()),
    ]);
  } catch (e) {
    console.error(e);
    if (e.status === 401) {
      yield put(refreshUser(undefined));
    } else if (e.status === 500 && e.message.includes("duplicate key")) {
      yield put(
        setStatus(
          "error",
          "Whoops! Perhaps you have entered a duplicate name.",
        ),
      );
    } else {
      yield put(setStatus("error", e.message));
    }
  }
}

function* updateAccount(action) {
  try {
    const user = yield store.getState().app.user;
    const { id, name } = action.payload;
    yield call(Api.updateCreditCard, id, name, user.username);
    yield all([
      put(editAccount(undefined)),
      put(setStatus("success", "Account updated successfully.")),
      put(getAccounts()),
    ]);
  } catch (err) {
    console.error(err);
    if (err.status === 401) {
      yield put(refreshUser(undefined));
    } else if (err.message.includes("unique")) {
      yield put(
        setStatus(
          "error",
          "Whoops! Perhaps you have entered a duplicate name.",
        ),
      );
    } else {
      yield put(setStatus("error", err.message));
    }
  }
}

export default function* accountsSaga() {
  yield takeEvery(ACTION_GET_ACCOUNTS, fetchAccounts);
  yield takeEvery(ACTION_DELETE_ACCOUNTS, deleteAccount);
  yield takeEvery(ACTION_ADD_ACCOUNTS, addAccount);
  yield takeEvery(ACTION_UPDATE_ACCOUNT, updateAccount);
}
