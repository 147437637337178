import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getTripsForMonthYear } from "../../../services/TripService";
import SpinnerComponent, {
  SpinnerComponentColor,
} from "../../shared/SpinnerComponent";

class HomePageTripsWidgetComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trips: [],
    };
  }

  async componentDidMount() {
    this.mounted = true;
    await this.fetchData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async fetchData() {
    try {
      this.setState({ isLoading: true });
      if (this.props.selectedMonth && this.props.selectedYear) {
        const trips = await getTripsForMonthYear(
          this.props.groupid,
          this.props.selectedYear,
          this.props.selectedMonth,
        );
        this.setState({ trips });
      }

      setTimeout(() => {
        if (this.mounted) this.setState({ isLoading: false });
      }, 1500);
    } catch (err) {
      console.error(err);
      this.setState({ isLoading: false });
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.selectedMonth !== this.props.selectedMonth ||
      prevProps.selectedYear !== this.props.selectedYear
    ) {
      await this.fetchData();
    }
  }

  render() {
    return (
      <div className="analysis-tile tile-small">
        <div className="analysis-tile-heading">Trips this Month</div>
        <div className="analysis-tile-subheading">
          {this.state.isLoading && (
            <SpinnerComponent
              size="medium"
              color={SpinnerComponentColor.green}
            />
          )}
          {!this.state.isLoading && (
            <span className="total" id="trip-count" style={{ margin: "0px" }}>
              {this.state.trips.length}
            </span>
          )}
        </div>
        <div className="tile-body">
          <Link className="tripExpense hyperlink" to="/trips">
            View
          </Link>
        </div>
      </div>
    );
  }
}

HomePageTripsWidgetComponent.propTypes = {
  selectedMonth: PropTypes.number,
  selectedYear: PropTypes.number,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  const { user, selectedMonth, selectedYear } = state.app;
  const { groupid } = state.group;
  return { user, selectedMonth, selectedYear, groupid };
}

export default connect(mapStateToProps)(HomePageTripsWidgetComponent);
