import React from "react";
import { createTripExpense } from "../../services/TripService";
import PropTypes from "prop-types";
import CalendarInputComponent from "../shared/CalendarInputComponent";
import moment from "moment";

export default class CreateTripExpenseDialogComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tripExpenseNameEditText: "",
      tripExpenseDateEditText: "",
      tripExpenseDateSQL: "",
      tripExpenseAmountEditText: "",
    };
    this.createTripExpenseHandler = this.createTripExpenseHandler.bind(this);
  }

  async createTripExpenseHandler() {
    try {
      await createTripExpense(
        this.props.user.username,
        this.state.tripExpenseNameEditText,
        moment(this.state.tripExpenseDateSQL).format("YYYY-MM-DD"),
        this.state.tripExpenseAmountEditText,
        this.props.tripid,
      );
      this.props.onSuccess();
    } catch (err) {
      console.error(err);
      this.props.onFailure(err);
    }
  }

  render() {
    return (
      <div className="modal" id="create-trip-expense-dialog">
        <div className="modal-content" style={{ height: "50%" }}>
          <span className="close" onClick={this.props.onCancel}>
            &times;
          </span>
          <div style={{ textAlign: "center" }}>
            <h3> Add Expense To Trip</h3>
          </div>
          <div style={{ margin: "1em 0em 2em 0em", textAlign: "center" }}>
            <table
              className="formTable"
              style={{ width: "100%", borderSpacing: "20px" }}
            >
              <tbody>
                <tr>
                  <td>
                    {" "}
                    Name <sup>*</sup>
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      id="trip-expense-name"
                      onChange={(e) =>
                        this.setState({
                          tripExpenseNameEditText: e.target.value,
                        })
                      }
                    />{" "}
                  </td>
                </tr>

                <tr>
                  <td>
                    {" "}
                    Date <sup>*</sup>{" "}
                  </td>
                  <td>
                    <CalendarInputComponent
                      id="trip-expense-date"
                      onChange={(localizedDate, dateText) =>
                        this.setState({
                          tripExpenseDateEditText: localizedDate,
                          tripExpenseDateSQL: dateText,
                        })
                      }
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    {" "}
                    Amount <sup>*</sup>
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      id="trip-expense-amount"
                      onChange={(e) =>
                        this.setState({
                          tripExpenseAmountEditText: e.target.value,
                        })
                      }
                    />{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: "1em", textAlign: "center" }}>
            <input
              id="cancel"
              type="button"
              value="Cancel"
              className="fnxGrey"
              onClick={this.props.onCancel}
            />

            <input
              id="createSaving"
              type="button"
              value="Create"
              className="fnxGreen"
              onClick={this.createTripExpenseHandler}
            />
          </div>
        </div>
      </div>
    );
  }
}

CreateTripExpenseDialogComponent.propTypes = {
  user: PropTypes.any.isRequired,
  doLogout: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  tripid: PropTypes.string.isRequired,
};
