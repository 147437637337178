import React from "react";
import TextInputComponent from "../../../components/shared/TextInputComponent";
import PropTypes from "prop-types";
import store from "../../../store";
import { editAccount, updateAccount } from "../../../actions/accountsAction";
import { connect } from "react-redux";

class AccountsRowComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      editModeCardText: undefined,
    };
    this.updateCardHandler = this.updateCardHandler.bind(this);
  }

  editCardHandler() {
    store.dispatch(
      editAccount({ id: this.props.acc.id, name: this.props.acc.name }),
    );
  }

  updateCardHandler() {
    store.dispatch(
      updateAccount(
        this.props.acc.id,
        this.state.editModeCardText || this.props.editAccount.name,
      ),
    );
    this.setState({ editMode: false, editModeCardText: undefined });
  }

  renderOptions() {
    if (
      this.props.editAccount &&
      this.props.editAccount.id === this.props.acc.id
    ) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "10px",
          }}
        >
          {this.state.editModeCardText?.length && (
            <a
              className="hyperlink-no-color successMsg"
              onClick={this.updateCardHandler}
            >
              Save
            </a>
          )}
          <a
            className="hyperlink-no-color"
            onClick={() => {
              store.dispatch(editAccount(undefined));
              this.setState({ editModeCardText: undefined });
            }}
          >
            Cancel
          </a>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "10px",
          }}
        >
          <a
            href="#"
            style={{ color: "red" }}
            onClick={() => this.props.onDeleteClick(this.props.acc.id)}
          >
            Delete
          </a>
          <a
            href="#"
            onClick={() => {
              this.editCardHandler();
            }}
          >
            Edit
          </a>
        </div>
      );
    }
  }

  renderEditComponent() {
    const text =
      this.state.editModeCardText?.length >= 0
        ? this.state.editModeCardText
        : this.props.editAccount?.name;
    if (
      this.props.editAccount &&
      this.props.editAccount.id === this.props.acc.id
    ) {
      return (
        <TextInputComponent
          type="text"
          id="editCardText"
          value={text}
          onChange={(e) => this.setState({ editModeCardText: e.target.value })}
        />
      );
    } else {
      return <span>{this.props.acc.name}</span>;
    }
  }

  render() {
    return (
      <li className="tabbed-list-li" key={this.props.index}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "50%" }}>
            <img src={"/assets/icons/credit-card.svg"} width="50px" />
          </div>

          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              {this.renderEditComponent()}
            </div>
            {this.renderOptions()}
          </div>
        </div>
      </li>
    );
  }
}

AccountsRowComponent.propTypes = {
  user: PropTypes.any.isRequired,
  acc: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  editAccount: PropTypes.any,
};

function mapStateToProps(state) {
  const { editAccount } = state.accounts;
  return { editAccount };
}

export default connect(mapStateToProps)(AccountsRowComponent);
