import PropTypes from "prop-types";
import React from "react";
import { getLocalizedDate } from "../../../helpers/DateHelper";

export default class HomePagePieChartSelectedComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expenses: [],
    };
  }

  render() {
    return (
      <div
        id="chart-selected-component"
        style={{
          width: "50%",
          backgroundColor: "var(--fnx-light-grey5)",
          padding: 10,
          borderRadius: 20,
          margin: "0px 10px",
          boxShadow:
            "0.25rem 0.25rem 0.6rem rgb(0 0 0 / 50%), 0 0.5rem 1.125rem rgb(75 0 0 / 5%)",
        }}
      >
        <div
          className="divSpread"
          style={{ margin: "10px 0px", padding: "0px 10px" }}
        >
          <div>{this.props.renderTitle()}</div>

          <span className="close" onClick={this.props.onCancel}>
            &times;
          </span>
        </div>

        <div style={{ maxHeight: 300, overflowY: "scroll" }}>
          <table className="exhutTable">
            <thead>
              <tr>
                <th> Category</th>
                <th> SubCategory</th>
                <th> Date</th>
                <th> Amount</th>
                <th> Account</th>
              </tr>
            </thead>
            <tbody>
              {this.props.filteredExpenses &&
                this.props.filteredExpenses.map((expense, i) => {
                  return (
                    <tr key={i}>
                      <td> {expense.category}</td>
                      <td> {expense.subcategory}</td>
                      <td>
                        {" "}
                        {getLocalizedDate(expense.expensedate, this.props.user)}
                      </td>
                      <td> {expense.amount}</td>
                      <td> {expense.cardname}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

HomePagePieChartSelectedComponent.propTypes = {
  onCancel: PropTypes.func,
  filteredExpenses: PropTypes.array,
  renderTitle: PropTypes.func,
  user: PropTypes.object,
};
