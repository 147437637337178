import {
  ACTION_SET_AI_JSON_DATA,
  ACTION_SET_AI_JSON_DATA_ERROR,
  ACTION_SET_RELATED_EXPENSES,
  ACTION_SET_LEGACY_SELECTED_ACCOUNT,
  ACTION_SET_MISC_EXPENSES_TOTAL,
  ACTION_SET_EXPENSES_TOTAL,
} from "../actions/expensesAction";

const initState = {
  ai: {},
  legacy: {},
  relatedExpenses: [],
};
export default function expense(state = initState, action) {
  switch (action.type) {
    case ACTION_SET_AI_JSON_DATA:
      return {
        ...state,
        ai: {
          ...state.ai,
          json: action.payload,
        },
      };
    case ACTION_SET_RELATED_EXPENSES:
      return {
        ...state,
        relatedExpenses: action.payload,
      };
    case ACTION_SET_AI_JSON_DATA_ERROR:
      return {
        ...state,
        ai: {
          ...state.ai,
          error:
            "Error fetching data. Please try after sometime or use legacy mode.",
        },
      };
    case ACTION_SET_LEGACY_SELECTED_ACCOUNT:
      return {
        ...state,
        legacy: {
          ...state.legacy,
          selectedAccount: action.payload,
        },
      };
    case ACTION_SET_MISC_EXPENSES_TOTAL:
      return {
        ...state,
        misc: {
          total: action.payload,
        },
      };
    case ACTION_SET_EXPENSES_TOTAL:
      return {
        ...state,
        monthlyTotal: action.payload,
      };
    default:
      return state;
  }
}
