import React from "react";
import { Link } from "react-router-dom";
import * as DateHelper from "../../helpers/DateHelper";
import PropTypes from "prop-types";
import { getLoanPendingData } from "../../services/LoanService";
import {
  getLoanPayments,
  getLoanPaymentsTotal,
} from "../../services/LoanPaymentService";
import { formatToLocaleAmount } from "../../helpers/NumberHelper";

export default class LoansQuickViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.styles = {
      closeButton: {
        fontWeight: "bold",
        fontSize: "20px",
        cursor: "pointer",
      },
      closeButtonContainer: {
        float: "right",
      },
      quickViewContainer: {
        padding: "10px 5px",
      },
      quickViewHeader: {
        margin: "20px 0px",
      },
    };
  }

  shouldComponentUpdate(nextProps) {
    return this.props.loan.id !== nextProps.loan.id;
  }

  render() {
    return (
      <div
        id="loans-quick-view"
        style={{
          border: "1px solid var(--fnx-light-grey0)",
          padding: "15px",
          borderRadius: "10px",
          backgroundColor: "var(--fnx-light-grey5)",
        }}
      >
        <div style={this.styles.closeButtonContainer}>
          <span
            id="close-loan-quick-view"
            style={this.styles.closeButton}
            onClick={this.props.onClose}
          >
            &times;
          </span>
        </div>

        <div style={this.styles.quickViewContainer}>
          <div style={this.styles.quickViewHeader}>
            <Link
              to={`/loans/${this.props.loan.id}`}
              className="linkAsBlueButton"
            >
              {" "}
              Full View{" "}
            </Link>
          </div>

          <div>
            <LoansQuickViewTableComponent
              loan={this.props.loan}
              user={this.props.user}
            />
          </div>
        </div>
      </div>
    );
  }
}

LoansQuickViewComponent.propTypes = {
  loan: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export class LoansQuickViewTableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
      pendingBalance: 0,
      totalInterestPaid: 0,
      totalPrincipalPaid: 0,
      paymentTotal: 0,
      remainingMonths: 0,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.loan !== this.props.loan) {
      await this.fetchData();
    }
  }

  async fetchData() {
    try {
      const payments = await getLoanPayments(
        this.props.user.groupid,
        this.props.loan.id,
      );
      const pendingData = await getLoanPendingData(
        this.props.user.groupid,
        this.props.loan.id,
      );
      const paymentTotal = await getLoanPaymentsTotal(
        this.props.user.groupid,
        this.props.loan.id,
      );
      this.setState({
        payments,
        paymentTotal: paymentTotal.total,
        pendingBalance: pendingData.pendingBalance,
        totalInterestPaid: pendingData.totalInterestPaid,
        totalPrincipalPaid: pendingData.totalPrincipalPaid,
        remainingMonths: pendingData.remainingMonths,
      });
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    return (
      <table className="exhutTable">
        <thead>
          <tr>
            <th>Details</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong> Loan Name </strong>
            </td>
            <td>{this.props.loan.name}</td>
          </tr>
          <tr>
            <td>
              <strong> Principal Amount</strong>
            </td>
            <td>{formatToLocaleAmount(this.props.loan.amount)}</td>
          </tr>
          <tr>
            <td>
              <strong> Months</strong>
            </td>
            <td>{this.props.loan.months}</td>
          </tr>
          <tr>
            <td>
              <strong> Interest</strong>
            </td>
            <td>{this.props.loan.interest + "%"}</td>
          </tr>
          <tr>
            <td>
              <strong> Start Date</strong>
            </td>
            <td>
              {DateHelper.getLocalizedDate(
                this.props.loan.start_date,
                this.props.user,
              )}
            </td>
          </tr>
          <tr>
            <td>
              <strong> Total Interest </strong>
            </td>
            <td>{formatToLocaleAmount(this.props.loan.total_interest)}</td>
          </tr>
          <tr>
            <td>Principal + Total Interest</td>
            <td>
              {formatToLocaleAmount(this.props.loan.total_amount_plus_interest)}
            </td>
          </tr>
          <tr>
            <td>
              <strong> Monthly Payment </strong>
            </td>
            <td>{formatToLocaleAmount(this.props.loan.monthly_payment)}</td>
          </tr>
          <tr
            style={{
              backgroundColor: "var(--fnx-total-green)",
              color: "white",
            }}
          >
            <td style={{ color: "white" }}>
              <strong>Balance</strong>
            </td>
            <td>
              <strong style={{ color: "white" }}>
                {formatToLocaleAmount(this.state.pendingBalance)}
              </strong>
            </td>
          </tr>

          <tr>
            <td>Payments</td>
            <td>
              <span id="payments-count">{this.state.payments?.length}</span>
            </td>
          </tr>
          <tr>
            <td>Payments Total</td>
            <td>
              <span id="payment-total">
                {formatToLocaleAmount(this.state.paymentTotal)}
              </span>
            </td>
          </tr>
          <tr>
            <td>Balance Due</td>
            <td>
              <span id="pending-balance">
                {formatToLocaleAmount(this.state.pendingBalance)}
              </span>
            </td>
          </tr>
          <tr>
            <td>Remaining months</td>
            <td>
              <span id="remaining-months">
                {Math.round(this.state.remainingMonths)}
              </span>
            </td>
          </tr>
          <tr>
            <td>Interest Paid Till Date</td>
            <td>
              <span id="total-interest-paid">
                {formatToLocaleAmount(this.state.totalInterestPaid)}
              </span>
            </td>
          </tr>
          <tr>
            <td>Principal Paid Till Date</td>
            <td>
              <span id="total-principal-paid">
                {formatToLocaleAmount(this.state.totalPrincipalPaid)}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

LoansQuickViewTableComponent.propTypes = {
  loan: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
};
