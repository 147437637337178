export const ACTION_GET_LOANS = "GET_LOANS";
export const ACTION_SET_LOANS = "SET_LOANS";

export function getLoans() {
  return {
    type: ACTION_GET_LOANS,
  };
}

export function setLoans(payload) {
  return {
    type: ACTION_SET_LOANS,
    payload,
  };
}
