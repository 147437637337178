export const ACTION_GET_GROUP_DETAILS = "GET_GROUP_DETAILS";
export const ACTION_SET_GROUP_DETAILS = "SET_GROUP_DETAILS";
export const UPDATE_DEFAULT_GROUP = "UPDATE_DEFAULT_GROUP";
export const SET_DEFAULT_GROUP = "SET_DEFAULT_GROUP";
export const ACTION_GET_GROUP_USERS = "ACTION_GET_GROUP_USERS";
export const SET_GROUP_USERS = "SET_GROUP_USERS";

export function getGroupUsers(groupid) {
  return {
    type: ACTION_GET_GROUP_USERS,
    payload: groupid,
  };
}

export function setGroupUsers(payload) {
  return {
    type: SET_GROUP_USERS,
    payload,
  };
}

export function getGroupDetails() {
  return {
    type: ACTION_GET_GROUP_DETAILS,
  };
}

export function setDefaultGroup(groupid) {
  return {
    type: SET_DEFAULT_GROUP,
    payload: groupid,
  };
}

/**
 * Triggers a saga to update a group. The setDefaultGroup will set in reducer.
 * @param {*} groupid
 * @returns
 */
export function updateDefaultGroup(groupid) {
  return {
    type: UPDATE_DEFAULT_GROUP,
    payload: groupid,
  };
}

export function setGroupDetails(defaultGroupId, groups) {
  return {
    type: ACTION_SET_GROUP_DETAILS,
    payload: {
      groupid: defaultGroupId,
      groups,
    },
  };
}
