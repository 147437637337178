import { call, put, takeLatest } from "redux-saga/effects";
import * as Api from "../services/LedgerService";
import { ACTION_GET_LEDGERS, setLedgers } from "../actions/ledgerAction";
import store from "../store";
import { refreshUser } from "../actions/userAction";

function* fetchLedgers() {
  try {
    const ledgers = yield call(Api.getLedgers);
    yield put(setLedgers(ledgers));
  } catch (e) {
    if (e.status === 401) {
      yield put(refreshUser(undefined));
    } else {
      console.error(e);
    }
  }
}

export default function* ledgerSaga() {
  yield takeLatest(ACTION_GET_LEDGERS, fetchLedgers);
}
