import { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";

export default function usePlaid({ init, onClose, linkToken }) {
  const [successData, setSuccessData] = useState();

  const { open, exit, ready } = usePlaidLink({
    token: linkToken,
    onExit: () => {
      onClose();
    },
    onSuccess: (public_token, metadata) => {
      setSuccessData({ public_token, metadata });
      onClose();
    },
  });

  useEffect(() => {
    if (init && ready) {
      open();
    }
  }, [init]);

  return { successData };
}
