import React from "react";
import PropTypes from "prop-types";
import * as DateHelper from "../../helpers/DateHelper";
import store from "../../store";

export default class RelatedExpenseComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        id="related-expenses"
        hidden={
          this.props.relatedExpenses && this.props.relatedExpenses.length == 0
        }
      >
        <div className="subtitle">Related Expenses</div>
        <div>
          <table className="exhutTable" id="relatedExpensesTable">
            <thead>
              <tr>
                <th> Category </th>
                <th> Sub Category </th>
                <th> Date </th>
                <th> Amount </th>
                <th> Comments </th>
              </tr>
            </thead>
            <tbody>
              {this.props.relatedExpenses.map((relatedExp, i) => {
                return (
                  <tr key={i}>
                    <td> {relatedExp.category} </td>
                    <td> {relatedExp.subcategory} </td>
                    <td>
                      {" "}
                      {DateHelper.getLocalizedDate(
                        relatedExp.expensedate,
                        store.getState().app.user,
                      )}{" "}
                    </td>
                    <td> {relatedExp.amount} </td>
                    <td> {relatedExp.comments} </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

RelatedExpenseComponent.propTypes = {
  relatedExpenses: PropTypes.array.isRequired,
};
