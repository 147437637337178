import React, { useState } from "react";
import { createCreditCard } from "../../../services/CreditCardService";
import store from "../../../store";
import { getAccounts } from "../../../actions/accountsAction";

export default function CreateAccountComponent({ onSuccess, onError }) {
  const [editText, setEditText] = useState("");

  const createAccountHandler = () => {
    createCreditCard(editText.trim())
      .then(() => {
        store.dispatch(getAccounts());
        onSuccess();
      })
      .catch(onError)
      .finally(() => setEditText(""));
  };

  return (
    <div>
      <div>
        <span> Account name </span>
        <input
          type="text"
          value={editText}
          maxLength={40}
          id="cardinput"
          autoComplete="off"
          onChange={(e) => setEditText(e.target.value)}
        />
      </div>

      <div className="divSpread" style={{ marginTop: "5%" }}>
        <span className="errorMsg">
          {" "}
          Note: Please do not enter secured credentials such as credit card or
          bank account numbers.{" "}
        </span>
      </div>

      <div
        style={{
          marginTop: "5%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <input
          type="button"
          className="bigButtonGreen"
          value="Add"
          disabled={editText.length === 0}
          onClick={createAccountHandler}
        />
      </div>
    </div>
  );
}
