import {
  ACTION_SET_GROUP_DETAILS,
  SET_DEFAULT_GROUP,
  SET_GROUP_USERS,
} from "../actions/groupsAction";

const initState = {
  groups: [],
};
export default function group(state = initState, action) {
  switch (action.type) {
    case SET_GROUP_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case ACTION_SET_GROUP_DETAILS:
      return {
        ...state,
        groupid: action.payload.groupid,
        groups: action.payload.groups,
      };
    case SET_DEFAULT_GROUP:
      return {
        ...state,
        groupid: action.payload,
      };
    default:
      return state;
  }
}
