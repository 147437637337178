import moment from "moment";

/**
 * Returns date in mm/dd/yyyy or dd/mm/yyyy according to user's country code.
 * Usually used when transforming date from backend service.
 *
 * IMPORTANT : this is a trivial function since this API completely ignores timezone.
 * Ignoring timezone is important because "date" type in postgres is supposed to store date without timezone,
 * but node postgres library deerializes it using the instance's timezone thus messing up date.
 *
 * @param {*} dateString - date as string. Possibly in the format YYYY-MM-DD:T00:00:000Z
 * @param {*} userObject
 * @returns
 */
export function getLocalizedDate(dateString, userObject) {
  if (!dateString || !userObject) {
    return "";
  }
  const dateComponentExcludingTimeZone = dateString.match(
    /[0-9]{4}-[0-9]{2}-[0-9]{2}/g,
  )[0];
  if (dateComponentExcludingTimeZone) {
    const literals = dateComponentExcludingTimeZone
      .split("-")
      .flatMap((value) => parseInt(value));
    const d = new Date(literals[0], literals[1] - 1, literals[2]);
    return getLocalizedDateFromObject(d, userObject);
  }
  return "";
}

export function getLocalizedDateFromObject(dateObject, userObject) {
  const d = dateObject;
  if (userObject && userObject.country_code === "USA") {
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  }
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
}

export function getTodaysDate(userObject) {
  return getLocalizedDateFromObject(new Date(), userObject);
}

/**
 * Returns date and day component by excluding the time.
 * For example, - for 2021-10-20T12:34:34:111 -> Oct 20 2021
 * @param {*} dateString
 * @param {*} userObject
 * @returns
 */
export function getDateExcludingTime(dateString) {
  if (!dateString) {
    return "";
  }
  try {
    const dateStr = new Date(dateString).toUTCString();
    const splits = dateStr.split(" ");
    return splits.slice(1, 4).join(" ").toString();
  } catch (err) {
    console.error(err);
    return "";
  }
}

export function getMonthNameByIndex(index) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[index - 1];
}

export function localDateToSQL(localizedDate, userObject) {
  const FORMAT = "YYYY-MM-DD";

  if (userObject && userObject.country_code === "USA") {
    return moment(localizedDate, "MM/DD/YYYY").format(FORMAT);
  }
  return moment(localizedDate, "DD/MM/YYYY").format(FORMAT);
}

export function sqlToLocalDate(sqlDate, userObject) {
  const FORMAT = "YYYY-MM-DD";

  if (userObject && userObject.country_code === "USA") {
    return moment(sqlDate, FORMAT).format("MM/DD/YYYY");
  }
  return moment(sqlDate, FORMAT).format("DD/MM/YYYY");
}
