import React from "react";
import "./SnackBarComponent.css";
import PropTypes from "prop-types";

export default class SnackBarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      close: false,
    };
    this.renderComponent = this.renderComponent.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.key = `${this.props.id}-show`;
  }

  handleClose() {
    sessionStorage.setItem(this.key, "false");
    this.setState({ close: true });
  }

  componentDidMount() {
    const element = document.querySelector(`#${this.props.id}`);
    if (element) {
      setTimeout(() => {
        element.classList.remove("hidden");
        element.classList.add("snackbar");
      }, this.props.delay || 0);
    }
  }

  renderComponent() {
    if (this.state.close || sessionStorage.getItem(this.key) === "false") {
      return null;
    } else {
      return (
        <div className="divFlex hidden" id={this.props.id}>
          <div>
            <span>{this.props.message}</span>
          </div>
          <div>
            <span className="closesnack" onClick={this.handleClose}>
              x
            </span>
          </div>
        </div>
      );
    }
  }

  render() {
    return this.renderComponent();
  }
}

SnackBarComponent.propTypes = {
  message: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  delay: PropTypes.number,
};
