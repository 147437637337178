import React from "react";
import {
  setSelectedMonth,
  setSelectedYear,
} from "../../../actions/expensesAction";
import store from "../../../store";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getLeftNavArrow, getRightNavArrow } from "../../../helpers/IconHelper";

class MonthYearComponent extends React.Component {
  constructor(props) {
    super(props);
    this.months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    this.years = ["2021", "2022", "2023", "2024"];
    this.state = {
      isCurrentMonthYear: false,
      showNavRight: true,
      showNavLeft: true,
    };
    this._setCurrentMonthYear = this._setCurrentMonthYear.bind(this);
    this.navigatePreviousMonthYear = this.navigatePreviousMonthYear.bind(this);
    this.navigateNextMonthYear = this.navigateNextMonthYear.bind(this);
  }

  componentDidMount() {
    this._setCurrentMonthYear();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedMonth !== this.props.selectedMonth ||
      prevProps.selectedYear !== this.props.selectedYear
    ) {
      this._setCurrentMonthYear();
      if (this.props.selectedYear > this.years[this.years.length - 1]) {
        this.setState({ showNavRight: false });
      } else {
        this.setState({ showNavRight: true });
      }
      if (this.props.selectedYear < this.years[0]) {
        this.setState({ showNavLeft: false });
      } else {
        this.setState({ showNavLeft: true });
      }
    }
  }

  _setCurrentMonthYear() {
    this.setState({
      isCurrentMonthYear:
        this.props.selectedMonth === new Date().getMonth() + 1 &&
        this.props.selectedYear === new Date().getFullYear(),
    });
  }

  goToCurrentDate() {
    store.dispatch(setSelectedMonth(new Date().getMonth() + 1));
    store.dispatch(setSelectedYear(new Date().getFullYear()));
  }

  navigateNextMonthYear() {
    let nextMonth = this.props.selectedMonth + 1;
    let year = this.props.selectedYear;
    if (nextMonth > 12) {
      nextMonth = 14 % nextMonth;
      year++;
    }
    store.dispatch(setSelectedMonth(nextMonth));
    store.dispatch(setSelectedYear(year));
  }

  navigatePreviousMonthYear() {
    let prevMonth = this.props.selectedMonth - 1;
    let year = this.props.selectedYear;
    if (prevMonth <= 0) {
      prevMonth = 12;
      year--;
    }
    store.dispatch(setSelectedMonth(prevMonth));
    store.dispatch(setSelectedYear(year));
  }

  render() {
    return (
      <div className="divCenterAlign">
        {this.state.showNavLeft && (
          <img
            id="nav-left"
            src={getLeftNavArrow()}
            className="iconSizeSmall"
            style={{
              marginRight: "10px",
              backgroundColor: "lightgray",
              padding: "3px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={this.navigatePreviousMonthYear}
          />
        )}

        <select
          onChange={(e) =>
            store.dispatch(setSelectedMonth(parseInt(e.target.value, 10)))
          }
          id="selectMonth"
          value={this.props.selectedMonth}
          style={{ marginRight: "10px" }}
        >
          {this.months.map((month, i) => {
            return (
              <option key={i} value={i + 1}>
                {month}
              </option>
            );
          })}
        </select>

        <select
          onChange={(e) =>
            store.dispatch(setSelectedYear(parseInt(e.target.value)))
          }
          id="selectYear"
          value={this.props.selectedYear}
          style={{ marginRight: "10px" }}
        >
          {this.years.map((year, i) => {
            return (
              <option key={i} value={year}>
                {year}
              </option>
            );
          })}
        </select>

        {this.state.showNavRight && (
          <img
            id="nav-right"
            src={getRightNavArrow()}
            className="iconSizeSmall"
            style={{
              marginLeft: "0px 5px",
              backgroundColor: "lightgray",
              padding: "3px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={this.navigateNextMonthYear}
          />
        )}

        {this.state.isCurrentMonthYear === false && (
          <span
            id="go-today-link"
            className="hyperlink"
            style={{ marginLeft: "10px", color: "var(--fnx-total-green)" }}
            onClick={this.goToCurrentDate}
          >
            Go To Today
          </span>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { selectedMonth, selectedYear } = state.app;
  return { selectedMonth, selectedYear };
}

MonthYearComponent.propTypes = {
  showButton: PropTypes.bool,
  selectedMonth: PropTypes.any,
  selectedYear: PropTypes.any,
};

export default connect(mapStateToProps)(MonthYearComponent);
