import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export function getSubCategories(category) {
  let url = new FNXConnection().getURL() + "/category";
  if (category) {
    url = `${url}/${category}`;
  }
  return executeService(url);
}

export function getCategoriesOnly() {
  const url = new FNXConnection().getURL() + "/category/only";
  return executeService(url);
}

export function getCategoriesMap() {
  const url = new FNXConnection().getURL() + "/category";
  return executeService(url);
}
