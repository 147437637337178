import React from "react";
import PropTypes from "prop-types";

/**
 * Tile components to be used particularly for analytics.
 *
 * Properties:
 *
 * headerText: {String} Header text. Text will be bolded and bigger
 *
 * subHeaderText: {String} Subheader text. Text will be smaller than header.
 *
 * primaryColumn: {String} This is the first column name. For ex, Category for category page, fullname for users page.
 *
 * tableContents: {Array} Contents array. Each item is array by itself with 2 elements. [ [td, td], [td, td] ]
 */
export default class AnalysisTileComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="analysis-tile">
        <div className="analysis-tile-heading">
          <span> {this.props.headerText}</span>
        </div>
        <div className="analysis-tile-subheading">
          <span>{this.props.subHeaderText}</span>
        </div>

        <div className="analysis-tile-body">
          <table>
            <thead>
              <tr>
                <th>{this.props.primaryColumn}</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {this.props.tableContents.map((row, i) => {
                return (
                  <tr key={i}>
                    <td title={row[0]}>{row[0]}</td>
                    <td>{row[1]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

AnalysisTileComponent.propTypes = {
  headerText: PropTypes.string.isRequired,
  subHeaderText: PropTypes.string.isRequired,
  primaryColumn: PropTypes.string.isRequired,
  tableContents: PropTypes.array.isRequired,
};
