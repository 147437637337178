import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export function getLoanPayments(groupid, loanid) {
  const url =
    new FNXConnection().getServicesURL() +
    "/" +
    groupid +
    "/loans/" +
    loanid +
    "/payments";
  return executeService(url);
}

export function getLoanPaymentsTotal(groupid, loanid) {
  const url =
    new FNXConnection().getServicesURL() +
    "/" +
    groupid +
    "/loans/" +
    loanid +
    "/payments/total";
  return executeService(url);
}

export function createLoanPayment(groupid, loanid, amount, date, comments) {
  const url =
    new FNXConnection().getServicesURL() +
    "/" +
    groupid +
    "/loans/" +
    loanid +
    "/payments";
  return executeService(
    url,
    "POST",
    { amount, date, comments },
    201,
    "restrict",
  );
}

export function deleteLoanPayment(groupid, loanid, paymentid) {
  const url =
    new FNXConnection().getServicesURL() +
    "/" +
    groupid +
    "/loans/" +
    loanid +
    "/payments/" +
    paymentid;
  return executeService(url, "DELETE", undefined, 204);
}
