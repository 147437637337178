import { Avatar, Drawer } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  getCharts2Icon,
  getEverywhereGlobe,
  getOneKey,
  getProfitsIcon,
  getShieldIcon,
} from "../../helpers/IconHelper";
import LeftPaneComponent from "../LeftPaneComponent";
import MobileHeaderComponent from "../MobileHeaderComponent";
import {
  AndroidAppLink,
  IOSAppLink,
} from "../mobile-links/MobileLinksComponent";
import "./LaunchComponent.css";

export default function LaunchComponent({ isMobile }) {
  const [showOptions, setShowOptions] = useState(false);
  return (
    <div id="launch-component">
      {isMobile && (
        <MobileHeaderComponent
          onToggleOptions={() => setShowOptions(!showOptions)}
        />
      )}

      <Drawer
        anchor={"left"}
        open={showOptions}
        sx={{ width: 100 }}
        onClose={() => setShowOptions(false)}
      >
        <LeftPaneComponent isMobile={isMobile} />
      </Drawer>

      <div className={isMobile ? "mx-60 my-40" : "mx-80 my-40"}>
        <div className={`divRight mb-40`}>
          <Link to={"/register"} className="blue-button">
            Sign Up
          </Link>
        </div>

        <TextToImage
          isMobile={isMobile}
          title={"Best way to manage your money"}
          subtitle={"Track & manage your finance better"}
          even={true}
          imgName={"assets/bestway3.svg"}
        />

        <hr />

        <WhyExpenseHutItem isMobile={isMobile} />
        <hr />

        <TextToImage
          isMobile={isMobile}
          title={"Send Money"}
          subtitle={
            "Skip unnecessary fees. Send money to friends or family instantly."
          }
          subtitleSupIndex={1}
          even={true}
          imgName={"assets/sendmoney.svg"}
        />
        <hr />

        <ImageToText
          isMobile={isMobile}
          title={"Instant ID Verification"}
          subtitle={"Instantly verify your identity using AI"}
          subtitleSupIndex={"2"}
          even={true}
          imgName={"assets/instant-id.svg"}
        />
        <hr />

        <TextToImage
          title={"Connect to bank accounts"}
          subtitle={"Sit back relax while we load your expenses everyday"}
          subtitleSupIndex={1}
          imgName={"assets/bank2.png"}
          isMobile={isMobile}
          imgWidth={200}
        />
        <hr />

        <GetTheAppItem isMobile={isMobile} />

        <hr />

        <ImageToText
          isMobile={isMobile}
          title={"Live FOREX rates"}
          subtitle={
            "Check how much is your savings worth in foreign currencies"
          }
          subtitleSupIndex={"3"}
          even={true}
          imgName={"assets/forex.svg"}
          imgWidth={250}
        />
        <hr />

        <TextToImage
          isMobile={isMobile}
          titleElem={
            <strong style={{ fontSize: 25 }}>
              {" "}
              SMS BAL to{" "}
              <strong style={{ fontSize: 20 }}>+1 (855) 934-1064</strong>{" "}
            </strong>
          }
          subtitle={"Send a text to check your balances across all accounts."}
          subtitleSupIndex={"1"}
          even={true}
          imgName={"assets/sms-bal.svg"}
        />
        <hr />

        <ImageToText
          isMobile={isMobile}
          title={"Scan receipts"}
          subtitle={"Scan receipts to add expenses faster"}
          even={true}
          imgName={"assets/receipt.svg"}
          imgWidth={200}
          containerStyle={{
            backgroundColor: "#FFFADE",
            padding: 20,
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        />
        <hr />

        <TextToImage
          isMobile={isMobile}
          title={"Be Informed"}
          subtitle={"Get notification or sms on your phone"}
          even={true}
          imgName={"assets/be-informed.svg"}
        />
        <hr />

        <ImageToText
          isMobile={isMobile}
          title={"Weekly reports"}
          subtitle={"Get weekly, mid-weekly or monthly reports via email"}
          even={true}
          imgName={"assets/weekly-reports.svg"}
          imgWidth={250}
        />
        <hr />

        <FinalItem />

        <hr />

        <div
          className="div-column-2"
          style={{ color: "darkgrey", fontSize: "small" }}
        >
          <span className={`body-footer-item`}>
            1. Available only to US customers and may require premium
            subscription.
          </span>
          <span className={`body-footer-item`}>
            2. Required only when using specific features such as Send Money.
          </span>
          <span className={`body-footer-item`}>
            3. Forex rates available to currencies AUD, CAD, EUR, GBP, HKD, INR,
            SGD and USD. Other currencies will be supported soon.
          </span>
        </div>
      </div>
    </div>
  );
}

function TextToImage({
  isMobile,
  title,
  titleElem,
  subtitle,
  subtitleSupIndex,
  imgName,
  imgWidth = 300,
  even,
  ternary,
  containerStyle,
}) {
  return (
    <div
      className={
        isMobile
          ? "divColumn divCenterAlign my-60"
          : `${even ? "divSpreadEven" : "divSpread"} my-60`
      }
      style={containerStyle}
    >
      <div className={isMobile ? "mb-40" : "mb-20 w-50"}>
        {title && (
          <strong style={{ fontSize: 35, fontWeight: "" }}>{title}</strong>
        )}
        {titleElem}

        <div style={{ paddingRight: 0, marginTop: 25 }}>
          <p style={{ color: "darkslategrey" }}>
            {subtitle}
            {subtitleSupIndex && (
              <sup style={{ color: "darkslategray" }}>{subtitleSupIndex}</sup>
            )}
          </p>
        </div>
        {ternary}
      </div>

      {imgName && <img src={imgName} width={imgWidth} alt={imgName} />}
    </div>
  );
}

function ImageToText({
  title,
  subtitle,
  subtitleSupIndex,
  imgName,
  isMobile,
  ternary,
  imgWidth = 300,
  even,
  containerStyle,
}) {
  if (isMobile) {
    return (
      <TextToImage
        title={title}
        subtitle={subtitle}
        imgName={imgName}
        isMobile={isMobile}
        imgWidth={imgWidth}
        containerStyle={containerStyle}
      />
    );
  }

  return (
    <div className={`divSpreadEven my-60`} style={containerStyle}>
      {imgName && (
        <div className={isMobile ? "mb-40" : "mb-20 w-50"}>
          <img src={imgName} width={imgWidth} alt={"alt-img"} />
        </div>
      )}

      <div className={isMobile ? "mb-40" : "divColumn mb-20 w-40"}>
        <strong style={{ fontSize: 30, fontWeight: "" }}>{title}</strong>
        <div style={{ paddingRight: 32 }}>
          <p style={{ color: "darkslategrey" }}>
            {subtitle}
            {subtitleSupIndex && (
              <sup style={{ color: "darkslategray" }}>{subtitleSupIndex}</sup>
            )}
          </p>
        </div>
        {ternary}
      </div>
    </div>
  );
}

function CenterItem({
  title,
  subtitle,
  imgName,
  ternary,
  isMobile,
  imgWidth = 300,
}) {
  return (
    <div className={"divColumn divCenterAlign"}>
      <div className={isMobile ? "mb-40" : "mb-20 w-40"}>
        <span style={{ fontSize: 30, fontWeight: "" }}>{title}</span>
        <div style={{ paddingRight: 32 }}>
          <p style={{ lineHeight: 1.3 }}>{subtitle}</p>
        </div>
        {ternary}
      </div>

      {imgName && (
        <div className={isMobile ? "mb-40" : "mb-20 w-50"}>
          <img
            src={`/assets/landingpage/${imgName}`}
            width={imgWidth}
            alt={imgName}
          />
        </div>
      )}
    </div>
  );
}

function WhyExpenseHutItem({ isMobile }) {
  return (
    <div className={`divSpreadEven my-60`}>
      <WhyExpenseHutItemText />
    </div>
  );
}

function WhyExpenseHutItemText() {
  return (
    <div className={"mb-20"}>
      <strong style={{ fontSize: 30, fontWeight: "" }}>Why ExpenseHut?</strong>
      <div className="divAlignItemsOnly divFlex my-40">
        <div>
          <img
            style={{ width: 40 }}
            alt="exhut type-less"
            src={getCharts2Icon()}
          />
        </div>
        <div className="div-column-2 mx-20">
          <strong style={{ fontWeight: "bold" }}>
            Manage your finances in one place
          </strong>
          <p style={{ color: "darkslategrey" }}>
            Individuals or families - we got your back.
          </p>
        </div>
      </div>
      <div className="divAlignItemsOnly divFlex my-40">
        <div>
          <Avatar alt="exhut type-less" src={getEverywhereGlobe()} />
        </div>
        <div className="div-column-2 mx-20">
          <strong style={{ fontWeight: "bold" }}>Available everywhere</strong>
          <p style={{ color: "darkslategrey" }}>
            Web or mobile - use on the go.
          </p>
        </div>
      </div>
      <div className="divAlignItemsOnly divFlex my-40">
        <div>
          <Avatar alt="exhut type-less" src={getOneKey()} />
        </div>
        <div className="div-column-2 mx-20">
          <strong style={{ fontWeight: "bold" }}>
            Multiple apps - One Identity
          </strong>
          <p style={{ color: "darkslategrey" }}>
            One credential to access all our apps.
          </p>
        </div>
      </div>
      <div className="divAlignItemsOnly divFlex my-40">
        <div>
          <Avatar alt="exhut type-less" src={getProfitsIcon()} />
        </div>
        <div className="div-column-2 mx-20">
          <strong style={{ fontWeight: "bold" }}>Free of cost</strong>
          <p style={{ color: "darkslategrey" }}>
            Sign up to experience awesome features without paying a dime.
          </p>
        </div>
      </div>

      <div className="divAlignItemsOnly divFlex my-40">
        <div>
          <Avatar alt="exhut type-less" src={getShieldIcon()} />
        </div>
        <div className="div-column-2 mx-20">
          <strong style={{ fontWeight: "bold" }}>Data Security</strong>
          <p style={{ color: "darkslategrey" }}>
            All our systems behind secured protocols.
          </p>
        </div>
      </div>
    </div>
  );
}

function FinalItem({ isMobile }) {
  return (
    <div
      className={"divColumn divCenterAlign my-40 p-10"}
      style={{ backgroundColor: "#FFDEFE", borderRadius: 30 }}
    >
      <div className={isMobile ? "mb-10" : "mb-10 w-100"}>
        <span style={{ fontSize: 30, fontWeight: "bold" }}>
          Lets improve your finances now!
        </span>
        <div style={{ paddingRight: 32 }}>
          <p style={{ lineHeight: 1.3 }}>
            Track and manage your finance better with ExpenseHut
          </p>
        </div>
      </div>

      <div className="mb-40">
        <img src="/assets/letsimprove.svg" style={{ width: 300 }} />
      </div>
    </div>
  );
}

function GetTheAppItem({ isMobile }) {
  if (isMobile) {
    return (
      <div
        className={"divCenterAlign divColumn my-20 p-20"}
        style={{ backgroundColor: "#DEEFFF", borderRadius: 30 }}
      >
        <div className={isMobile ? "mb-40" : "mb-20 w-50"}>
          <span style={{ fontSize: 30, fontWeight: "bold" }}>Get the App</span>
          <div style={{ paddingRight: 32 }}>
            <p style={{ lineHeight: 1.3 }}>Available in Android and iOS</p>
          </div>

          <div>
            <AndroidAppLink />

            <div className="mx-10">
              <IOSAppLink />
            </div>
          </div>
        </div>

        <img
          style={{ width: 200 }}
          alt="exhut type-less"
          src={"/assets/iphone-exhut.png"}
        />
      </div>
    );
  }
  return (
    <div
      className={"divSpreadEven my-20 p-20"}
      style={{ backgroundColor: "#DEEFFF", borderRadius: 30 }}
    >
      <img
        style={{ width: 200 }}
        alt="exhut type-less"
        src={"/assets/iphone-exhut.png"}
      />

      <div className={"mb-20"}>
        <span style={{ fontSize: 30, fontWeight: "bold" }}>Get the App</span>
        <div style={{ paddingRight: 32 }}>
          <p style={{ lineHeight: 1.3 }}>Available in Android and iOS</p>
        </div>

        <div>
          <AndroidAppLink />

          <div className="mx-10">
            <IOSAppLink />
          </div>
        </div>
      </div>
    </div>
  );
}
