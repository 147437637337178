import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export function getLoans(groupid) {
  const url = new FNXConnection().getServicesURL() + "/" + groupid + "/loans";
  return executeService(url);
}

export function getLoanById(groupid, id) {
  const url =
    new FNXConnection().getServicesURL() + "/" + groupid + "/loans/" + id;
  return executeService(url);
}

export function getLoanPendingData(groupid, id) {
  const url =
    new FNXConnection().getServicesURL() +
    "/" +
    groupid +
    "/loans/" +
    id +
    "/pending";
  return executeService(url);
}

export function createLoan(
  groupid,
  name,
  amount,
  months,
  interest,
  start_date,
  shareableUsers,
) {
  const url = new FNXConnection().getServicesURL() + "/" + groupid + "/loans";
  return executeService(
    url,
    "POST",
    { name, amount, months, interest, start_date, users: shareableUsers },
    201,
    "json",
  );
}

export function updateLoan(
  groupid,
  loanid,
  name,
  amount,
  months,
  interest,
  start_date,
  shareableUsers,
) {
  const url =
    new FNXConnection().getServicesURL() + "/" + groupid + "/loans/" + loanid;
  return executeService(
    url,
    "PUT",
    { name, amount, months, interest, start_date, users: shareableUsers },
    200,
    "json",
  );
}

export function deleteLoan(groupid, id) {
  const url = `${new FNXConnection().getServicesURL()}/${groupid}/loans/${id}`;
  return executeService(url, "DELETE", undefined, 204);
}
