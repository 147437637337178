export function capitalize(input) {
  const words = input.split(" ");
  const result = [];
  for (const w of words) {
    result.push(`${w.charAt(0).toUpperCase()}${w.substring(1)}`);
  }
  return result.join(" ");
}

export function getSuperScript(input) {
  let number = input;
  if (typeof input === "string") {
    number = +input;
  }
  if ([11, 12, 13].find((n) => n === number)) {
    return "th";
  }
  const remainder = number % 10;
  if (remainder === 1) {
    return "st";
  } else if (remainder === 2) {
    return "nd";
  } else if (remainder === 3) {
    return "rd";
  }
  return "th";
}
