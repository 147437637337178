import {
  ACTION_SET_GROUP_INCOME_TOTAL,
  ACTION_SET_USER_INCOME_TOTAL,
} from "../actions/incomeAction";

const initState = {};

export default function income(state = initState, action) {
  switch (action.type) {
    case ACTION_SET_GROUP_INCOME_TOTAL:
      return {
        ...state,
        groupTotal: action.payload,
      };
    case ACTION_SET_USER_INCOME_TOTAL:
      return {
        ...state,
        userTotal: action.payload,
      };
    default:
      return state;
  }
}
