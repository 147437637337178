import * as AppConfigHelper from "../helpers/AppConfigHelper";
/**
 *
 * @param {String} methodType - 'GET' or 'POST' or 'PUT' or 'DELETE'. GET is default.
 * @param {String} url - the url
 * @param {Object} payload - the payload as object for POST or PUT requests.
 * @param {Number | [Number] } respCode - expected response code or array of codes. Default is 200.
 * @param {String} respType - response type. Values are 'json' or 'text' or 'restrict'. json is default.
 *    Restrict will not check the response at all.
 * @returns
 */
export async function executeService(
  url,
  methodType = "GET",
  payload,
  respCode = 200,
  respType = "json",
  useToken = true,
  bearerTokenOverride,
) {
  /* console.log('Execute Service',
     url,
     methodType,
     payload,
     respCode,
     respType,
     useToken); */
  try {
    const headers = await getHeaders(useToken, bearerTokenOverride);
    let options = {
      method: methodType,
      headers,
    };
    if (payload && (methodType === "POST" || methodType === "PUT")) {
      options = Object.assign({}, options, { body: JSON.stringify(payload) });
    }
    const response = await fetch(url, options);
    return processResponse(response, respCode, respType, methodType);
  } catch (error) {
    // console.error(error);
    throw error;
  }
}

async function processResponse(response, respCode, respType, methodType) {
  const code = response.status;
  if (
    respCode &&
    ((!Array.isArray(respCode) && code !== respCode) ||
      (Array.isArray(respCode) && !respCode.includes(code)))
  ) {
    let message = `Error executing ${methodType}`;
    if (respType === "json") {
      const errorBody = await response.json();
      message = errorBody.message;
    } else {
      const errorBodyAsText = await response.text();
      message = errorBodyAsText;
    }
    throw { status: code, message };
  }
  if (respCode !== 204 && respCode !== 201) {
    if (respType !== "restrict") {
      if (respType === "text") {
        // console.log('Returning response as Text');
        return response.text();
      }
      const json = response.json();
      // console.log(JSON.stringify(json));
      return json;
    }
  }
}

async function getHeaders(useToken = true, bearerTokenOverride) {
  const defaultHeaders = {
    "Content-Type": "application/json",
    "x-authenticate-with": "exhut",
  };
  if (bearerTokenOverride) {
    return Object.assign({}, defaultHeaders, {
      bearer: bearerTokenOverride,
    });
  }
  if (useToken) {
    const signature = AppConfigHelper.getSignature();
    return Object.assign({}, defaultHeaders, {
      bearer: signature,
    });
  }
  return defaultHeaders;
}
