import React from "react";
import PropTypes from "prop-types";
import store from "../../../store";
import { DeleteButtonComponent } from "../../shared/ButtonComponent";

export default class IncomeRowComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <li className="tabbed-list-li thin-list" key={this.props.key}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px 0",
          }}
        >
          <div style={{ maxWidth: "40%", minWidth: "30%" }}>
            <span className="boldText">{this.props.income.name}</span>
          </div>

          <div style={{ maxWidth: "30%", minWidth: "20%" }}>
            <span>{this.props.income.amount}</span>
          </div>

          <div style={{ width: "10%" }}>
            {this.props.income.username ===
              store.getState().app.user.username && (
              <DeleteButtonComponent
                onClick={() => this.props.onDeleteClick(this.props.income.id)}
              />
            )}
          </div>
        </div>
      </li>
    );
  }
}

IncomeRowComponent.propTypes = {
  key: PropTypes.number.isRequired,
  income: PropTypes.any.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};
