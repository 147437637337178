import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getGroupDetails } from "../../actions/groupsAction";
import { createGroup } from "../../services/GroupService";
import store from "../../store";
import StatusMessageComponent2 from "../shared/StatusMessageComponent2";
import TextInputComponent2 from "../shared/TextInputComponent2";
import "./GroupsComponent.css";
import GroupsRowComponent from "./GroupsRowComponent";

function mapStateToProps(state) {
  const { user } = state.app;
  const { groups, groupid } = state.group;
  return {
    groups,
    groupid,
    user,
  };
}

export default connect(mapStateToProps)(GroupsComponent);

function GroupsComponent({ groups, groupid: defaultGroupId, user, doLogout }) {
  const [groupNameEditText, setGroupNameEditText] = useState("");
  const [statusMsg, setStatusMsg] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    store.dispatch(getGroupDetails());
  }, []);

  const createGroupHandler = async () => {
    setIsLoading(true);
    createGroup(groupNameEditText)
      .then(() => {
        setStatusMsg({
          text: "Group successfully added.",
          type: "success",
        });
        setGroupNameEditText("");
        store.dispatch(getGroupDetails());
      })
      .catch((err) => {
        setStatusMsg({
          text: err.message,
          type: "error",
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="margin-center">
      <StatusMessageComponent2 message={statusMsg} />

      <div className="title topMargin0P">Groups</div>

      <div
        style={{
          marginTop: "2%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="groups-left-panel">
          <div>
            <span>
              {" "}
              <b>Groups </b> are essential to track your data. You can add
              members to your groups and start sharing expenses and savings.{" "}
            </span>
          </div>

          <div className="divSpread" style={{ marginTop: "10%" }}>
            <div className="divCenterAlign">
              <TextInputComponent2
                id="groupname"
                type="text"
                errorid="email-error"
                value={groupNameEditText}
                maxLength={35}
                placeholder={"Enter group name..."}
                onChange={(e) => setGroupNameEditText(e.target.value)}
              />
            </div>

            <input
              type="button"
              className="bigButtonGreen"
              value="Add"
              disabled={isLoading}
              onClick={createGroupHandler}
            />
          </div>
        </div>

        <div style={{ width: "50%" }} className="groups-right-panel">
          <ol style={{ listStyle: "none" }}>
            {groups &&
              groups.map((group, i) => {
                return (
                  <GroupsRowComponent
                    key={i}
                    group={group}
                    defaultGroupId={defaultGroupId}
                  />
                );
              })}
          </ol>
        </div>
      </div>
    </div>
  );
}

GroupsComponent.propTypes = {
  user: PropTypes.any.isRequired,
  doLogout: PropTypes.func.isRequired,
};
