import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export async function getExpenseCategoryTotal(groupid, month, year, misc) {
  const queryParams = misc ? "misc=true" : "";
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/month/${year}/${month}/total?${queryParams}`;
  return executeService(url, "GET", undefined, 200, "json");
}

export async function getExpensesRelated(
  groupid,
  year,
  month,
  category,
  subcategory,
) {
  const queryParams = `category=${category}&subcategory=${subcategory}`;
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/month/${year}/${month}/related`;
  return executeService(`${url}?${queryParams}`);
}

export async function getExpenseCategoryForPieChart(groupid, month, year) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/month/${year}/${month}/category`;
  return executeService(url, "GET", undefined, 200, "json");
}

export async function getExpenseCategoryForLineChart(groupid, month, year) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/${year}/${month}/subcategoryDiff`;
  return executeService(url, "GET", undefined, 200, "json");
}

export async function getMiscExpensesForMonth(groupid, month, year) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/month/${year}/${month}?type=misc`;
  return executeService(url);
}

export async function getExpensesForMonthYear(
  groupid,
  month,
  year,
  sortBy,
  categoryFilter,
  subcategoryFilter,
) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/month/${year}/${month}`;
  const queryParams = `?sortBy=${sortBy}&category=${categoryFilter}&subcategory&${subcategoryFilter}`;
  return executeService(url + queryParams, "GET", undefined, 200, "json");
}

export async function validateExpensePhrase(phrase, username) {
  const url = `${new FNXConnection().getURL()}/expense/ai/validate`;
  return executeService(url, "POST", { phrase, username }, 200);
}

export function createExpenseMulti(payload) {
  const url = `${new FNXConnection().getURL()}/expense/multi`;
  // Do not expect 210 multi status, since we have to report the error in the UI.
  return executeService(url, "PUT", { expenseArray: payload }, 200, "json");
}

export function createExpense(
  category,
  subcategory,
  date,
  amount,
  comments,
  creditcard,
  groupid,
  username,
) {
  const url = `${new FNXConnection().getURL()}/expense`;
  return executeService(
    url,
    "PUT",
    {
      category,
      subcategory,
      date,
      amount,
      comments,
      creditcard,
      groupid,
      username,
    },
    200,
    "json",
  );
}

export async function createMiscExpense(name, amount, date, username) {
  const url = `${new FNXConnection().getURL()}/expense`;
  return executeService(
    url,
    "PUT",
    { name, amount, date, username, type: "misc" },
    201,
    "json",
  );
}

export async function inflateExpense(groupid, expenseId) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/${expenseId}`;
  return executeService(url);
}

export async function moveExpenseToGroup(expenseid, groupid, targetgroupid) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/${expenseid}/move/group/${targetgroupid}`;
  return executeService(url, "POST");
}

export async function moveExpenseToTrip(expenseid, groupid, tripid) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/${expenseid}/move/trip/${tripid}`;
  return executeService(url, "POST");
}

export async function moveExpenseToLedger(expenseid, groupid, ledgerId) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/${expenseid}/move/ledgers/${ledgerId}`;
  return executeService(url, "POST");
}

export async function updateExpense(
  expenseid,
  category,
  subcategory,
  date,
  amount,
  comments,
  cardid,
  groupid,
) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/${expenseid}`;
  return executeService(url, "POST", {
    expenseid,
    category,
    subcategory,
    date,
    amount,
    comments,
    creditcard: cardid,
    groupid,
  });
}

export async function updateMiscExpense(
  groupid,
  username,
  expenseid,
  date,
  amount,
  comments,
) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/${expenseid}`;
  return executeService(url, "POST", {
    username,
    expenseid,
    date,
    amount,
    comments,
  });
}

export async function deleteExpense(expenseId) {
  const url = `${new FNXConnection().getURL()}/expense/${expenseId}`;
  return executeService(url, "DELETE", undefined, undefined, "text");
}

export async function createExpenseLegacy(
  category,
  subcategory,
  date,
  amount,
  comments,
  card,
  username,
) {
  const url = `${new FNXConnection().getURL()}/expense`;
  return executeService(
    url,
    "PUT",
    {
      category,
      subcategory,
      date,
      amount,
      creditcard: card,
      comments,
      username,
    },
    200,
  );
}

export async function searchExpense(groupid, searchTerm, miscOnly, tripOnly) {
  let queryParams = `q=${searchTerm}`;
  if (miscOnly === true) {
    queryParams += `&misconly=true`;
  }
  if (tripOnly === true) {
    queryParams += `&triponly=true`;
  }
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/search?${queryParams}`;
  return executeService(url, "GET", undefined, 200);
}

export async function clarifyExpense(groupid, expenseid) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/${expenseid}/clarify?sendSMS=true`;
  return executeService(url, "POST", undefined, 200);
}

export async function moveExpenseToLoanPayment(groupid, expenseid, loanid) {
  const url = `${new FNXConnection().getURL()}/expense/${groupid}/${expenseid}/loan/${loanid}`;
  return executeService(url, "POST", undefined, 200);
}
