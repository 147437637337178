import moment from "moment";
import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export function getSubscriptions() {
  const url = new FNXConnection().getServicesURL() + "/subscriptions";
  return executeService(url);
}

export function createSubscriptions(
  category,
  subcategory,
  amount,
  frequency,
  dayOfMonth,
  name,
  creditcard,
) {
  const url = new FNXConnection().getServicesURL() + "/subscriptions";
  return executeService(
    url,
    "POST",
    {
      category,
      subcategory,
      amount,
      frequency,
      dayOfMonth,
      name,
      creditcard,
      createdDate: moment().format("YYYY-MM-DD"),
    },
    201,
    "restrict",
  );
}

export function deleteSubscription(id) {
  const url = `${new FNXConnection().getServicesURL()}/subscriptions/${id}`;
  return executeService(url, "DELETE", undefined, 204);
}
