export const ACTION_LOGIN_USER = "LOGIN_USER";
export const ACTION_LOGOUT_USER = "LOGOUT_USER";
export const ACTION_LOGIN_SUCCESS = "LOGIN_USER_SUCCESS";
export const ACTION_LOGIN_FAILED = "LOGIN_USER_FAILED";
export const ACTION_REFRESH_USER = "REFRESH_USER";
export const ACTION_DO_REFRESH_USER = "ACTION_DO_REFRESH_USER";
export const ACTION_ON_LOGIN_SUCCESS = "ON_LOGIN_SUCCESS";

export function loginSuccess(payload) {
  return {
    type: ACTION_LOGIN_SUCCESS,
    payload,
  };
}

/**
 * This action is tied to a saga which will spawn the downstream sagas such as getSettings or
 * getAccounts.
 */
export function onLoginSuccess() {
  return {
    type: ACTION_ON_LOGIN_SUCCESS,
  };
}

export function loginFailed(payload) {
  return {
    type: ACTION_LOGIN_FAILED,
    payload,
  };
}

export function loginUser(payload) {
  return {
    type: ACTION_LOGIN_USER,
    payload,
  };
}

export function logoutUser(msg) {
  return {
    type: ACTION_LOGOUT_USER,
    payload: msg,
  };
}

export function refreshUser(payload) {
  return {
    type: ACTION_REFRESH_USER,
    payload,
  };
}

// Same as above, but this is associated with saga that refreshes the user behind the scenes.
export function doRefreshUser() {
  return {
    type: ACTION_DO_REFRESH_USER,
  };
}
