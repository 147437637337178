import React from "react";
import PropTypes from "prop-types";
/**
 * AITextInputComponent with inline error, warning and success message.
 *
 * id : {String} id of the text field.
 *
 * value: {String} value of the text field.
 *
 * onChange: Function callback on text change.
 *
 * inlineMessageArr : {Array}
 *
 * inlineMessageArr.type: {String} error, warn, success
 *
 * inlineMessageArr.text: {String} the message
 *
 */
export default class AITextInputComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  getClassName(type) {
    if (type === "success") {
      return "successMsg";
    } else if (type === "warn") {
      return "warnMsg";
    }
    return "errorMsg";
  }

  render() {
    return (
      <div
        id="expense-phrase-pane"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "50px",
          justifyContent: "space-between",
        }}
      >
        <input
          type={"text"}
          id={this.props.id}
          value={this.props.value}
          onChange={this.props.onChange}
          style={{ width: "370px" }}
          placeholder={"Enter expense phrase..."}
          autoComplete="off"
        />
        <ul style={{ listStyle: "disc", width: "370px" }}>
          {this.props.inlineMessageArr.map((list, i) => {
            return (
              <li key={i}>
                <span
                  className={
                    "expense-ai-inline " +
                    this.getClassName(list.type) +
                    " smallText"
                  }
                  style={{ maxWidth: "370px", marginTop: "10px" }}
                >
                  {list.text}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

AITextInputComponent.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  inlineMessageArr: PropTypes.array,
};
