import React from "react";
import PropTypes from "prop-types";
/**
 * TextInputComponent with inline error message.
 *
 * type: {String} 'text' or 'password'. Default is password.
 *
 * id : {String} id of the text field.
 *
 * value: {String} value of the text field.
 *
 * height: {String} height of whole component.
 *
 * width: {String} width of the input component.
 *
 * onChange: Function callback on text change.
 *
 * onClick: Function callback on click on input field.
 *
 * onFocus: Function callback for on focus.
 *
 * error : {String} pass not null string value for message to be shown. If null, then inline error
 * will not be shown.
 *
 * errorid: {String} id of the span error message
 *
 * success : {String} the success message.
 *
 * successid: {String} id of the span error message
 *
 * width: {String} style width
 *
 * placeholder: {String} placeholder text
 */
export default class TextInputComponent2 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: this.props.height ?? "50px",
          justifyContent: "space-between",
        }}
      >
        <input
          type={this.props.type || "text"}
          id={this.props.id}
          value={this.props.value}
          onChange={this.props.onChange}
          onClick={this.props.onClick}
          style={{
            width: this.props.width ?? 300,
            height: "40px",
            borderRadius: "0px",
            fontSize: "20px",
            border: "0px",
            borderBottom: "1px solid black",
            backgroundColor: this.props.backgroundColor,
          }}
          placeholder={this.props.placeholder}
          onFocus={this.props.onFocus}
          autoComplete="off"
          {...this.props}
        />
        {this.props.error && (
          <span id={this.props.errorid} className="errorMsg mediumTest my-10">
            {" "}
            {this.props.error}{" "}
          </span>
        )}
        {this.props.success && (
          <span
            id={this.props.successid}
            className="successMsg mediumTest my-10"
          >
            {" "}
            {this.props.success}{" "}
          </span>
        )}
      </div>
    );
  }
}

TextInputComponent2.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  errorid: PropTypes.string,
  successid: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  error: PropTypes.string,
  success: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  backgroundColor: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
