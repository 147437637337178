import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { setEditExpenseId } from "../../actions/expensesAction";
import { getCurrencySymbol } from "../../helpers/CurrencyHelper";
import * as DateHelper from "../../helpers/DateHelper";
import {
  getCityScapeIcon,
  getClockIcon,
  getIconForCategorySubcategory,
} from "../../helpers/IconHelper";
import { getExpensesForMonthYear } from "../../services/ExpenseService";
import store from "../../store";
import "./HomePageExpenseListComponent.css";
import worker_script from "../../workers/expense-dupe-check.worker.js";

export default class HomePageExpenseListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFilterText: "",
      accountFilter: "",
      userFilter: "all",
      categoryFilter: "all-cat",
      subcategoryFilter: undefined,
      expenses: [],
      filteredExpenses: [],
      downloadableContent: undefined,
    };
    this.expenses = [];
    this._filterList = this._filterList.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.prepareDownloadableContent =
      this.prepareDownloadableContent.bind(this);
    this.worker = new Worker(new URL(worker_script));
  }

  async componentDidMount() {
    await this.fetchData();

    this.worker.onmessage = (m) => {
      // console.log("msg from worker: ", m.data);
    };
  }

  async fetchData() {
    try {
      const _selectedMonth = store.getState().app.selectedMonth;
      const _selectedYear = store.getState().app.selectedYear;

      const expenses = await getExpensesForMonthYear(
        this.props.groupid,
        _selectedMonth,
        _selectedYear,
      );
      this.expenses = expenses;
      this.prepareDownloadableContent(expenses);
      this.setState({ expenses, filteredExpenses: expenses });
    } catch (err) {
      console.error(err);
    }
  }

  prepareDownloadableContent(expenses) {
    const headers = [
      "#",
      "Category",
      "SubCategory",
      "Date",
      "Amount",
      "Comments",
      "Account",
    ];
    const expenseRows = [headers];

    for (let i = 0; i < expenses.length; i++) {
      const expense = expenses[i];
      expenseRows.push([
        i + 1,
        expense.category,
        expense.subcategory,
        DateHelper.getLocalizedDate(expense.expensedate, this.props.user),
        expense.amount,
        expense.comments ?? "",
        expense.cardname,
      ]);
    }

    let csvContent = "data:text/csv;charset=utf-8,";

    expenseRows.forEach((data) => {
      const row = data.join(",");
      csvContent += row + "\n";
    });
    this.setState({
      downloadableContent: encodeURI(csvContent).replace("#", "%23"),
    });
  }

  _editExpenseHandler(id) {
    store.dispatch(setEditExpenseId(id));
  }

  async componentDidUpdate(prevProps, prevState) {
    this.expenses = this.state.expenses;
    // if the user selects a different month, then reset the filters.
    if (this.props.selectedMonth !== prevProps.selectedMonth) {
      this.setState({ userFilter: "all" });
    }
    if (
      this.props.selectedMonth !== prevProps.selectedMonth ||
      this.props.selectedYear !== prevProps.selectedYear
    ) {
      await this.fetchData();
    }
  }

  resetFilters() {
    this.setState({
      searchFilterText: "",
      accountFilter: "",
      userFilter: "all",
      categoryFilter: "all-cat",
      filteredExpenses: this.state.expenses,
    });
  }

  _filterListByTextSearch() {
    const listItems = document.querySelectorAll(".tabbed-list-li");
    for (const l of listItems) {
      if (
        l
          .querySelector(".expense-list-right-comments")
          .innerText.toLowerCase()
          .includes(this.state.searchFilterText.toLowerCase())
      ) {
        l.style.display = "block";
      } else {
        l.style.display = "none";
      }
    }
  }
  _filterList() {
    const originalExpenses = this.state.expenses;
    let filteredExpenses = originalExpenses; // reset the filtered expenses here.
    if (this.state.userFilter !== "all") {
      filteredExpenses = originalExpenses.filter(
        (expense) => expense.username === this.state.userFilter,
      );
    }
    if (this.state.categoryFilter !== "all-cat") {
      filteredExpenses = filteredExpenses.filter(
        (expense) => expense.category === this.state.categoryFilter,
      );
    }
    if (
      !!this.state.subcategoryFilter &&
      this.state.subcategoryFilter !== "all-subcat"
    ) {
      filteredExpenses = filteredExpenses.filter(
        (expense) => expense.subcategory === this.state.subcategoryFilter,
      );
    }
    if (this.state.accountFilter !== "") {
      filteredExpenses = filteredExpenses.filter(
        (expense) => expense.creditcard === this.state.accountFilter,
      );
    }
    this.setState({ filteredExpenses });
  }

  render() {
    if (this.state.expenses.length === 0) {
      return null;
    }
    return (
      <>
        <hr className="shadowed" />

        <div className="home-page-expense-list-body">
          <div className="subtitle">List of Expenses</div>
          <div className="divSpreadRight">
            <input
              type="text"
              placeholder="Search by comments.."
              value={this.state.searchFilterText}
              onChange={(e) =>
                this.setState(
                  { searchFilterText: e.target.value },
                  this._filterListByTextSearch,
                )
              }
            />

            {this.state.downloadableContent && (
              <a
                className="hyperlink-green"
                download={`expense_hut_expenses_${new Date().toDateString()}.csv`}
                href={this.state.downloadableContent}
              >
                Download
              </a>
            )}
          </div>

          <div className="divFlex">
            <div id="expense-list-filter">
              <div className="expense-list-filter-section">
                {this.state.expenses?.length !==
                  this.state.filteredExpenses?.length && (
                  <a onClick={this.resetFilters} className="hyperlink">
                    Reset Filters
                  </a>
                )}
              </div>

              <div className="expense-list-filter-section">
                <span>Filter By</span>
              </div>
              <div className="expense-list-filter-section-body">
                <select
                  style={{ width: "150px" }}
                  className="filter-select"
                  id="users-filter"
                  onChange={(e) =>
                    this.setState(
                      { userFilter: e.target.value },
                      this._filterList,
                    )
                  }
                  value={this.state.userFilter}
                >
                  <option value="all">All</option>
                  <option value={this.props.user.username}>
                    My Expenses Only
                  </option>
                </select>
              </div>

              {this.state.userFilter !== "all" && (
                <div className="expense-list-filter-section">
                  <span style={{ fontSize: "medium", fontWeight: "bold" }}>
                    Accounts
                  </span>
                </div>
              )}
              {this.state.userFilter !== "all" && (
                <div className="expense-list-filter-section-body">
                  <select
                    style={{ width: "150px" }}
                    className="filter-select"
                    id="accounts-filter"
                    onChange={(e) =>
                      this.setState(
                        { accountFilter: e.target.value },
                        this._filterList,
                      )
                    }
                  >
                    <option value=""> --Select-- </option>
                    {store.getState().app.accounts?.map((acc, i) => {
                      return (
                        <option key={i} value={acc.id}>
                          {" "}
                          {acc.name}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              <div className="expense-list-filter-section">
                <span>Category</span>
              </div>
              <div className="expense-list-filter-section-body">
                <select
                  style={{ width: "150px" }}
                  className="filter-select"
                  id="category-filter"
                  onChange={(e) =>
                    this.setState(
                      { categoryFilter: e.target.value },
                      this._filterList,
                    )
                  }
                  value={this.state.categoryFilter}
                >
                  <option value="all-cat">--All--</option>
                  {this.props.categories?.map((cat, i) => (
                    <option key={i} value={cat}>
                      {" "}
                      {cat}{" "}
                    </option>
                  ))}
                </select>
              </div>

              {this.state.categoryFilter !== "all-cat" && (
                <>
                  <div className="expense-list-filter-section">
                    <span>Sub Category</span>
                  </div>

                  <div className="expense-list-filter-section-body">
                    <select
                      style={{ width: "150px" }}
                      className="filter-select"
                      id="subcategory-filter"
                      onChange={(e) =>
                        this.setState(
                          { subcategoryFilter: e.target.value },
                          this._filterList,
                        )
                      }
                      value={this.state.subcategoryFilter}
                    >
                      <option value="all-subcat">--All--</option>
                      {this.props.categoriesMap[this.state.categoryFilter]?.map(
                        (cat, i) => (
                          <option key={i} value={cat}>
                            {" "}
                            {cat}{" "}
                          </option>
                        ),
                      )}
                    </select>
                  </div>
                </>
              )}
            </div>
            <div id="expenses-list">
              <ol style={{ listStyle: "none" }}>
                {this.state.filteredExpenses?.map((expense, i) => {
                  return (
                    <li
                      className={
                        expense.username === this.props.user.username
                          ? "tabbed-list-li"
                          : "tabbed-list-li notself"
                      }
                      key={i}
                    >
                      <Link
                        to={`/editExpense`}
                        onClick={() =>
                          store.dispatch(setEditExpenseId(expense.id))
                        }
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div id="expenses-list-container">
                          <div id="expense-list-left">
                            <div>
                              <img
                                src={getIconForCategorySubcategory(
                                  expense.subcategory,
                                  expense.category,
                                )}
                                width="50px"
                              />
                            </div>
                            <div>
                              {DateHelper.getDateExcludingTime(
                                expense.expensedate,
                              )}
                            </div>
                          </div>

                          <div id="expense-list-middle">
                            {expense?.expense_source && (
                              <img
                                src={
                                  expense.expense_source === "recurring"
                                    ? getClockIcon()
                                    : getCityScapeIcon()
                                }
                                width={60}
                                style={{ opacity: 0.1 }}
                                title={
                                  expense.source === "recurring"
                                    ? "recurring"
                                    : "plaid"
                                }
                              />
                            )}
                          </div>

                          <div className="expense-list-right">
                            <div>
                              <span className="expense-list-right-amount">
                                {getCurrencySymbol(this.props.user) +
                                  " " +
                                  expense.amount}
                              </span>
                            </div>
                            <div className="expense-list-right-comments">
                              <span>{expense.comments}</span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      </>
    );
  }
}

HomePageExpenseListComponent.propTypes = {
  expenses: PropTypes.array.isRequired,
  user: PropTypes.any.isRequired,
  selectedMonth: PropTypes.number,
  selectedYear: PropTypes.number,
  categories: PropTypes.array,
  categoriesMap: PropTypes.array,
  groupid: PropTypes.string,
};
