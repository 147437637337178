import React from "react";
import "./team.component.css";
import { getPriyaProfileIcon } from "../../helpers/IconHelper";
import { Link } from "react-router-dom";

export default function PriyaTeamComponent({ isMobile }) {
  return (
    <div id="contents">
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          padding: 40,
        }}
      >
        {isMobile && (
          <div
            className="divCenterAlign divColumn"
            style={{ marginBottom: 20 }}
          >
            <Link to="/">
              <img
                src={"/assets/logo.png"}
                width={100}
                style={{ borderRadius: 20 }}
              />
            </Link>

            <h1 style={{ color: "var(--fnx-total-green)" }}>Meet the team</h1>
          </div>
        )}

        <div style={{ flex: 1 }} className="divCenterAlign">
          <img
            src={getPriyaProfileIcon()}
            width="50%"
            style={{ borderRadius: isMobile ? 200 : 300 }}
          />
        </div>

        <div style={{ flex: 1 }}>
          <div>
            <div style={{ alignItems: "center", marginBottom: "20px" }}>
              <h3> About Priya</h3>
              <a
                href="https://www.linkedin.com/in/priya-nath-b7693678"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/linkedin-logo.png"
                  width="60px"
                  height="15px"
                  style={{ marginLeft: "10px" }}
                />
              </a>
            </div>

            <p>
              I'm a BI Lead experienced in reporting & analytics that builds
              business intelligence solutions and strategies for clients. I
              enjoy tailoring data according to the business needs to make an
              impactful sight.
            </p>

            <p>
              I have a strong understanding in business analytics, leadership,
              data warehousing, Big Data concepts, database structures, risk
              management principles and client relationships.
            </p>

            <p>
              Technologies change rapidly so learning is the key mantra. I
              gather knowledge from the tools & technologies that I learn both
              in and outside my professional career.
            </p>

            <p>
              Outside my professional interests - I am a mom, a wife, a dancer,
              ping pong player, gardner and a fantastic cook.
            </p>
          </div>

          <div style={{ width: "100%" }}>
            <div>
              <h3> My Experience </h3>
            </div>

            <div>
              <table className="profile-table">
                <tbody>
                  <tr>
                    <td>Analytics & Reporting</td>
                    <td>
                      Looker, Arcadia, Paxata, Cognos 10 & 11, Power BI,
                      Pentaho, Advanced Excel, VBA & Macro
                    </td>
                  </tr>

                  <tr>
                    <td>Query/ETL Tool</td>

                    <td>
                      Hive-HUE, SQL developer, SQL Workbench, Toad, Pentaho Data
                      Integration
                    </td>
                  </tr>

                  <tr>
                    <td>Database/ Datalake</td>

                    <td>Snowflake, Hive, Oracle 12 C, Green Plum</td>
                  </tr>

                  <tr>
                    <td>Query Language</td>

                    <td> Oracle SQL, Hive-sql, DAX, Power Query </td>
                  </tr>

                  <tr>
                    <td>Issue tracking</td>

                    <td> JIRA, Kanban Flow </td>
                  </tr>

                  <tr>
                    <td>Other Tools</td>

                    <td>
                      {" "}
                      Tectia, WinSCP, Putty, Motio PI, SVN, Power Point, Visio
                    </td>
                  </tr>

                  <tr>
                    <td>SDLC Methodologies</td>

                    <td> Agile, Waterfall </td>
                  </tr>

                  <tr>
                    <td>Programming Language</td>

                    <td> HTML/CSS, C, Core java </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
