import { Link } from "react-router-dom";

export default function LogoComponent({ authenticated }) {
  return (
    <div className="logo">
      <Link to={authenticated ? "/home" : "/"}>
        <img src="/assets/logo.png" />
      </Link>
    </div>
  );
}
