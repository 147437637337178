import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";
import { Link, Redirect } from "react-router-dom";
import { getLocalizedDate } from "../../../helpers/DateHelper";
import { formatToLocaleAmount } from "../../../helpers/NumberHelper";
import {
  deleteLoanPayment,
  getLoanPayments,
  getLoanPaymentsTotal,
} from "../../../services/LoanPaymentService";
import { deleteLoan, getLoanById } from "../../../services/LoanService";
import { DeleteButtonComponent } from "../../shared/ButtonComponent";
import ModalDialogComponent from "../../shared/ModalDialogComponent";
import StatusMessageComponent2 from "../../shared/StatusMessageComponent2";
import CreateLoanDialogComponent from "../CreateLoanDialogComponent";
import "../LoansComponent.css";
import { LoansQuickViewTableComponent } from "../LoansQuickViewComponent";
import CreateLoanPaymentDialogComponent from "./CreateLoanPaymentDialogComponent";

export default class LoansPaymentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: undefined,
      loan: undefined,
      payments: [],
      paymentsTotal: 0,
      statusMsg: undefined,
      loanId: undefined,
      deleteSuccess: false,
      deletePaymentId: undefined,
    };
    this.onFailureHandler = this.onFailureHandler.bind(this);
    this.onSuccessHandler = this.onSuccessHandler.bind(this);
    this.fetchAllData = this.fetchAllData.bind(this);
    this.deleteLoansHandler = this.deleteLoansHandler.bind(this);
    this.deletePaymentsHandler = this.deletePaymentsHandler.bind(this);
    this.handleUpdateLoanSuccess = this.handleUpdateLoanSuccess.bind(this);
  }

  async componentDidMount() {
    this.fetchAllData();
  }

  async onSuccessHandler() {
    try {
      this.setState({ mode: undefined });
      this.setState({
        statusMsg: {
          type: "success",
          text: "Payment successfully created.",
        },
      });
      await this.fetchAllData();
    } catch (err) {
      this.onFailureHandler(err);
    }
  }

  onFailureHandler(err) {
    if (err.status === 401) {
      this.props.doLogout();
    } else {
      console.error(err);
      this.setState({ mode: undefined });
      this.setState({
        statusMsg: {
          type: "error",
          text: "Whoops! Something went wrong.",
        },
      });
    }
  }

  async deleteLoansHandler() {
    try {
      await deleteLoan(this.props.user.groupid, this.state.loanId);
      this.setState({ deleteSuccess: "true" });
    } catch (err) {
      this.onFailureHandler(err);
    }
  }

  async deletePaymentsHandler() {
    try {
      await deleteLoanPayment(
        this.props.user.groupid,
        this.state.loanId,
        this.state.deletePaymentId,
      );
      this.setState({
        deletePaymentId: undefined,
        statusMsg: {
          type: "success",
          text: "Payment successfully deleted.",
        },
      });
      await this.fetchAllData();
    } catch (err) {
      this.onFailureHandler(err);
    }
  }

  async fetchAllData() {
    const id = this.props.match?.params?.id;
    if (id) {
      // this is edit loan usecase
      try {
        const loan = await getLoanById(this.props.user.groupid, id);
        this.setState({ loan, loanId: loan.id });
      } catch (err) {
        this.onFailureHandler(err);
      }
    }
    const payments = await getLoanPayments(
      this.props.user.groupid,
      this.state.loanId,
    );
    const paymentsTotal = await getLoanPaymentsTotal(
      this.props.user.groupid,
      this.state.loanId,
    );
    this.setState({ payments, paymentsTotal: paymentsTotal.total });
  }

  async handleUpdateLoanSuccess() {
    await this.fetchAllData();
    this.setState({
      mode: undefined,
      statusMsg: {
        type: "success",
        text: "Loan data successfully modified.",
      },
    });
  }

  render() {
    if (this.state.deleteSuccess) {
      return <Redirect to="/loans" />;
    }

    return (
      <div className="margin-center">
        <StatusMessageComponent2 message={this.state.statusMsg} />

        {this.state.mode === "delete-loan" && ( // this is delete loan
          <ModalDialogComponent
            id="delete-loan-dialog"
            title="Delete Loan"
            message="Are you sure? All payments associated with this loan will also be deleted."
            onSuccess={this.deleteLoansHandler}
            onCancel={() => this.setState({ mode: undefined })}
          />
        )}

        {this.state.mode === "edit-loan" && (
          <CreateLoanDialogComponent
            title="Edit Loan"
            user={this.props.user}
            editLoanId={this.state.loanId}
            onFailure={() =>
              this.setState({
                mode: undefined,
                statusMsg: {
                  type: "error",
                  text: "Whoops! Something went wrong. Please try again.",
                },
              })
            }
            onSuccess={this.handleUpdateLoanSuccess}
            onCancel={() => this.setState({ mode: undefined })}
          />
        )}

        {this.state.deletePaymentId && (
          <ModalDialogComponent
            id="delete-payment-dialog"
            title="Delete Payment"
            message="Are you sure?"
            onSuccess={this.deletePaymentsHandler}
            onCancel={() => this.setState({ deletePaymentId: undefined })}
          />
        )}

        <div>
          {this.state.mode === "create-payment" && (
            <CreateLoanPaymentDialogComponent
              user={this.props.user}
              loanId={this.state.loanId}
              onSuccess={this.onSuccessHandler}
              onFailure={(err) => this.onFailureHandler(err)}
              onCancel={() => this.setState({ mode: undefined })}
            />
          )}
        </div>

        <div className="title-extra topMargin1P">
          Loan: {this.state.loan?.name}
        </div>

        {this.state.loan?.active === false && (
          <div className="divCenterAlign">
            <span
              style={{
                backgroundColor: "var(--fnx-green)",
                color: "white",
                padding: "5px 10px",
                borderRadius: 15,
              }}
            >
              Congratulations! This loan is paid off.{" "}
            </span>
          </div>
        )}

        <div className="divFlex">
          <div className="loans-panel-left">
            <div className="loans-div-header">
              <Link to="/loans"> Back </Link>
              <span className="total">
                {" "}
                {formatToLocaleAmount(this.state.paymentsTotal)}{" "}
              </span>
              {(this.state.loan?.active === true ||
                this.state.loan?.active === null) && (
                <a
                  className="hyperlink"
                  onClick={() => this.setState({ mode: "create-payment" })}
                >
                  {" "}
                  Add Payment
                </a>
              )}
            </div>

            <div
              style={{
                border: "1px solid var(--fnx-light-grey0)",
                backgroundColor: "var(--fnx-light-grey5)",
                borderRadius: "5px",
                minHeight: "400px",
                padding: 20,
              }}
            >
              <div>
                <h3> Payments</h3>
              </div>

              {this.state.payments &&
                this.state.payments.map((payment, i) => (
                  <Accordion key={i}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography sx={{ width: "10%", flexShrink: 0 }}>
                        <strong> {i + 1 + "."}</strong>
                      </Typography>
                      <Typography sx={{ width: "40%", flexShrink: 0 }}>
                        {getLocalizedDate(payment.created_at, this.props.user)}
                      </Typography>
                      <Typography sx={{ width: "40%", flexShrink: 0 }}>
                        {formatToLocaleAmount(payment.amount)}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography>
                        <strong> Memo: </strong> {payment.comments}
                      </Typography>
                      <DeleteButtonComponent
                        id={`delete-payment-${i}`}
                        onClick={() =>
                          this.setState({ deletePaymentId: payment.id })
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
          </div>

          <div className="loans-panel-right">
            <div className="loans-div-header">
              <div>
                {(this.state.loan?.active === true ||
                  this.state.loan?.active === null) && (
                  <input
                    type="button"
                    className="fnxBlue"
                    value="Edit Loan"
                    id="edit-loan"
                    onClick={() => this.setState({ mode: "edit-loan" })}
                  />
                )}
              </div>

              <input
                type="button"
                className="fnxRed"
                value="Delete Loan"
                id="delete-loan"
                onClick={() => this.setState({ mode: "delete-loan" })}
              />
            </div>

            {this.state.loan && (
              <LoansQuickViewTableComponent
                user={this.props.user}
                loan={this.state.loan}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

LoansPaymentComponent.propTypes = {
  match: PropTypes.any,
  user: PropTypes.any.isRequired,
  doLogout: PropTypes.func,
  onDeleteSuccess: PropTypes.func,
  loanId: PropTypes.string,
};
