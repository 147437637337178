import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export function getSavings(groupid) {
  const url = new FNXConnection().getServicesURL() + "/" + groupid + "/savings";
  return executeService(url);
}

export function getSavingsEstimate(groupid, username) {
  const url =
    new FNXConnection().getServicesURL() +
    "/" +
    groupid +
    "/savings/" +
    username +
    "/estimate";
  return executeService(url);
}

export function getSavingsById(groupid, savingsId) {
  const url =
    new FNXConnection().getServicesURL() +
    "/" +
    groupid +
    "/savings/" +
    savingsId;
  return executeService(url);
}

export function getSavingsTotal(groupid) {
  const url =
    new FNXConnection().getServicesURL() + "/" + groupid + "/savings/total";
  return executeService(url);
}

export function createSavings(groupid, desc, date, amount, shareableUsers) {
  const url = new FNXConnection().getServicesURL() + "/" + groupid + "/savings";
  return executeService(
    url,
    "POST",
    { desc, date, amount, users: shareableUsers },
    200,
    "json",
  );
}

export function updateSavings(groupid, id, desc, date, amount, shareableUsers) {
  const url =
    new FNXConnection().getServicesURL() + "/" + groupid + "/savings/" + id;
  return executeService(
    url,
    "PUT",
    { desc, date, amount, users: shareableUsers },
    200,
    "json",
  );
}

export function deleteSavings(groupid, username, id) {
  const url = `${new FNXConnection().getServicesURL()}/${groupid}/savings/${id}`;
  return executeService(url, "DELETE", undefined, 204);
}

export function getCurrencyRates(username) {
  const url = new FNXConnection().getURL() + "/currency-rates/savings";
  return executeService(url);
}

export function syncSavingsForPlaidAccounts() {
  const url = new FNXConnection().getServicesURL() + "/plaid/balance/sync";
  return executeService(url, "POST");
}
