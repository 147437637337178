import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getDateExcludingTime } from "../../helpers/DateHelper";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteButtonComponent } from "../shared/ButtonComponent";
import { deleteErrorTransactions } from "../../services/TransactionService";
import store from "../../store";
import { getTransactions } from "../../actions/transactionAction";
class TransactionsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      columns: [
        { field: "id", headerName: "ID", width: 20 },
        { field: "transactionId", headerName: "", display: false },
        {
          field: "timestamp",
          headerName: "Date",
          width: 130,
          valueGetter: (params) =>
            `${getDateExcludingTime(params.row.timestamp)}`,
        },
        {
          field: "bankName",
          headerName: "Bank Name",
          description: "This column has a value getter and is not sortable.",
          sortable: false,
        },
        { field: "message", headerName: "Message", width: 500 },
        {
          field: "actionNeeded",
          headerName: "Action Needed",
          width: 400,
        },
        {
          field: "delete",
          headerName: "",
          width: 40,
          renderCell: (params) => {
            const onDelete = () => {
              deleteErrorTransactions(
                this.props.user.username,
                params.row.transactionId,
              ).then(() => store.dispatch(getTransactions()));
            };

            return <DeleteButtonComponent onClick={onDelete} />;
          },
        },
      ],
    };
  }

  render() {
    const notifications = [];
    let index = 1;
    for (const error of this.props.expenseErrors) {
      const message =
        error.type === "PLAID_GET_EXPENSE_ERROR"
          ? "We encountered an error fetching transactions from your account."
          : "";
      let actionNeeded;
      let bankName;

      try {
        const content = JSON.parse(error.content);
        if (content.error_code === "INVALID_ACCESS_TOKEN") {
          actionNeeded =
            "Nothing needed from your side! We will try again and let you know.";
        } else if (content.error_code === "ITEM_LOGIN_REQUIRED") {
          actionNeeded = "Re-login into your bank from Plaid";
        } else {
          actionNeeded = "-";
        }
        bankName = content.exhutErrorAccountName;
      } catch (e) {
        /* do nothing */
        console.error(e);
      }

      notifications.push({
        id: index++,
        transactionId: error.id,
        timestamp: error.timestamp,
        message,
        actionNeeded,
        bankName,
      });
    }

    return (
      <div className="margin-center">
        <div className="title-extra topMargin5P">Notifications</div>

        {this.props.expenseErrors?.length === 0 && (
          <div className="divCenterAlign" style={{ minHeight: "300px" }}>
            <p className="warnMsg boldText">
              You do not have any notifications at the moment.
            </p>
          </div>
        )}

        {notifications.length > 0 && (
          <div className="divFlex" style={{ height: 800 }}>
            <DataGrid
              rows={notifications}
              columns={this.state.columns}
              pageSize={100}
              height={600}
            />
          </div>
        )}
      </div>
    );
  }
}

TransactionsComponent.propTypes = {
  user: PropTypes.any.isRequired,
  doLogout: PropTypes.func.isRequired,
  expenseErrors: PropTypes.array,
};

function mapStateToProps(state) {
  const { user } = state.app;
  const { expenseErrors } = state.transactions;
  return { user, expenseErrors };
}

export default connect(mapStateToProps)(TransactionsComponent);
