import {
  ACTION_SET_EDIT_LEDGER_ID,
  ACTION_SET_LEDGERS,
} from "../actions/ledgerAction";

const initState = {};

export default function ledger(state = initState, action) {
  switch (action.type) {
    case ACTION_SET_EDIT_LEDGER_ID:
      return {
        ...state,
        editLedgerId: action.payload,
      };
    case ACTION_SET_LEDGERS:
      return {
        ...state,
        ledgers: action.payload,
      };
    default:
      return state;
  }
}
