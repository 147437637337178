import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export function connectPlaid() {
  const url = new FNXConnection().getServicesURL() + "/plaid/create";
  return executeService(url, "POST", {}, 200, "json");
}

export function exchangePlaidtoken({
  token,
  bankName,
  bankId,
  expense_only,
  savings_only,
}) {
  const url = new FNXConnection().getServicesURL() + "/plaid/exchange";
  return executeService(
    url,
    "POST",
    {
      token,
      createdDate: new Date().toISOString(),
      bankName,
      bankId,
      expense_only,
      savings_only,
    },
    200,
    "json",
  );
}

export function getPlaidAccounts() {
  const url = new FNXConnection().getServicesURL() + "/plaid/bank-accounts";
  return executeService(url);
}

export function deletePlaidAccount(id) {
  const url =
    new FNXConnection().getServicesURL() + "/plaid/bank-accounts/" + id;
  return executeService(url, "DELETE", {}, 204, "restrict");
}
