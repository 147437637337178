import React from "react";
import {
  deleteGroup,
  inflateGroup,
  getUsersInGroups2,
  updateGroup,
} from "../../services/GroupService";
import {
  getNoGroupUsers2,
  updateMyDefaultGroup,
} from "../../services/UserService";
import { removeUserFromGroup } from "../../services/UserService2";
import ModalDialogComponent from "../shared/ModalDialogComponent";
import PropTypes from "prop-types";
import StatusMessageComponent2 from "../shared/StatusMessageComponent2";
import { refreshUser } from "../../actions/userAction";
import store from "../../store";
import { addUserToGroup } from "../../services/UserService2";
import {
  getGroupDetails,
  getGroupUsers,
  setDefaultGroup,
} from "../../actions/groupsAction";
import { TextField, Tooltip } from "@mui/material";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function mapStateToProps(state) {
  const { user, navigate, expense, logoutMsg, accounts } = state.app;
  const { groups, groupid } = state.group;

  return {
    user,
    groups,
    groupid,
  };
}

class GroupCreatorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusMsg: undefined,
      usersInGroup: [],
      searchUserResult: undefined,
      group: undefined,
      showDeleteConfirm: false,
      showRemoveUserConfirm: false,
      usernameToDelete: undefined,
      searchUsersEditText: "",
    };
    this.fetchAllUsersInGroup = this.fetchAllUsersInGroup.bind(this);
    this.searchUsers = this.searchUsers.bind(this);
    this.addUserToGroup = this.addUserToGroup.bind(this);
    this.removeUserFromGroup = this.removeUserFromGroup.bind(this);
    this.deleteGroupHandler = this.deleteGroupHandler.bind(this);
    this.removeUserHandler = this.removeUserHandler.bind(this);
    this.switchGroupHandler = this.switchGroupHandler.bind(this);
  }

  async componentDidMount() {
    await this.fetchAllUsersInGroup();
  }

  async fetchAllUsersInGroup() {
    try {
      const group = await inflateGroup(this.props.group.id);
      this.setState({ group });
      const users = await getUsersInGroups2(group.id);
      this.setState({ usersInGroup: users });
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        console.error(err);
      }
    }
  }

  async searchUsers() {
    if (this.state.searchUsersEditText.trim().length >= 3) {
      try {
        const noGroupUsers = await getNoGroupUsers2(
          this.state.searchUsersEditText,
        );
        let user = noGroupUsers[0];
        if (
          user &&
          !this.state.usersInGroup.map((u) => u.email).includes(user.email)
        ) {
          this.setState({ searchUserResult: user });
        }
      } catch (err) {
        if (err.status === 401) {
          this.props.doLogout();
        } else {
          this.setState({
            statusMsg: {
              type: "error",
              text: `Whoops! Unable to search users at this time. Please try again later.`,
            },
          });
        }
      }
    }
  }

  async addUserToGroup(username) {
    try {
      await addUserToGroup(this.props.group.id, username);
      await this.fetchAllUsersInGroup();
      this.setState({ searchUserResult: undefined });
    } catch (err) {
      console.error(err);
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        this.setState({
          statusMsg: {
            type: "error",
            text: err.message,
          },
        });
      }
    }
  }

  async removeUserFromGroup(username) {
    try {
      this.setState({ showRemoveUserConfirm: false });
      await removeUserFromGroup(this.props.group.id, username);
      await this.fetchAllUsersInGroup();
      this.setState({
        statusMsg: {
          type: "success",
          text: "User successfully removed from group.",
        },
      });
    } catch (err) {
      console.error(err);
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        this.setState({
          statusMsg: {
            type: "error",
            text: err.message,
          },
        });
      }
    }
  }

  async deleteGroupHandler() {
    try {
      this.setState({ showDeleteConfirm: false });
      await deleteGroup(this.props.group.id);
      this.props.onBack();
    } catch (err) {
      if (err.status === 401) {
      } else {
        this.setState({
          statusMsg: {
            type: "error",
            text: err.message,
          },
        });
      }
    }
  }

  removeUserHandler(username) {
    this.setState({ showRemoveUserConfirm: true, usernameToDelete: username });
  }

  async switchGroupHandler() {
    try {
      await updateMyDefaultGroup(this.props.group.id);
      store.dispatch(getGroupDetails());
      store.dispatch(getGroupUsers(this.props.group.id));
      this.renderDefaultGroupPanel();
      this.setState({
        statusMsg: {
          type: "success",
          text: "Success changed.",
        },
      });
    } catch (err) {
      this.setState({
        statusMsg: {
          type: "error",
          text: err.message,
        },
      });
    }
  }

  renderDefaultGroupPanel() {
    if (this.props.groupid === this.state.group?.id) {
      return (
        <div
          style={{
            backgroundColor: "var(--fnx-light-green)",
            padding: "10px",
            borderRadius: "10px",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <span className="boldText">This is your default group</span>
        </div>
      );
    } else {
      return (
        <a className="linkAsGreenButton" onClick={this.switchGroupHandler}>
          Make this group default.
        </a>
      );
    }
  }

  render() {
    return (
      <div>
        <StatusMessageComponent2 message={this.state.statusMsg} />

        <div className="title topMargin1P">
          <GroupNameComponent
            group={this.state.group}
            onComplete={this.fetchAllUsersInGroup}
          />
        </div>

        <div style={{ marginLeft: "2%" }}>
          <Link to="/settings/groups">Back</Link>
        </div>

        <div>
          {this.state.showDeleteConfirm && (
            <ModalDialogComponent
              title={"Delete Group"}
              message={
                "Are you sure? This will permanently remove all data associated with this group."
              }
              onSuccessTitle={"Yes"}
              onCancelTitle={"No"}
              onSuccess={() =>
                this.deleteGroupHandler(this.state.deleteGroupId)
              }
              onCancel={() => this.setState({ showDeleteConfirm: false })}
            />
          )}
        </div>

        <div>
          {this.state.showRemoveUserConfirm && (
            <ModalDialogComponent
              title={"Remove User"}
              message={"Are you sure you want to remove this user from group?"}
              onSuccessTitle={"Yes"}
              onCancelTitle={"No"}
              onSuccess={() =>
                this.removeUserFromGroup(this.state.usernameToDelete)
              }
              onCancel={() => this.setState({ showRemoveUserConfirm: false })}
            />
          )}
        </div>

        <div
          style={{
            margin: "2%",
            marginTop: "5%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "50%" }}>
            <div style={{ marginBottom: "44px" }}>
              {this.renderDefaultGroupPanel()}
            </div>

            <div>
              <span className="tableHeader"> Group Members</span>
            </div>

            <div style={{ marginTop: "5%" }}>
              <table className="exhutTable">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Role</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.usersInGroup.map((user, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {user.firstName && user.lastName
                            ? user.lastName + ", " + user.firstName
                            : user.email}
                        </td>
                        <td>
                          {this.props.group.created_by === user.username
                            ? "Admin"
                            : "Member"}
                        </td>
                        <td>
                          {this.props.group.created_by !== user.username && (
                            <a
                              href="#"
                              style={{ color: "red" }}
                              onClick={() =>
                                this.removeUserHandler(user.username)
                              }
                            >
                              {" "}
                              Remove{" "}
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div style={{ marginTop: "5%" }}>
              <input
                type="button"
                value="Delete Group"
                className="fnxRed"
                id="delete-group"
                disabled={this.state.usersInGroup.length > 1}
                onClick={() => this.setState({ showDeleteConfirm: true })}
              />

              {this.state.usersInGroup.length > 1 && (
                <span className="errorMsg mediumText">
                  {" "}
                  Remove users before deleting the group.
                </span>
              )}
            </div>
          </div>

          <div style={{ width: "40%" }}>
            <div className="divFlex">
              <input
                type="text"
                placeholder="Search by full email..."
                style={{ width: "270px" }}
                id="search-user-input"
                value={this.state.searchUsersEditText}
                onChange={(e) => {
                  this.setState({ searchUsersEditText: e.target.value });
                }}
              />
              <div className="ml-10">
                <button
                  id="search-user-input-btn"
                  className="fnxBlue"
                  onClick={() => this.searchUsers()}
                >
                  {" "}
                  Search
                </button>
              </div>
            </div>

            <div>
              <table className="generalTable">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>firstName</th>
                    <th>lastName</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.searchUserResult && (
                    <tr>
                      <td>
                        <input
                          type="button"
                          className="fnxGreen small"
                          value="+"
                          onClick={() =>
                            this.addUserToGroup(
                              this.state.searchUserResult.username,
                            )
                          }
                        />
                      </td>
                      <td>{this.state.searchUserResult.firstName ?? ""}</td>
                      <td>{this.state.searchUserResult.lastName ?? ""}</td>
                      <td>{this.state.searchUserResult.email}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(GroupCreatorComponent);

class GroupNameComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editGroupMode: false,
      editGroupNameText: "",
    };
    this._handleCancelGroupNameEdit =
      this._handleCancelGroupNameEdit.bind(this);
    this.updateGroupNameHandler = this.updateGroupNameHandler.bind(this);
  }

  _handleCancelGroupNameEdit(e) {
    const idAttr = e.target.getAttribute("id");
    if (
      this.state.editGroupMode &&
      idAttr !== "edit-group-name-span" &&
      idAttr !== "edit-group-name-input"
    ) {
      this.setState({ editGroupMode: false });
    }
  }

  componentDidMount() {
    document.addEventListener("click", this._handleCancelGroupNameEdit);
  }

  componentWillUnmount() {
    document.addEventListener("click", this._handleCancelGroupNameEdit);
  }

  async updateGroupNameHandler() {
    if (this.state.editGroupNameText.length) {
      try {
        await updateGroup(this.props.group.id, this.state.editGroupNameText);
      } catch (err) {
        console.error(err);
      }
    }
    this.setState({ editGroupMode: false });
    this.props.onComplete();
  }

  renderComponent() {
    if (this.state.editGroupMode) {
      return (
        <>
          <TextField
            error={
              this.state.editGroupNameText.trim().length >= 3 ? undefined : true
            }
            id="edit-group-name-input"
            label="Group Name"
            defaultValue={this.state.editGroupNameText}
            variant="standard"
            maxLength={30}
            onChange={(e) =>
              this.setState({ editGroupNameText: e.target.value })
            }
          />

          <a
            className="hyperlink-no-color successMsg"
            onClick={this.updateGroupNameHandler}
          >
            Save
          </a>
        </>
      );
    } else {
      return (
        <Tooltip title="Click to Edit">
          <span
            className="clickable"
            id="edit-group-name-span"
            onClick={() =>
              this.setState({
                editGroupMode: true,
                editGroupNameText: this.props.group.name,
              })
            }
          >
            {"Group: " + this.props.group?.name}
          </span>
        </Tooltip>
      );
    }
  }

  render() {
    return this.renderComponent();
  }
}

GroupNameComponent.propTypes = {
  group: PropTypes.any,
  onComplete: PropTypes.func.isRequired,
};
