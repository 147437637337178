import React from "react";
import { getIconForCategorySubcategory } from "../../../helpers/IconHelper";
import PropTypes from "prop-types";

export default class ExpenseAIMatchComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={"expense-ai-data-section"}>
        <div className="expense-ai-data-section-icon">
          <img
            src={getIconForCategorySubcategory(
              this.props.subcategory,
              this.props.category,
            )}
          />
        </div>

        <div className="expense-ai-data-section-details">
          <div className="expense-ai-data-section-tile">
            <div className="expense-ai-data-section-tile-header">
              <span>CATEGORY:</span>
            </div>
            <div className="expense-ai-data-section-tile-body">
              <input
                type="text"
                onChange={() => {}}
                value={
                  this.props.category && this.props.subcategory
                    ? this.props.category +
                      " - (" +
                      this.props.subcategory +
                      ")"
                    : ""
                }
              />
            </div>
            <div className="expense-ai-data-section-tile-img">
              <img
                title={
                  this.props.category && this.props.subcategory
                    ? this.props.subcategory.includes(",")
                      ? "Please select subcategory."
                      : ""
                    : "Please enter category/subcategory."
                }
                src={
                  this.props.category && this.props.subcategory
                    ? this.props.subcategory.includes(",")
                      ? "/assets/warning.png"
                      : "/assets/checked.png"
                    : "/assets/warning.png"
                }
              />
            </div>
          </div>

          <div className="expense-ai-data-section-tile">
            <div className="expense-ai-data-section-tile-header">
              <span>DATE:</span>
            </div>
            <div className="expense-ai-data-section-tile-body">
              <input
                type="text"
                onChange={() => {}}
                value={this.props.expenseDate}
              />
            </div>
          </div>

          <div className="expense-ai-data-section-tile">
            <div className="expense-ai-data-section-tile-header">
              <span>AMOUNT:</span>
            </div>
            <div className="expense-ai-data-section-tile-body">
              <input
                type="text"
                onChange={() => {}}
                value={this.props.amount}
              />
            </div>
            <div className="expense-ai-data-section-tile-img">
              <img
                title={this.props.amount > 0 ? "" : "Please enter amount."}
                src={
                  this.props.amount > 0
                    ? "/assets/checked.png"
                    : "/assets/warning.png"
                }
              />
            </div>
          </div>

          <div className="expense-ai-data-section-tile">
            <div className="expense-ai-data-section-tile-header">
              <span>COMMENTS:</span>
            </div>
            <div className="expense-ai-data-section-tile-body">
              <input
                type="text"
                onChange={() => {}}
                value={this.props.comments}
              />
            </div>
            <div className="expense-ai-data-section-tile-img">
              <img
                title={
                  this.props.jsonData.comments.length > 0
                    ? ""
                    : "Comments are optional."
                }
                src={
                  this.props.jsonData.comments.trim().length > 0
                    ? "/assets/checked.png"
                    : "/assets/warning.png"
                }
              />
            </div>
          </div>

          <div className="expense-ai-data-section-tile">
            <div className="expense-ai-data-section-tile-header">
              <span>ACCOUNT:</span>
            </div>
            <div className="expense-ai-data-section-tile-body">
              <input
                type="text"
                onChange={() => {}}
                value={this.props.card || ""}
              />
            </div>
            <div className="expense-ai-data-section-tile-img">
              <img
                title={
                  this.props.jsonData.cardId ? "" : "Account/Card missing."
                }
                src={
                  this.props.jsonData.cardId
                    ? "/assets/checked.png"
                    : "/assets/warning.png"
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ExpenseAIMatchComponent.propTypes = {
  jsonData: PropTypes.any,
  card: PropTypes.any,
  category: PropTypes.any,
  subcategory: PropTypes.any,
  expenseDate: PropTypes.any,
  amount: PropTypes.any,
  comments: PropTypes.any,
};
