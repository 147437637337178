import React from "react";
import PropTypes from "prop-types";

export default class OpSuccessComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {}

  render() {
    return (
      <div className="margin-center">
        <div className="title" style={{ color: "var(--fnx-total-green)" }}>
          Success!
        </div>

        <div className="subtitle">{this.props.renderSubtitle()}</div>
      </div>
    );
  }
}

OpSuccessComponent.propTypes = {
  renderSubtitle: PropTypes.func,
};
