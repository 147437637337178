import React from "react";
import { getDeleteIcon } from "../../helpers/IconHelper";
import PropTypes from "prop-types";

/**
 * Delete button component.
 *
 * id {String}
 *
 * onClick {Function}
 */
export class DeleteButtonComponent extends React.Component {
  super(props) {
    constructor(props);
  }

  render() {
    return (
      <button
        id={this.props.id}
        style={{
          background: "url(" + getDeleteIcon() + ")",
          backgroundSize: "cover",
          height: "30px",
          width: "30px",
          border: "0px",
          cursor: "pointer",
        }}
        className="fnxDelete"
        onClick={this.props.onClick}
      />
    );
  }
}

DeleteButtonComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string,
};

export function LoadingButton({ loading, value, id, onClick }) {
  return (
    <button class="bigButtonGreen buttonload" id={id} onClick={onClick}>
      <i class={`fa ${loading ? "fa-spinner fa-spin" : ""}`}></i> {value}
    </button>
  );
}
