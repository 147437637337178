import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getSignature } from "../helpers/AppConfigHelper";
import FooterComponent from "./FooterComponent";
import "./LeftPaneComponent.css";
import LogoComponent from "./LogoComponent";
export default function LeftPaneComponent({
  isMobile,
  authenticated,
  onClickLogout,
}) {
  const [showProductsMode, setShowProductsMode] = useState(false);

  return (
    <div className="left-pane-container">
      <LogoComponent authenticated={authenticated} />

      <div id="menu">
        {!authenticated && (
          <ul>
            {!showProductsMode && (
              <>
                <li>
                  {" "}
                  <a href="https://help.expensehut.com/about">About Us</a>
                </li>
                <li>
                  {" "}
                  <Link to="/register">Register</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/login">Login</Link>
                </li>
                <li>
                  {" "}
                  <a href="#" onClick={() => setShowProductsMode(true)}>
                    Products
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="https://help.expensehut.com/contact">Contact Us</a>
                </li>
              </>
            )}

            {showProductsMode && (
              <>
                <li>
                  <a href="#" onClick={() => setShowProductsMode(false)}>
                    <strong>&lt;</strong>
                    <span style={{ marginLeft: 5 }}>Back</span>
                  </a>
                </li>

                <li>
                  <div className="divCenterAlign">
                    <a
                      href="https://www.recruit.expensehut.com"
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginRight: 5 }}
                    >
                      Recruit
                    </a>
                    <OpenInNewIcon sx={{ fontSize: 18 }} />
                  </div>
                </li>
                <li>
                  <div className="divCenterAlign">
                    <a
                      href="https://www.blogs.expensehut.com"
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginRight: 5 }}
                    >
                      Blogs
                    </a>
                    <OpenInNewIcon sx={{ fontSize: 18 }} />
                  </div>
                </li>
                <li>
                  <div className="divCenterAlign">
                    <a
                      href="https://www.wallet.expensehut.com"
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginRight: 5 }}
                    >
                      Wallet
                    </a>
                    <OpenInNewIcon sx={{ fontSize: 18 }} />
                  </div>
                </li>
              </>
            )}
          </ul>
        )}
        {authenticated && (
          <ul>
            <li>
              {" "}
              <Link to="/home">Home</Link>
            </li>
            <li>
              {" "}
              <Link to="/addExpense">Add Expense</Link>
            </li>
            <li>
              {" "}
              <Link to="/savings">Savings</Link>
            </li>

            <li>
              {" "}
              <Link to="/analysis">Analysis</Link>
            </li>
            <li>
              {" "}
              <Link to="/settings">Settings</Link>
            </li>
            <li>
              {" "}
              <a
                href={`https://wallet.expensehut.com?exhut_token_ssid=${getSignature()}`}
                target="_blank"
                rel="noreferrer"
              >
                Wallet
              </a>
            </li>
            <li>
              {" "}
              <Link onClick={onClickLogout} to="/login?logout=1">
                Logout
              </Link>
            </li>
          </ul>
        )}
      </div>
      <FooterComponent isMobile={isMobile} />
    </div>
  );
}
