import React from "react";
import PropTypes from "prop-types";
import { Chart } from "react-google-charts";
import { connect } from "react-redux";
import { getExpenseCategoryForLineChart } from "../../../services/ExpenseService";
import store from "../../../store";
import SpinnerComponent from "../../shared/SpinnerComponent";

class HomePageLineChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expensesDataLine: [],
      isLoading: false,
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.selectedMonth !== prevProps.selectedMonth ||
      this.props.selectedYear !== prevProps.selectedYear ||
      this.props.groupid !== prevProps.groupid
    ) {
      await this.fetchData();
    }
  }

  async fetchData() {
    try {
      this.setState({ isLoading: true });
      const _selectedMonth = store.getState().app.selectedMonth;
      const _selectedYear = store.getState().app.selectedYear;
      const lineChartData = await getExpenseCategoryForLineChart(
        this.props.groupid,
        _selectedMonth,
        _selectedYear,
      );
      const expensesDataLine =
        this._populateDataArrayForLineChart(lineChartData);
      this.setState({ expensesDataLine });
    } catch (err) {
      console.error(err);
    }
    this.setState({ isLoading: false });
  }

  _populateDataArrayForLineChart(json) {
    const dataArray = [["Subcat Diff", json.thisMonthName, json.prevMonthName]];
    for (const d of json.data) {
      const tempArray = [];
      tempArray.push(`${d.category} - ${d.subcategory}`);
      tempArray.push(d.thisMonthTotal);
      tempArray.push(d.prevMonthTotal);
      dataArray.push(tempArray);
    }
    return dataArray;
  }

  renderChartComponent() {
    if (this.state.isLoading) {
      return (
        <div
          style={{ width: "100%", height: "100%" }}
          className="divCenterAlign"
        >
          <SpinnerComponent />
        </div>
      );
    }
    return (
      <Chart
        width={"500px"}
        height={"300px"}
        chartType="BarChart"
        loader={<div>Loading Chart</div>}
        data={this.state.expensesDataLine}
        options={{
          title: "Expense comparison to previous month",
          chartArea: { width: "50%" },
          hAxis: {
            title: "",
            minValue: 0,
          },
          vAxis: {
            title: "",
            textStyle: {
              fontSize: 8,
            },
          },
        }}
        rootProps={{ "data-testid": "2" }}
        chartEvents={[
          {
            eventName: "select",
            callback: ({ chartWrapper }) =>
              this.props.onSelect({ chartWrapper }),
          },
        ]}
      />
    );
  }

  render() {
    return (
      <div style={{ width: "50%" }} hidden={!this.props.show}>
        {this.renderChartComponent()}
      </div>
    );
  }
}

HomePageLineChartComponent.propTypes = {
  onSelect: PropTypes.func,
  user: PropTypes.object,
  selectedMonth: PropTypes.any,
  selectedYear: PropTypes.any,
  show: PropTypes.bool,
};

function mapStateToProps(state) {
  const { user, selectedMonth, selectedYear } = state.app;
  const { groupid } = state.group;
  return { user, selectedMonth, selectedYear, groupid };
}

export default connect(mapStateToProps)(HomePageLineChartComponent);
