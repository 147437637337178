import React from "react";
import CreateSavingsDialogComponent from "./CreateSavingsDialogComponent";
import EditSavingsDialogComponent from "./EditSavingsDialogComponent";
import {
  getSavings,
  deleteSavings,
  syncSavingsForPlaidAccounts,
} from "../../services/SavingsService";
import { getUsersInGroups2 } from "../../services/GroupService";
import * as DateHelper from "../../helpers/DateHelper";
import { formatToLocaleAmount } from "../../helpers/NumberHelper";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import ModalDialogComponent from "../shared/ModalDialogComponent";
import CurrencyRatesPanelComponent from "./CurrencyRatesPanelComponent";
import SavingsEstimatePanelComponent from "./SavingsEstimatePanelComponent";
import store from "../../store";
import { getGroupsIcon, getHelpIcon } from "../../helpers/IconHelper";
import { getSavingsTotal } from "../../actions/savingsAction";
import RadioButtonComponent from "../shared/RadioButtonComponent";
import { DeleteButtonComponent } from "../shared/ButtonComponent";
import SpinnerComponent from "../shared/SpinnerComponent";
import "./SavingsComponent.css";
import { updateSettings } from "../../actions/settingsAction";
import {
  getGroupIncomeTotal,
  getUserIncomeTotal,
} from "../../actions/incomeAction";
import { Link } from "react-router-dom";
import { getEstimatedSavings } from "../../helpers/SavingsHelper";
import StatusMessageComponent2 from "../shared/StatusMessageComponent2";
import FirstTimeLoginComponent from "../FirstTimeLoginComponent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCurrencySymbol } from "../../helpers/CurrencyHelper";
import { getGroupDetails } from "../../actions/groupsAction";
import ChooseGroupComponent from "../shared/ChooseGroupComponent";
import GroupDisplayComponent from "../shared/GroupDisplayComponent";

class SavingsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: undefined,
      savings: [],
      showRates: false,
      showEstimates: false,
      editSavingsId: undefined,
      deleteSavingsId: undefined,
      savingsTotal: 0,
      estimatedSavings: 0,
      totalIncome: 0,
      totalExpenses: 0,
      usersInGroup: [],
      statusMsg: undefined,
      groups: [],
      isSyncProgress: false,
    };
    this.onFailureHandler = this.onFailureHandler.bind(this);
    this.onSuccessHandler = this.onSuccessHandler.bind(this);
    this.fetchAllData = this.fetchAllData.bind(this);
    this.deleteSavingsHandler = this.deleteSavingsHandler.bind(this);
    this.toggleShowRates = this.toggleShowRates.bind(this);
    this.toggleShowEstimate = this.toggleShowEstimate.bind(this);
    this._handleEditSavings = this._handleEditSavings.bind(this);
    this.updateEstimatedSavings = this.updateEstimatedSavings.bind(this);
    this.syncSavingsHandler = this.syncSavingsHandler.bind(this);
  }

  async componentDidMount() {
    try {
      store.dispatch(getGroupDetails());
      await this.fetchAllData();
    } catch (err) {
      this.onFailureHandler(err);
    }
  }

  async onSuccessHandler(isUpdate) {
    try {
      this.setState({ mode: undefined });
      this.setState({
        statusMsg: {
          type: "success",
          text: isUpdate
            ? "Savings successfully updated."
            : "Savings successfully created.",
        },
      });
      await this.fetchAllData();
    } catch (err) {
      this.onFailureHandler(err);
    }
  }

  onFailureHandler(err) {
    if (err.status === 401) {
      this.props.doLogout();
    } else {
      console.error(err);
      this.setState({ mode: undefined, isSyncProgress: false });
      this.setState({
        statusMsg: {
          type: "error",
          text: "Whoops! Something went wrong.",
        },
      });
    }
  }

  async syncSavingsHandler() {
    try {
      this.setState({ isSyncProgress: true });
      await syncSavingsForPlaidAccounts(this.props.user.groupid);
      await this.fetchAllData();
      this.setState({
        statusMsg: {
          type: "success",
          text: "Savings sync complete!",
        },
        deleteSavingsId: undefined,
        isSyncProgress: false,
      });
    } catch (err) {
      this.onFailureHandler(err);
    }
  }

  async deleteSavingsHandler(id) {
    try {
      await deleteSavings(
        this.props.user.groupid,
        this.props.user.username,
        id,
      );
      this.setState({
        statusMsg: {
          type: "error",
          text: "Savings successfully deleted.",
        },
        deleteSavingsId: undefined,
      });
      await this.fetchAllData();
    } catch (err) {
      this.onFailureHandler(err);
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.groupid !== this.props.groupid) {
      this.fetchAllData();
    }
  }

  async fetchAllData() {
    if (!!this.props.groupid) {
      store.dispatch(getSavingsTotal());
      store.dispatch(getUserIncomeTotal());
      store.dispatch(getGroupIncomeTotal());

      const usersInGroup = await getUsersInGroups2(this.props.groupid, true);
      this.setState({ usersInGroup });

      const savings = await getSavings(this.props.groupid);
      this.setState({ savings });

      this.updateEstimatedSavings();
    }
  }

  updateEstimatedSavings(payload) {
    const { estSavings, totalIncome, totalExpenses } = getEstimatedSavings(
      payload,
      store,
    );
    this.setState({ estimatedSavings: estSavings, totalIncome, totalExpenses });
  }

  _updateSettings(payload) {
    store.dispatch(updateSettings(payload));
    this.updateEstimatedSavings(payload);
  }

  toggleShowRates() {
    this.setState({ showRates: !this.state.showRates, showEstimates: false });
  }

  toggleShowEstimate() {
    this.setState({
      showRates: false,
      showEstimates: !this.state.showEstimates,
    });
  }

  _handleEditSavings(id) {
    this.setState({ mode: "edit", editSavingsId: id });
  }

  renderEstimatedSavingsPanel() {
    if (this.state.totalIncome > 0) {
      return (
        <div className="estimate-savings-panel">
          <div className="estimate-savings-panel-section-left">
            <div className="divCenter">
              <img
                src={getHelpIcon()}
                title={
                  "Calculated based on total income of " +
                  this.state.totalIncome +
                  " minus total expenses this month of " +
                  this.state.totalExpenses
                }
                className="iconSize"
                style={{ marginRight: "10px" }}
              />

              <span>Estimated savings this month</span>
            </div>

            <div className="divCenter">
              <span
                id="savings-estimated-by-income"
                className="boldText largerText"
              >
                {formatToLocaleAmount(this.state.estimatedSavings)}
              </span>
            </div>
          </div>

          <div className="estimate-savings-panel-section-right">
            <div>
              <span className="mediumText"> Include Group Income</span>
              <RadioButtonComponent
                size="small"
                checked={this.props.include_group_income}
                onChange={(e) =>
                  this._updateSettings({
                    include_group_income: e.target.checked,
                  })
                }
              />
            </div>

            <div>
              <span className="mediumText"> Include Misc Expenses</span>
              <RadioButtonComponent
                size="small"
                checked={this.props.include_misc_expense_for_savings}
                onChange={(e) =>
                  this._updateSettings({
                    include_misc_expense_for_savings: e.target.checked,
                  })
                }
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="estimate-savings-pane-no-data blueMsg">
          Enter <Link to="/settings/income">income</Link> to see estimated
          savings.
        </div>
      );
    }
  }

  render() {
    if (!this.props.groupid && this.props.groups.length) {
      return <ChooseGroupComponent />;
    }

    if (!this.props.groupid && this.props.groups.length === 0) {
      return <FirstTimeLoginComponent />;
    }

    return (
      <div className="margin-center">
        <StatusMessageComponent2 message={this.state.statusMsg} />

        <GroupDisplayComponent />

        <div className="title-extra topMargin1P">Savings</div>

        {this.state.deleteSavingsId && (
          <ModalDialogComponent
            title="Delete Savings"
            message="Are you sure?"
            onSuccess={() =>
              this.deleteSavingsHandler(this.state.deleteSavingsId)
            }
            onCancel={() => this.setState({ deleteSavingsId: undefined })}
          />
        )}

        <div>
          {this.state.mode === "create" && (
            <CreateSavingsDialogComponent
              user={this.props.user}
              groupid={this.props.groupid}
              usersInGroup={this.state.usersInGroup}
              onSuccess={this.onSuccessHandler}
              onFailure={(err) => this.onFailureHandler(err)}
              onCancel={() => this.setState({ mode: undefined })}
            />
          )}
        </div>

        <div>
          {this.state.mode === "edit" && (
            <EditSavingsDialogComponent
              user={this.props.user}
              groupId={this.props.groupId}
              usersInGroup={this.state.usersInGroup}
              editSavingsId={this.state.editSavingsId}
              onSuccess={() => this.onSuccessHandler("update")}
              onFailure={(err) => this.onFailureHandler(err)}
              onCancel={() => this.setState({ mode: undefined })}
            />
          )}
        </div>

        {this.props.savingsTotal === 0 && (
          <div className="divCenterAlign" style={{ minHeight: "300px" }}>
            <input
              type="button"
              className="fnxGreen"
              value="Add Savings"
              onClick={() => this.setState({ mode: "create" })}
            />
          </div>
        )}

        {this.props.savingsTotal > 0 && (
          <div className="divFlex savingsBody">
            <div className="savings-pagel-left">
              <div>
                <div>
                  <div id="savingsTotal" className="total">
                    <span>{formatToLocaleAmount(this.props.savingsTotal)}</span>
                  </div>
                </div>

                {this.renderEstimatedSavingsPanel()}

                <div className="section">
                  <div id="showCurrencyConvPanel">
                    <div className="divFlex">
                      <div className="subsection">
                        <span className="mediumText">
                          {" "}
                          Show Live Currency Rates
                        </span>
                      </div>
                      <div className="divCenterAlign">
                        <RadioButtonComponent
                          size="small"
                          onChange={this.toggleShowRates}
                          id="showCurrencyConvButton"
                          checked={this.state.showRates}
                        />
                      </div>
                    </div>
                    <div className="divFlex">
                      <div className="subsection">
                        <span className="mediumText"> Show Estimate</span>
                      </div>
                      <div className="divCenterAlign">
                        <RadioButtonComponent
                          size="small"
                          onChange={this.toggleShowEstimate}
                          id="showEstimatedSavingsButton"
                          checked={this.state.showEstimates}
                        />
                      </div>
                    </div>
                  </div>

                  <div id="savings-data-panel" className="section">
                    {this.state.showRates && (
                      <CurrencyRatesPanelComponent
                        user={this.props.user}
                        doLogout={this.props.doLogout}
                      />
                    )}
                    {this.state.showEstimates && (
                      <SavingsEstimatePanelComponent
                        doLogout={this.props.doLogout}
                        user={this.props.user}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="savings-pagel-right">
              <div
                id="savingsTableHeader"
                className={
                  this.props.user.entitlement > 2 ? "divSpread" : "divRight"
                }
                style={{ backgroundColor: "white", padding: "10px 0px" }}
              >
                {this.props.user.entitlement > 2 && (
                  <input
                    type="button"
                    className="fnxGreen"
                    value="Sync"
                    disabled={this.state.isSyncProgress}
                    onClick={this.syncSavingsHandler}
                  />
                )}

                <a
                  className="hyperlink"
                  onClick={() => this.setState({ mode: "create" })}
                >
                  {" "}
                  Add Savings
                </a>
              </div>

              <div className="savings-row-container">
                <div>
                  {this.state.isSyncProgress && (
                    <SpinnerComponent size="medium" />
                  )}
                  {!this.state.isSyncProgress &&
                    this.state.savings.map((sav, i) => {
                      return (
                        <Accordion key={i}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography sx={{ width: "60%", flexShrink: 0 }}>
                              <strong>{sav.description}</strong>
                            </Typography>
                            <Typography sx={{ width: "30%", flexShrink: 0 }}>
                              <a
                                className={
                                  sav.username === this.props.user.username
                                    ? "hyperlink"
                                    : ""
                                }
                                onClick={() => {
                                  if (
                                    sav.username === this.props.user.username
                                  ) {
                                    this._handleEditSavings(sav.id);
                                  }
                                }}
                              >
                                {getCurrencySymbol(this.props.user) +
                                  " " +
                                  sav.amount}
                              </a>
                            </Typography>
                            <Typography sx={{ width: "10%", flexShrink: 0 }}>
                              {sav.users.length > 0 && (
                                <img
                                  src={getGroupsIcon()}
                                  className="iconSize"
                                />
                              )}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography>
                              {DateHelper.getLocalizedDate(
                                sav.savingsdate,
                                this.props.user,
                              )}
                            </Typography>

                            <Typography></Typography>

                            {sav.username === this.props.user.username && (
                              <DeleteButtonComponent
                                onClick={() =>
                                  this.setState({ deleteSavingsId: sav.id })
                                }
                              />
                            )}
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

SavingsComponent.propTypes = {
  user: PropTypes.any.isRequired,
  doLogout: PropTypes.func.isRequired,
  savingsTotal: PropTypes.number,
  include_group_income: PropTypes.bool,
  include_misc_expense_for_savings: PropTypes.bool,
  groupid: PropTypes.string,
  groups: PropTypes.array,
};

function mapStateToProps(state) {
  const { user, navigate } = state.app;
  const { total } = state.savings;
  const { groupid, groups } = state.group;
  /* eslint-disable camelcase */
  const { include_group_income, include_misc_expense_for_savings } =
    state.settings;
  return {
    user,
    navigate,
    savingsTotal: total,
    include_group_income,
    include_misc_expense_for_savings,
    groups,
    groupid,
  };
}

export default connect(mapStateToProps)(SavingsComponent);
