import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";

/**
 * Status component that uses react material ui.
 *
 * Properties
 * message - {type, text} Types are success | error. text is the message.
 *
 */
export default function StatusMessageComponent2({ message }) {
  const [statusMsg, setStatusMsg] = useState();

  useEffect(() => {
    setStatusMsg(message);
  }, [message]);

  return (
    <div style={{ minHeight: "50px" }}>
      {statusMsg && (
        <Alert
          severity={statusMsg.type}
          onClose={() => setStatusMsg(undefined)}
        >
          {statusMsg.component ? statusMsg.component() : statusMsg.text}
        </Alert>
      )}
    </div>
  );
}

StatusMessageComponent2.propTypes = {
  message: PropTypes.object,
};
