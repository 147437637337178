import React from "react";
import {
  inflateExpense,
  updateExpense,
  deleteExpense,
  createMiscExpense,
  clarifyExpense,
  moveExpenseToLoanPayment,
  moveExpenseToGroup,
  moveExpenseToTrip,
  moveExpenseToLedger,
} from "../../services/ExpenseService";
import { inflateGroup } from "../../services/GroupService";
import {
  getSubCategories,
  getCategoriesOnly,
} from "../../services/CategoryService";
import { getCreditCards } from "../../services/CreditCardService";
import * as DateHelper from "../../helpers/DateHelper";
import { getSuccessIcon } from "../../helpers/IconHelper";
import ModalDialogComponent from "../shared/ModalDialogComponent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import store from "../../store";
import {
  getExpensesTotal,
  setEditExpenseId,
} from "../../actions/expensesAction";
import { Link } from "react-router-dom";
import CalendarInputComponent from "../../components/shared/CalendarInputComponent";
import OpSuccessComponent from "../shared/OpSuccessComponent";
import SpinnerComponent from "../shared/SpinnerComponent";
import StatusMessageComponent2 from "../shared/StatusMessageComponent2";
import moment from "moment";
import { getFullName } from "../../helpers/UserHelper";

class EditExpenseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusMsg: undefined,
      categories: [],
      subcategories: [],
      accounts: [],
      selectedCategory: undefined,
      selectedSubcategory: undefined,
      selectedDate: undefined,
      selectedAmount: "",
      selectedComments: "",
      selectedAccount: undefined,
      selectedExpenseUsername: undefined,
      showDeleteConfirmation: false,
      showCreateAsMiscConfirmation: false,
      moveExpenseToTargetGroup: undefined,
      moveExpenseToLoan: undefined,
      moveExpenseToTrip: undefined,
      moveExpenseToLedger: undefined,
      isGroupAdmin: false,
      expenseUserFullname: undefined,
      setInterOpSuccess: false,
      isLoading: false,
      expense: undefined,
    };
    this.inflateExpense = this.inflateExpense.bind(this);
    this.inflateGroup = this.inflateGroup.bind(this);
    this.updateExpenseHandler = this.updateExpenseHandler.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.deleteExpenseHandler = this.deleteExpenseHandler.bind(this);
    this.getSubcategories = this.getSubcategories.bind(this);
    this.handleCreateAsMiscExpense = this.handleCreateAsMiscExpense.bind(this);
    this.moveExpenseHandler = this.moveExpenseHandler.bind(this);
    this.askExpenseClarificationHandler =
      this.askExpenseClarificationHandler.bind(this);
  }

  async componentDidMount() {
    await this.inflateExpense();
    await this.inflateGroup(); // to show Update/Delete actions buttons if user is group admin
  }

  errorHandler(err) {
    if (err.status === 401) {
      this.props.doLogout();
    } else if (err.status === 409) {
      this.setState({
        statusMsg: {
          text: "Possible duplicate found! Please double check and try again.",
          type: "error",
        },
      });
    } else {
      console.error(err);
      this.setState({
        statusMsg: {
          text: "Whoops! Something went wrong. Please try again or contact the admin",
          type: "error",
        },
      });
    }
  }

  async inflateGroup() {
    try {
      const group = await inflateGroup(this.props.groupid);
      this.setState({
        isGroupAdmin: group.created_by === this.props.user.username,
      });
    } catch (err) {
      this.errorHandler(err);
    }
  }

  async inflateExpense() {
    try {
      this.setState({ isLoading: true });
      const expense = await inflateExpense(
        this.props.groupid,
        this.props.expenseId,
      );

      const expenseCreatedBy = this.props.groupUsers.find(
        (u) => u.username === expense.username,
      );
      if (!!expenseCreatedBy) {
      }
      this.setState({
        selectedCategory: expense.category,
        expense,
        selectedSubcategory: expense.subcategory,
        selectedDate: expense.expensedate,
        selectedAmount: expense.amount,
        selectedComments: expense.comments,
        selectedAccount: expense.creditcard,
        selectedExpenseUsername: expense.username,
        selectedExpenseGroupid: expense.groupid,
        expenseUserFullname: getFullName(expenseCreatedBy),
      });
      const categories = await getCategoriesOnly();
      this.setState({ categories });
      const subcategories = await getSubCategories(expense.category);
      this.setState({ subcategories });
      const accounts = await getCreditCards(this.props.user.username);
      this.setState({ accounts });
    } catch (err) {
      this.errorHandler(err);
    }
    this.setState({ isLoading: false });
  }

  async updateExpenseHandler() {
    try {
      await updateExpense(
        this.props.expenseId,
        this.state.selectedCategory,
        this.state.selectedSubcategory,
        moment(this.state.selectedDate).format("YYYY-MM-DD"),
        this.state.selectedAmount,
        this.state.selectedComments,
        this.state.selectedAccount,
        this.props.groupid,
      );
      this.setState({
        statusMsg: {
          text: "Expense successfully updated.",
          type: "success",
        },
      });
      store.dispatch(getExpensesTotal());
    } catch (err) {
      this.errorHandler(err);
    }
  }

  async moveExpenseHandler() {
    try {
      this.setState({ moveExpenseToTargetGroup: undefined });
      await moveExpenseToGroup(
        this.props.expenseId,
        this.props.groupid,
        this.state.moveExpenseToTargetGroup.id,
      );
      this.setState({
        statusMsg: {
          text: "Expense successfully moved! You must switch to this group to view them.",
          type: "success",
        },
      });
    } catch (err) {
      this.errorHandler(err);
    }
  }

  async moveExpenseToLoanHandler() {
    try {
      await moveExpenseToLoanPayment(
        this.props.groupid,
        this.props.expenseId,
        this.state.moveExpenseToLoan,
      );

      this.setState({
        setInterOpSuccess: {
          message: (
            <span>
              This expense is moved to the loan as payment. Go to{" "}
              <Link to="loans" style={{ marginLeft: "5px" }}>
                Loans
              </Link>
              .
            </span>
          ),
        },
      });
    } catch (err) {
      this.errorHandler(err);
    }
    this.setState({ moveExpenseToLoan: undefined });
  }

  async moveExpenseToTripHandler() {
    try {
      const targetTrip = JSON.parse(this.state.moveExpenseToTrip);
      await moveExpenseToTrip(
        this.props.expenseId,
        this.props.groupid,
        targetTrip.id,
      );
      this.setState({
        setInterOpSuccess: {
          message: (
            <span>
              This expense is moved to the trips. Go to{" "}
              <Link to="trips" style={{ marginLeft: "5px" }}>
                Trips
              </Link>
              .
            </span>
          ),
        },
      });
    } catch (err) {
      this.errorHandler(err);
    }
    this.setState({ moveExpenseToTrip: undefined });
  }

  async moveExpenseToLEdgerHandler() {
    try {
      const ledger = JSON.parse(this.state.moveExpenseToLedger);
      await moveExpenseToLedger(
        this.props.expenseId,
        this.props.groupid,
        ledger.id,
      );
      this.setState({
        setInterOpSuccess: {
          message: (
            <span>
              This expense is moved to the ledgers. Go to{" "}
              <Link to="ledgers" style={{ marginLeft: "5px" }}>
                Ledgers
              </Link>
              .
            </span>
          ),
        },
      });
    } catch (err) {
      this.errorHandler(err);
    }
    this.setState({ moveExpenseToTrip: undefined });
  }

  async askExpenseClarificationHandler() {
    try {
      this.setState({ showAskClarificationConfirmation: false });
      await clarifyExpense(this.props.groupid, this.props.expenseId);
      this.setState({
        statusMsg: {
          text:
            "We have sent a SMS to " +
            this.state.expenseUserFullname +
            ". They will respond back to your phone.",
          type: "success",
        },
      });
    } catch (err) {
      this.errorHandler(err);
    }
  }

  async deleteExpenseHandler() {
    try {
      await deleteExpense(this.props.expenseId);
      store.dispatch(setEditExpenseId(undefined));
      store.dispatch(getExpensesTotal());
    } catch (err) {
      this.errorHandler(err);
    }
  }

  async getSubcategories() {
    try {
      const subcategories = await getSubCategories(this.state.selectedCategory);
      this.setState({ subcategories, selectedSubcategory: subcategories[0] });
    } catch (err) {
      console.error(err);
      this.setState({ subcategories: [] });
    }
  }

  async handleCreateAsMiscExpense() {
    try {
      this.setState({ isLoading: true });
      await createMiscExpense(
        this.state.selectedComments,
        this.state.selectedAmount,
        moment(this.state.selectedDate).format("YYYY-MM-DD"),
      );
      await deleteExpense(this.props.expenseId);
      this.setState({
        setInterOpSuccess: {
          message: (
            <span>
              A misc expense was successfully created. Go to{" "}
              <Link to="miscExpenses" style={{ marginLeft: "5px" }}>
                Misc Expenses
              </Link>
              .
            </span>
          ),
        },
      });
    } catch (err) {
      this.setState({
        statusMsg: {
          type: "error",
          text: "Whoops! Something unexpected happened. Please try again.",
        },
      });
    }
    this.setState({ isLoading: false });
  }

  render() {
    if (this.state.setInterOpSuccess) {
      return (
        <OpSuccessComponent
          renderSubtitle={() => (
            <div className="divCenterAlign">
              <img
                src={getSuccessIcon()}
                width="50px"
                style={{ marginRight: "20px" }}
              />
              {this.state.setInterOpSuccess.message}
            </div>
          )}
        />
      );
    }
    return (
      <div className="margin-center">
        <StatusMessageComponent2 message={this.state.statusMsg} />

        <div className="title topMargin0P">Edit Expense</div>

        <div className="divFlex">
          <div style={{ width: "100%" }}>
            {this.state.isLoading && (
              <div className="divCenter" style={{ marginTop: "40px" }}>
                <SpinnerComponent />
              </div>
            )}

            {!this.state.isLoading && (
              <div>
                <div className="divSpread" style={{ margin: "0px 40px" }}>
                  <Link to="/home">Back</Link>

                  {(this.state.selectedExpenseUsername ===
                    this.props.user.username ||
                    this.state.isGroupAdmin) && (
                    <>
                      <a
                        className="hyperlink"
                        onClick={() =>
                          this.setState({ showCreateAsMiscConfirmation: true })
                        }
                      >
                        Save As Misc Expense
                      </a>

                      {false &&
                        this.state.selectedExpenseUsername !==
                          this.props.user.username &&
                        this.props.user.entitlement > 2 && (
                          <a
                            className="hyperlink"
                            onClick={() =>
                              this.setState({
                                showAskClarificationConfirmation: true,
                              })
                            }
                          >
                            Ask Clarification
                          </a>
                        )}

                      {this.props.groups?.length > 1 && (
                        <select
                          id="move-group-select"
                          onChange={(e) => {
                            if (e.target.value !== "") {
                              const serializedGroup = JSON.parse(
                                e.target.value,
                              );
                              this.setState({
                                moveExpenseToTargetGroup: {
                                  id: serializedGroup.id,
                                  name: serializedGroup.name,
                                },
                              });
                            } else {
                              this.setState({
                                moveExpenseToTargetGroup: undefined,
                              });
                            }
                          }}
                        >
                          <option value="">-- Move to group -- </option>
                          {this.props.groups
                            .filter(
                              (group) =>
                                group.id !== this.state.selectedExpenseGroupid,
                            )
                            .map((group, i) => {
                              return (
                                <option key={i} value={JSON.stringify(group)}>
                                  {" "}
                                  {group.name}{" "}
                                </option>
                              );
                            })}
                        </select>
                      )}

                      {this.props.loans?.length > 0 && (
                        <select
                          id="move-loans-select"
                          onChange={(e) => {
                            if (e.target.value !== "") {
                              this.setState({
                                moveExpenseToLoan: e.target.value,
                              });
                            } else {
                              this.setState({ moveExpenseToLoan: undefined });
                            }
                          }}
                        >
                          <option value="">-- Make as loan payment -- </option>
                          {this.props.loans.map((loan, i) => {
                            return (
                              <option key={i} value={loan.id}>
                                {" "}
                                {loan.name}{" "}
                              </option>
                            );
                          })}
                        </select>
                      )}

                      {this.props.trips?.length > 0 && (
                        <select
                          id="trip-expenses-select"
                          onChange={(e) => {
                            if (e.target.value !== "") {
                              this.setState({
                                moveExpenseToTrip: e.target.value,
                              });
                            } else {
                              this.setState({ moveExpenseToTrip: undefined });
                            }
                          }}
                        >
                          <option value="">-- Make as trip expense -- </option>
                          {this.props.trips.map((trip, i) => {
                            return (
                              <option key={i} value={JSON.stringify(trip)}>
                                {" "}
                                {trip.name}{" "}
                              </option>
                            );
                          })}
                        </select>
                      )}

                      {this.props.ledgers?.length > 0 && (
                        <select
                          id="ledgers-expenses-select"
                          onChange={(e) => {
                            if (e.target.value !== "") {
                              this.setState({
                                moveExpenseToLedger: e.target.value,
                              });
                            } else {
                              this.setState({ moveExpenseToLedger: undefined });
                            }
                          }}
                        >
                          <option value="">
                            -- Make as ledger transaction --{" "}
                          </option>
                          {this.props.ledgers.map((ledger, i) => {
                            return (
                              <option key={i} value={JSON.stringify(ledger)}>
                                {" "}
                                {ledger.name}{" "}
                              </option>
                            );
                          })}
                        </select>
                      )}

                      <input
                        type="button"
                        value="Delete"
                        className="fnxRed"
                        onClick={() =>
                          this.setState({ showDeleteConfirmation: true })
                        }
                      />
                    </>
                  )}
                </div>

                <div>
                  {this.state.showDeleteConfirmation && (
                    <ModalDialogComponent
                      title={"Delete Expense"}
                      message={"Are you sure you want to proceed?"}
                      onSuccessTitle={"Yes"}
                      onCancelTitle={"No"}
                      onSuccess={() => this.deleteExpenseHandler()}
                      onCancel={() =>
                        this.setState({ showDeleteConfirmation: false })
                      }
                    />
                  )}
                </div>

                <div>
                  {this.state.showCreateAsMiscConfirmation && (
                    <ModalDialogComponent
                      title={"Save as Misc Expense?"}
                      message={"Are you sure?"}
                      onSuccessTitle={"Yes"}
                      onCancelTitle={"No"}
                      onSuccess={() => this.handleCreateAsMiscExpense()}
                      onCancel={() =>
                        this.setState({ showCreateAsMiscConfirmation: false })
                      }
                    />
                  )}

                  {this.state.showAskClarificationConfirmation && (
                    <ModalDialogComponent
                      title={"Ask Clarification?"}
                      message={`This will send text sms to ${this.state.expenseUserFullname}.
                           Would you like to proceed?`}
                      onSuccessTitle={"Yes"}
                      onCancelTitle={"No"}
                      onSuccess={() => this.askExpenseClarificationHandler()}
                      onCancel={() =>
                        this.setState({
                          showAskClarificationConfirmation: false,
                        })
                      }
                    />
                  )}

                  {this.state.moveExpenseToTargetGroup && (
                    <ModalDialogComponent
                      title={`Move expense?`}
                      message={`Are you sure you want move this expense to the group 
                          "${this.state.moveExpenseToTargetGroup.name}" ?`}
                      onSuccessTitle={"Yes"}
                      onCancelTitle={"No"}
                      onSuccess={() => this.moveExpenseHandler()}
                      onCancel={() =>
                        this.setState({ moveExpenseToTargetGroup: undefined })
                      }
                    />
                  )}

                  {this.state.moveExpenseToLoan && (
                    <ModalDialogComponent
                      title={`Make as loan payment ?`}
                      message={`Are you sure you want make this as payment to loan?`}
                      onSuccessTitle={"Yes"}
                      onCancelTitle={"No"}
                      onSuccess={() => this.moveExpenseToLoanHandler()}
                      onCancel={() =>
                        this.setState({ moveExpenseToLoan: undefined })
                      }
                    />
                  )}

                  {this.state.moveExpenseToTrip && (
                    <ModalDialogComponent
                      title={`Move expense to Trip?`}
                      message={`Are you sure you want move this expense to the trip 
                          "${JSON.parse(this.state.moveExpenseToTrip)?.name}" ?`}
                      onSuccessTitle={"Yes"}
                      onCancelTitle={"No"}
                      onSuccess={() => this.moveExpenseToTripHandler()}
                      onCancel={() =>
                        this.setState({ moveExpenseToTrip: undefined })
                      }
                    />
                  )}

                  {this.state.moveExpenseToLedger && (
                    <ModalDialogComponent
                      title={`Move expense to Ledger?`}
                      message={`Are you sure you want move this expense to the ledger 
                          "${JSON.parse(this.state.moveExpenseToLedger)?.name}" ?`}
                      onSuccessTitle={"Yes"}
                      onCancelTitle={"No"}
                      onSuccess={() => this.moveExpenseToLEdgerHandler()}
                      onCancel={() =>
                        this.setState({ moveExpenseToLedger: undefined })
                      }
                    />
                  )}
                </div>

                <div
                  style={{
                    border: "1px solid black",
                    margin: "40px",
                    padding: "20px 40px",
                    backgroundColor: "var(--fnx-light-grey5)",
                  }}
                >
                  <div className="divRight">
                    {this.state.expense?.created_at && (
                      <>
                        <strong>Created At: </strong>
                        <span
                          style={{
                            color: "var(--fnx-dark-grey)",
                            margin: "0px 10px",
                          }}
                        >
                          {new Date(
                            this.state.expense?.created_at,
                          ).toLocaleString()}
                        </span>
                      </>
                    )}
                  </div>

                  <table className="generalTable" id="savingsTable">
                    <tbody>
                      <tr>
                        <td>Created By</td>
                        <td>
                          <span className="boldText">
                            {this.state.expenseUserFullname}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Category</td>

                        <td>
                          <select
                            onChange={(e) =>
                              this.setState(
                                { selectedCategory: e.target.value },
                                this.getSubcategories,
                              )
                            }
                            id={"category"}
                            style={{ width: "250px" }}
                            value={this.state.selectedCategory}
                          >
                            {this.state.categories.map((cat, i) => {
                              return (
                                <option key={i} value={cat}>
                                  {cat}
                                </option>
                              );
                            })}
                          </select>
                        </td>
                      </tr>

                      <tr>
                        <td>Sub Category</td>

                        <td>
                          <select
                            onChange={(e) =>
                              this.setState({
                                selectedSubcategory: e.target.value,
                              })
                            }
                            id={"subcategory"}
                            style={{ width: "250px" }}
                            value={this.state.selectedSubcategory}
                          >
                            {this.state.subcategories.map((subcat, i) => {
                              return (
                                <option key={i} value={subcat}>
                                  {subcat}
                                </option>
                              );
                            })}
                          </select>
                        </td>
                      </tr>

                      <tr>
                        <td>Date</td>
                        <td>
                          <CalendarInputComponent
                            id="expensedate"
                            value={DateHelper.sqlToLocalDate(
                              this.state.selectedDate,
                              this.props.user,
                            )}
                            onChange={(value, dateText) => {
                              this.setState({
                                selectedDate:
                                  moment(dateText).format("YYYY-MM-DD"),
                              });
                            }}
                            textAlign={"flex-start"}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>Amount</td>
                        <td>
                          <input
                            value={this.state.selectedAmount}
                            type="text"
                            style={{ width: "235px" }}
                            id="amount"
                            onChange={(e) =>
                              this.setState({ selectedAmount: e.target.value })
                            }
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>Comments</td>

                        <td>
                          <input
                            value={this.state.selectedComments}
                            id="comments"
                            type="text"
                            style={{ width: "235px" }}
                            onChange={(e) =>
                              this.setState({
                                selectedComments: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>Account</td>

                        <td>
                          <select
                            style={{ width: "250px" }}
                            id="creditcard"
                            onChange={(e) =>
                              this.setState({ selectedAccount: e.target.value })
                            }
                            value={this.state.selectedAccount}
                          >
                            {this.state.accounts.map((acc, i) => {
                              return (
                                <option key={i} value={acc.id}>
                                  {acc.name}
                                </option>
                              );
                            })}
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {(this.state.selectedExpenseUsername ===
                    this.props.user.username ||
                    this.state.isGroupAdmin) && (
                    <div style={{ margin: "30px 0px" }}>
                      <div>
                        {this.state.selectedExpenseUsername !==
                          this.props.user.username && (
                          <span className="infoMsg">
                            {" "}
                            Note: Group admins can edit or update expenses.
                          </span>
                        )}
                      </div>
                      <div className="divSpread" style={{ marginLeft: "30%" }}>
                        <input
                          type="button"
                          value="Update"
                          className="fnxGreen"
                          onClick={this.updateExpenseHandler}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

EditExpenseComponent.propTypes = {
  expenseId: PropTypes.string,
  user: PropTypes.any.isRequired,
  doLogout: PropTypes.func.isRequired,
  groups: PropTypes.array,
  loans: PropTypes.array,
  trips: PropTypes.array,
};

function mapStateToProps(state) {
  const { user, navigate, expense } = state.app;
  const { groups, groupid, users } = state.group;
  const { loans } = state.loan;
  const { trips } = state.trip || {};
  const { ledgers } = state.ledger || {};
  return {
    user,
    navigate,
    groupUsers: users,
    expenseId: expense.editExpenseId,
    groups,
    loans,
    trips,
    ledgers,
    groupid,
  };
}

export default connect(mapStateToProps)(EditExpenseComponent);
