import React from "react";
import PropTypes from "prop-types";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import store from "../../store";

import {
  getLocalizedDateFromObject,
  localDateToSQL,
} from "../../helpers/DateHelper";
/**
 * CalendarInputComponent with text field and calendar component.
 *
 * id : {String} id of the text field.
 *
 * value: {String} the default value [optional].
 *
 * width: {Number} [optional]. default is 200
 *
 * fixedPostion: {String}. "fixed" or "relative" or "absolute". Default is fixed. Used is cases when calendar is
 * unreachable during scroll that it should have position set to relative instead of fixed.
 *
 * onChange: {Function} (dateText) => this.setState({selectedDate: dateText}) Callback after date is set.
 *
 * textAlign: 'center', 'flex-start'
 *
 * showError: Will show red background if it is invalid date.
 *
 */
export default class CalendarInputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDate: false,
      selectedDate: "",
      user: store.getState().app.user,
    };
    this.textInput = React.createRef();
    this._formatDateHandler = this._formatDateHandler.bind(this);
    this._hideCalendarListener = this._hideCalendarListener.bind(this);
  }

  componentDidUpdate() {
    // The following code will adjust the css when the calendar appears.
    if (this.state.showDate) {
      const calendarComponent = document.querySelector(".react-calendar");
      if (calendarComponent) {
        calendarComponent.style.width = "250px";
        calendarComponent.style.position = this.props.position || "fixed";
        calendarComponent.style.flex = "1";
      }
      const calendarNavigationComponent = document.querySelector(
        ".react-calendar__navigation",
      );
      if (calendarNavigationComponent) {
        calendarNavigationComponent.style["margin-bottom"] = "0px";
        calendarNavigationComponent.style.height = "30px";
      }
    }
  }

  _hideCalendarListener(e) {
    if (e.key === "Escape") {
      this.setState({ showDate: false });
    }
    // if calendar nav bar var is clicked, then simply return
    if (e.target.classList.contains("react-calendar__navigation__arrow")) {
      return;
    }
    // This block is necessary to hide the calendar widget if clicked anywhere outside the date text field.
    if (
      !(
        e.target.getAttribute("id") !== null &&
        e.target.getAttribute("id").includes(this.props.id)
      )
    ) {
      this.setState({ showDate: false });
    }
  }

  componentDidMount() {
    document.addEventListener("click", this._hideCalendarListener);
    document.addEventListener("keydown", this._hideCalendarListener);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this._hideCalendarListener);
    document.removeEventListener("keydown", this._hideCalendarListener);
  }

  _formatDateHandler(dateText) {
    if (this.props.showError) {
      if (isNaN(new Date(dateText).getTime())) {
        this.textInput.current.classList.add("error-background");
      } else {
        this.textInput.current.classList.remove("error-background");
      }
    }

    const d = new Date(dateText);
    const _localizedDate = getLocalizedDateFromObject(d, this.state.user);
    this.setState({ selectedDate: _localizedDate, showDate: false });
    this.props.onChange(_localizedDate, dateText);
  }

  render() {
    return (
      <div>
        <input
          type="text"
          ref={this.textInput}
          className={this.props.showError ? "error-background" : undefined}
          style={{
            width: this.props.width || 200,
            minWidth: this.props.width || 200,
            textAlign: this.props.textAlign || "center",
          }}
          id={this.props.id}
          value={
            this.state.selectedDate !== ""
              ? this.state.selectedDate
              : this.props.value ?? ""
          }
          onClick={() => this.setState({ showDate: !this.state.showDate })}
          onChange={(e) => {
            this.setState(
              { selectedDate: e.target.value, showDate: false },
              () =>
                this.props.onChange(
                  this.state.selectedDate,
                  localDateToSQL(e.target.value, this.state.user),
                ),
            );
            if (this.props.showError) {
              if (isNaN(new Date(e.target.value).getTime())) {
                this.textInput.current.classList.add("error-background");
              } else {
                this.textInput.current.classList.remove("error-background");
              }
            }
          }}
          autoComplete={"off"}
          maxLength={this.props.maxLength ?? 200}
        />
        {this.state.showDate && (
          <Calendar
            onClickDay={(dateText) => this._formatDateHandler(dateText)}
          />
        )}
      </div>
    );
  }
}

CalendarInputComponent.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number,
  position: PropTypes.string,
  textAlign: PropTypes.string,
  maxLength: PropTypes.number,
  showError: PropTypes.bool,
};
