import { executeService } from "./FetchService";
import FNXConnection from "./FNXConnection";

export function addUserToGroup(groupid, username) {
  const url = new FNXConnection().getURL() + "/groups/" + groupid + "/addUser";
  return executeService(url, "POST", { username }, 200, "json");
}

export function removeUserFromGroup(groupid, username) {
  const url = new FNXConnection().getURL() + "/groups/" + groupid + "/rmUser";
  return executeService(url, "POST", { username }, 200, "json");
}

export function leaveGroup(groupid) {
  const url = new FNXConnection().getURL() + "/groups/" + groupid + "/leave";
  return executeService(url, "POST", undefined, 200, "json");
}
