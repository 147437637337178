import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { formatToLocaleAmount } from "../../../helpers/NumberHelper";
import SpinnerComponent, {
  SpinnerComponentColor,
} from "../../shared/SpinnerComponent";
import { getMiscExpensesTotal } from "../../../actions/expensesAction";
import store from "../../../store";

class HomePageMiscExpensesWidgetComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  async componentDidMount() {
    this.mounted = true;
    this.setState({ isLoading: true });
    setTimeout(() => this.mounted && this.setState({ isLoading: false }), 1000);
    store.dispatch(getMiscExpensesTotal());
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedMonth !== prevProps.selectedMonth ||
      this.props.selectedYear !== prevProps.selectedYear
    ) {
      this.setState({ isLoading: true });
      store.dispatch(getMiscExpensesTotal());
      setTimeout(
        () => this.mounted && this.setState({ isLoading: false }),
        1000,
      );
    }
  }

  render() {
    return (
      <div className="analysis-tile tile-small">
        <div className="analysis-tile-heading">Misc Expenses</div>
        <div className="analysis-tile-subheading">
          {this.state.isLoading && (
            <SpinnerComponent
              size="medium"
              color={SpinnerComponentColor.blue}
            />
          )}
          {!this.state.isLoading && (
            <span className="total" id="misc-total">
              {formatToLocaleAmount(this.props.miscTotal)}
            </span>
          )}
        </div>
        <div className="tile-body">
          <Link className="emerExpense hyperlink" to="/miscExpenses">
            View
          </Link>
        </div>
      </div>
    );
  }
}

HomePageMiscExpensesWidgetComponent.propTypes = {
  miscTotal: PropTypes.number,
  selectedMonth: PropTypes.any,
  selectedYear: PropTypes.any,
};

function mapStateToProps(state) {
  const { selectedMonth, selectedYear } = state.app;
  const { misc } = state.expense;
  return { miscTotal: misc?.total, selectedMonth, selectedYear };
}

export default connect(mapStateToProps)(HomePageMiscExpensesWidgetComponent);
