import React from "react";
import { getTrips, createTrip } from "../../services/TripService";
import PropTypes from "prop-types";
import "./TripsComponent.css";
import CalendarInputComponent from "../shared/CalendarInputComponent";
import { getDateExcludingTime } from "../../helpers/DateHelper";
import store from "../../store";
import { setEditTripId } from "../../actions/tripAction";
import { connect } from "react-redux";
import TripsFullViewComponent from "./TripsFullViewComponent";
import { getCurrencySymbol } from "../../helpers/CurrencyHelper";
import moment from "moment";

class TripsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trips: [],
      statusMsg: undefined,
      tripNameEditText: "",
      tripDestinationEditText: "",
      tripFromDateEditText: "",
      tripFromDateSQL: undefined,
      tripToDateEditText: "",
      tripToDateSQL: undefined,
      isInvalidInput: false,
    };
    this.createTripHandler = this.createTripHandler.bind(this);
    this.fetchAllTrips = this.fetchAllTrips.bind(this);
    this.validateInputHandler = this.validateInputHandler.bind(this);
  }

  async componentDidMount() {
    await this.fetchAllTrips();
  }

  async fetchAllTrips() {
    try {
      const trips = await getTrips(this.props.groupid);
      this.setState({ trips });
      // store.dispatch(setAccounts(accounts));
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        console.error("Error rendering trips component", err);
      }
    }
  }

  async createTripHandler() {
    try {
      await createTrip(
        this.state.tripNameEditText,
        moment(this.state.tripFromDateSQL).format("YYYY-MM-DD"),
        moment(this.state.tripToDateSQL).format("YYYY-MM-DD"),
        this.state.tripDestinationEditText,
      );
      await this.fetchAllTrips();
      this.setState({
        statusMsg: {
          text: "Trip successfully created.",
          type: "success",
        },
        tripNameEditText: "",
        tripFromDateEditText: "",
        tripToDateEditText: "",
        tripDestinationEditText: "",
      });
    } catch (err) {
      console.error(err);
      if (err.status === 401) {
        this.props.doLogout();
      } else if (err.status === 400) {
        this.setState({
          statusMsg: {
            text: err.message,
            type: "error",
          },
        });
      } else {
        this.setState({
          statusMsg: {
            text: "Whoops! Perhaps you have entered a duplicate name. Please try again.",
            type: "error",
          },
        });
      }
    }
  }

  validateInputHandler() {}

  render() {
    if (this.props.editTripId) {
      return (
        <TripsFullViewComponent
          doLogout={this.props.doLogout}
          handleBack={() => {
            store.dispatch(setEditTripId(undefined));
            this.setState({ statusMsg: undefined });
            this.fetchAllTrips();
          }}
        />
      );
    }
    return (
      <div className="margin-center">
        <div className="title">Trips</div>

        <div style={{ height: "2em", textAlign: "center", margin: "2% 0%" }}>
          {this.state.statusMsg && (
            <span
              className={
                this.state.statusMsg.type === "success"
                  ? "successMsg"
                  : "errorMsg"
              }
            >
              {this.state.statusMsg.text}
            </span>
          )}
        </div>

        <div className="trip-page">
          <div className="trip-page-left-panel">
            <ol className="noDiscList">
              {this.state.trips.map((trip, i) => {
                return (
                  <li
                    className="tabbed-list-li trip-list-li"
                    key={i}
                    onClick={() => store.dispatch(setEditTripId(trip.id))}
                  >
                    <div className="divSpread">
                      <div className="divCenterAlign">
                        <img
                          src={"/assets/icons/travelling.svg"}
                          width="30px"
                        />
                      </div>

                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <h3 className="trip-name">{trip.name}</h3>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <span>{getDateExcludingTime(trip.fromdate)}</span>
                          <span className="infoMsg"> to </span>
                          <span>{getDateExcludingTime(trip.todate)}</span>
                        </div>
                      </div>

                      <div>
                        <h3>
                          {getCurrencySymbol(this.props.user)}
                          {trip.total || 0}
                        </h3>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ol>
          </div>

          <div className="add-trip-panel">
            <div className="subtitle">Add Trip</div>

            <div className="add-trip-panel-body">
              <div className="divCenter">
                <span className="infoMsg">
                  {" "}
                  Note: You can add expenses to a trip once you create it.
                </span>
              </div>
              <div className="trips-row">
                <span>
                  {" "}
                  Trip name <sup> * </sup>{" "}
                </span>
                <input
                  type="text"
                  maxLength={40}
                  id="tripname"
                  value={this.state.tripNameEditText}
                  onChange={(e) =>
                    this.setState(
                      { tripNameEditText: e.target.value },
                      this.validateInputHandler,
                    )
                  }
                />
              </div>

              <div className="trips-row">
                <span> Destination </span>

                <input
                  type="text"
                  maxLength={40}
                  id="tripdest"
                  value={this.state.tripDestinationEditText}
                  onChange={(e) =>
                    this.setState({ tripDestinationEditText: e.target.value })
                  }
                />
              </div>

              <div className="trips-row">
                <span>
                  {" "}
                  From Date <sup> * </sup>
                </span>

                <CalendarInputComponent
                  id="tripFrom"
                  value={this.state.tripFromDateEditText}
                  onChange={(_localizedDate, dateText) =>
                    this.setState(
                      {
                        tripFromDateEditText: _localizedDate,
                        tripFromDateSQL: dateText,
                      },
                      this.validateInputHandler,
                    )
                  }
                />
              </div>

              <div className="trips-row">
                <span>
                  {" "}
                  To Date <sup> * </sup>{" "}
                </span>
                <CalendarInputComponent
                  id="tripTo"
                  value={this.state.tripToDateEditText}
                  onChange={(_localizedDate, dateText) =>
                    this.setState(
                      {
                        tripToDateEditText: _localizedDate,
                        tripToDateSQL: dateText,
                      },
                      this.validateInputHandler,
                    )
                  }
                />
              </div>
            </div>

            <div className="trips-footer">
              <input
                type="button"
                className="fnxGreen"
                value="Add"
                onClick={this.createTripHandler}
                disabled={this.state.isInvalidInput}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TripsComponent.propTypes = {
  user: PropTypes.any.isRequired,
  doLogout: PropTypes.func.isRequired,
  editTripId: PropTypes.string,
};

function mapStateToProps(state) {
  const { editTripId } = state.trip;
  const { groupid } = state.group;
  return { editTripId, groupid };
}

export default connect(mapStateToProps)(TripsComponent);
