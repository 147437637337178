import { put, takeLatest, all } from "redux-saga/effects";
import * as Api from "../services/UserService";
import {
  ACTION_DO_REFRESH_USER,
  refreshUser,
  ACTION_ON_LOGIN_SUCCESS,
} from "../actions/userAction";
import store from "../store";
import { getSettings } from "../actions/settingsAction";
import { getAccounts } from "../actions/accountsAction";

function* inflateUser() {
  try {
    const username = yield store.getState().app?.user?.username;
    if (username) {
      const refreshedUser = yield Api.inflateUser(username);
      yield put(refreshUser(refreshedUser));
    } else {
      console.debug("Inflate user saga did not run! username is undefined.");
    }
  } catch (e) {
    console.error(e);
  }
}

function* onLoginSuccess() {
  try {
    const username = yield store.getState().app?.user?.username;
    yield all([put(getSettings(username)), put(getAccounts())]);
  } catch (e) {
    console.error(e);
  }
}

export default function* userSaga() {
  yield takeLatest(ACTION_DO_REFRESH_USER, inflateUser);
  yield takeLatest(ACTION_ON_LOGIN_SUCCESS, onLoginSuccess);
}
