import React from "react";
import PropTypes from "prop-types";
import { getCurrencySymbol } from "../../../helpers/CurrencyHelper";
import { roundTo2Decimals } from "../../../helpers/NumberHelper";
import { getTillDateExpenses } from "../../../services/ExpenseAnalyticsService";
import { connect } from "react-redux";
import { formatToLocaleAmount } from "../../../helpers/NumberHelper";
import SpinnerComponent, {
  SpinnerComponentColor,
} from "../../shared/SpinnerComponent";

const styles = {
  diffIconImg: {
    width: "12px",
    height: "12px",
    alignSelf: "center",
    marginRight: "3px",
  },
};

class HomePageTotalWidgetComponent extends React.Component {
  constructor(props) {
    super(props);
    this.total = 0;
    this.state = {
      tillDateExpenses: [],
    };
  }

  async componentDidMount() {
    this.mounted = true;
    await this.fetchData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async fetchData() {
    try {
      this.setState({ isLoading: true });
      const tillDateExpenses = await getTillDateExpenses(this.props.groupid);
      this.setState({ tillDateExpenses });
      setTimeout(
        () => this.mounted && this.setState({ isLoading: false }),
        1000,
      );
    } catch (err) {
      console.error(err);
      this.setState({ isLoading: false });
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.user.groupid !== this.props.user.groupid) {
      await this.fetchData();
    }
  }

  _renderDiffTotal() {
    if (
      this.state.tillDateExpenses.length > 0 &&
      this.props.selectedMonth === new Date().getMonth() + 1 &&
      this.props.selectedYear === new Date().getFullYear()
    ) {
      const isCurrentMonthTotalGreaterThanPrevious =
        this.state.tillDateExpenses[0].total >
        this.state.tillDateExpenses[1].total;
      const icon = isCurrentMonthTotalGreaterThanPrevious
        ? "uparrow"
        : "downarrow";
      const altTitle = isCurrentMonthTotalGreaterThanPrevious
        ? "Expenses this month increased compared to last month."
        : "Expenses this month decreased compared to last month.";
      let amountDiff = isCurrentMonthTotalGreaterThanPrevious
        ? this.state.tillDateExpenses[0].total -
          this.state.tillDateExpenses[1].total
        : this.state.tillDateExpenses[1].total -
          this.state.tillDateExpenses[0].total;

      amountDiff = roundTo2Decimals(amountDiff);
      return (
        <div>
          <img
            src={`./assets/${icon}.png`}
            style={styles.diffIconImg}
            title={altTitle}
          />
          <span className="mediumText"> {amountDiff} </span>
        </div>
      );
    } else {
      return null;
    }
  }

  _renderPastMonthTotal() {
    if (
      this.state.tillDateExpenses.length > 0 &&
      this.props.selectedMonth === new Date().getMonth() + 1 &&
      this.props.selectedYear === new Date().getFullYear()
    ) {
      const pastMonthTotal = roundTo2Decimals(
        this.state.tillDateExpenses[1].total,
      );
      const pastMonthTotalText =
        this.state.tillDateExpenses[1].monthName?.substring(0, 3) +
        " " +
        getCurrencySymbol(this.props.user) +
        pastMonthTotal;
      return (
        <div style={{ marginRight: "10%" }}>
          <span className="mediumText">{pastMonthTotalText}</span>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    this.total =
      this.props.includeMiscExpensesInTotal === true
        ? this.props.monthlyTotal + this.props.miscTotal
        : this.props.monthlyTotal;
    return (
      <div className="analysis-tile tile-small">
        <div className="analysis-tile-heading">Monthly Total</div>
        <div className="analysis-tile-subheading">
          {this.state.isLoading && (
            <SpinnerComponent
              size="medium"
              color={SpinnerComponentColor.orange}
            />
          )}
          {!this.state.isLoading && (
            <span
              className="total"
              id="expense-total"
              style={{ margin: "0px" }}
            >
              {formatToLocaleAmount(this.total)}
            </span>
          )}
        </div>
        <div className="tile-body-spread">
          {this._renderPastMonthTotal()}
          {this._renderDiffTotal()}
        </div>
      </div>
    );
  }
}

HomePageTotalWidgetComponent.propTypes = {
  selectedMonth: PropTypes.number,
  selectedYear: PropTypes.number,
  user: PropTypes.any.isRequired,
  includeMiscExpensesInTotal: PropTypes.bool,
  monthlyTotal: PropTypes.number,
  miscTotal: PropTypes.number,
};

function mapStateToProps(state) {
  const { user, selectedMonth, selectedYear } = state.app;
  const { monthlyTotal, misc } = state.expense;
  const { groupid } = state.group;
  /* eslint-disable camelcase */
  const { include_misc_expense_in_total } = state.settings;
  return {
    user,
    selectedMonth,
    selectedYear,
    includeMiscExpensesInTotal: include_misc_expense_in_total,
    monthlyTotal,
    miscTotal: misc?.total,
    groupid,
  };
}

export default connect(mapStateToProps)(HomePageTotalWidgetComponent);
