import React from "react";
import { getExpensesForMonthYear } from "../../services/ExpenseService";
import * as DateHelper from "../../helpers/DateHelper";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import store from "../../store";

class AllExpensesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expenses: [],
    };
    this.fetchData = this.fetchData.bind(this);
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData(sortBy) {
    try {
      const expenses = await getExpensesForMonthYear(
        store.getState().app.user.groupid,
        this.props.selectedMonth,
        this.props.selectedYear,
        sortBy,
      );
      let filteredExpenses = [];
      if (this.props.selectedCategory && !this.props.selectedSubCategory) {
        filteredExpenses = expenses.filter(
          (exp) => exp.category === this.props.selectedCategory,
        );
      } else if (
        this.props.selectedCategory &&
        this.props.selectedSubCategory
      ) {
        filteredExpenses = expenses.filter(
          (exp) =>
            exp.category === this.props.selectedCategory &&
            exp.subcategory === this.props.selectedSubCategory,
        );
      }
      this.setState({ expenses: filteredExpenses });
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        this.setState({
          statusMsg: {
            type: "error",
            text: err.message,
          },
        });
      }
    }
  }

  async sortExpenses(value) {
    if (value === "amount") {
      await this.fetchData("amount");
    } else {
      await this.fetchData(/* undefined will be date */);
    }
  }

  getMonthFromIndex(index) {
    if (index < 0 || index > 11) {
      return "";
    }
    const months = [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[index - 1];
  }

  renderSubtitle() {
    let text =
      this.getMonthFromIndex(this.props.selectedMonth) +
      " " +
      this.props.selectedYear +
      " ";
    if (this.props.selectedCategory) {
      let categorySubCategoryText = "";
      categorySubCategoryText = this.props.selectedCategory;
      if (this.props.selectedSubCategory) {
        categorySubCategoryText =
          categorySubCategoryText + " - " + this.props.selectedSubCategory;
      }
      categorySubCategoryText = `(${categorySubCategoryText})`;
      text = text + categorySubCategoryText;
    }
    return <span style={{ fontWeight: "bold" }}>{text}</span>;
  }

  render() {
    return (
      <div className={"margin-center"}>
        <div className="title">Expenses</div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <a className="hyperlink" onClick={this.props.onBack}>
              Back{" "}
            </a>
          </div>
          <div>{this.renderSubtitle()}</div>
          <div>
            <select onChange={(e) => this.sortExpenses(e.target.value)}>
              <option>Sort By</option>
              <option value="date">Date</option>
              <option value="amount">Amount (High to Low)</option>
            </select>
          </div>
        </div>

        <div style={{ marginTop: "2%" }}>
          <table className="exhutTable">
            <thead>
              <tr>
                <th></th>
                <th>Category</th>
                <th>SubCategory</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              {this.state.expenses.map((expense, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{expense.category}</td>
                    <td>{expense.subcategory}</td>
                    <td>
                      {DateHelper.getDateExcludingTime(expense.expensedate)}
                    </td>
                    <td>{expense.amount}</td>
                    <td>{expense.comments}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

AllExpensesComponent.propTypes = {
  selectedMonth: PropTypes.number,
  selectedYear: PropTypes.number,
  selectedCategory: PropTypes.string,
  selectedSubCategory: PropTypes.string,
  doLogout: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { selectedYear } = state.app;
  return { selectedYear };
}

export default connect(mapStateToProps)(AllExpensesComponent);
