import React from "react";
import { createLoanPayment } from "../../../services/LoanPaymentService";
import PropTypes from "prop-types";
import TextInputComponent from "../../shared/TextInputComponent";
import CalendarInputComponent from "../../shared/CalendarInputComponent";

export default class CreateLoanPaymentDialogComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentsEditText: "",
      amountEditText: "",
      dateEditText: "",
    };
    this.shareableUsers = [];
    this.createLoanPaymentHandler = this.createLoanPaymentHandler.bind(this);
  }

  async createLoanPaymentHandler() {
    try {
      const dateComponents = this.state.dateEditText.split("/");
      let date;
      if (this.props.user.country_code === "USA") {
        date = new Date(
          dateComponents[2],
          parseInt(dateComponents[0], 10) - 1,
          dateComponents[1],
        );
      } else {
        date = new Date(
          dateComponents[2],
          parseInt(dateComponents[1], 10) - 1,
          dateComponents[0],
        );
      }
      await createLoanPayment(
        this.props.user.groupid,
        this.props.loanId,
        this.state.amountEditText,
        date.toISOString(),
        this.state.commentsEditText,
      );
      this.props.onSuccess();
    } catch (err) {
      console.error(err);
      this.props.onFailure(err);
    }
  }

  render() {
    return (
      <div className="modal" id="create-loan-payment-dialog">
        <div className="modal-content loans-dialog-content">
          <span className="close" onClick={this.props.onCancel}>
            &times;
          </span>
          <div style={{ textAlign: "center" }}>
            <h3> Add Payment</h3>
          </div>
          <div>
            <table
              className="formTable"
              style={{ width: "100%", borderSpacing: "20px" }}
            >
              <tbody>
                <tr>
                  <td>
                    {" "}
                    Amount <sup>*</sup>
                  </td>
                  <td>
                    <TextInputComponent
                      type="text"
                      id="loan-payment-amount"
                      onChange={(e) =>
                        this.setState(
                          { amountEditText: e.target.value },
                          this.inputValidator,
                        )
                      }
                      value={this.state.amountEditText}
                      error={
                        isNaN(this.state.amountEditText)
                          ? "Please enter valid amount."
                          : undefined
                      }
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    {" "}
                    Date <sup>*</sup>
                  </td>
                  <td>
                    <CalendarInputComponent
                      id="loan-payment-date"
                      onChange={(dateText) =>
                        this.setState({ dateEditText: dateText })
                      }
                      maxLength={10}
                    />
                  </td>
                </tr>

                <tr>
                  <td> Comments </td>
                  <td style={{ display: "flex" }}>
                    <input
                      type="text"
                      id="loan-payment-comments"
                      value={this.state.commentsEditText}
                      onChange={(e) =>
                        this.setState(
                          { commentsEditText: e.target.value },
                          this.inputValidator,
                        )
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: "1em", textAlign: "center" }}>
            <input
              id="cancel"
              type="button"
              value="Cancel"
              className="fnxGrey"
              onClick={this.props.onCancel}
            />

            <input
              id="createSaving"
              type="button"
              value="Create"
              className="fnxGreen"
              onClick={this.createLoanPaymentHandler}
            />
          </div>
        </div>
      </div>
    );
  }
}

CreateLoanPaymentDialogComponent.propTypes = {
  user: PropTypes.any.isRequired,
  loanId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
