import { ACTION_SET_LOANS } from "../actions/loansAction";

const initState = {
  loans: [],
};
export default function loans(state = initState, action) {
  switch (action.type) {
    case ACTION_SET_LOANS:
      return {
        ...state,
        loans: action.payload,
      };
    default:
      return state;
  }
}
