import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as Api from "../services/ExpenseService";
import {
  ACTION_GET_EXPENSES_TOTAL,
  setExpensesTotal,
  setMiscExpensesTotal,
  ACTION_GET_MISC_EXPENSES_TOTAL,
} from "../actions/expensesAction";
import store from "../store";

function* fetchMiscExpensesTotal() {
  try {
    const month = yield store.getState().app.selectedMonth;
    const year = yield store.getState().app.selectedYear;
    const groupid = yield store.getState().group?.groupid;
    if (month && year && groupid) {
      const result = yield call(
        Api.getExpenseCategoryTotal,
        groupid,
        month,
        year,
        true,
      );
      yield put(setMiscExpensesTotal(result.total));
    }
  } catch (e) {
    console.error(e);
    yield put(setMiscExpensesTotal(0));
  }
}

function* fetchExpensesTotal() {
  try {
    const month = yield store.getState().app.selectedMonth;
    const year = yield store.getState().app.selectedYear;
    const groupid = yield store.getState().group?.groupid;
    if (month && year && groupid) {
      const result = yield call(
        Api.getExpenseCategoryTotal,
        groupid,
        month,
        year,
      );
      yield put(setExpensesTotal(result.total));
    }
  } catch (e) {
    console.error(e);
    yield put(setExpensesTotal(0));
  }
}

export default function* expensesSaga() {
  yield takeLatest(ACTION_GET_MISC_EXPENSES_TOTAL, fetchMiscExpensesTotal);
  yield takeEvery(ACTION_GET_EXPENSES_TOTAL, fetchExpensesTotal);
}
