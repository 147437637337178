import React from "react";
import { getCurrencySymbolPerCountry } from "../../helpers/CurrencyHelper";
import PropTypes from "prop-types";
import { getCurrencyRates } from "../../services/SavingsService";

export default class CurrencyRatesPanelComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currencyRates: [],
    };
    this.fetchCurrencyRates = this.fetchCurrencyRates.bind(this);
  }

  async fetchCurrencyRates() {
    try {
      const currencyRates = await getCurrencyRates(this.props.user.username);
      // this is a json, so convert to array.
      const ratesArray = [];
      const keys = Object.keys(currencyRates);
      for (const k of keys) {
        const localeAmount =
          k === "INR"
            ? currencyRates[k].toLocaleString("en-IN")
            : currencyRates[k].toLocaleString("en-US");
        ratesArray.push({ country: k, rate: localeAmount });
      }
      this.setState({ currencyRates: ratesArray });
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        console.error(err);
      }
    }
  }

  async componentDidMount() {
    await this.fetchCurrencyRates();
  }

  render() {
    return (
      <div id="currencyConvDataPanel" className="savings-data-panel">
        <div style={{ marginBottom: "20px", marginTop: "0px" }}>
          <span className={"subtitle"}> Live rates </span>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <span className={"infoMsg"}>
            {" "}
            Note: Currency exchange rates are refreshed nightly.
          </span>
        </div>
        <div>
          <table className="exhutTable" id="currencyTable">
            <thead>
              <tr>
                <th> </th>
                <th> Currency </th>
                <th> Total Value </th>
              </tr>
            </thead>
            <tbody>
              {this.state.currencyRates.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item.country}</td>
                    <td>
                      {getCurrencySymbolPerCountry(item.country) +
                        " " +
                        item.rate}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

CurrencyRatesPanelComponent.propTypes = {
  user: PropTypes.object.isRequired,
  doLogout: PropTypes.func.isRequired,
};
