import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { formatToLocaleAmount } from "../../helpers/NumberHelper";
import { getLoanById, getLoans } from "../../services/LoanService";
import FirstTimeLoginComponent from "../FirstTimeLoginComponent";
import SpinnerComponent from "../shared/SpinnerComponent";
import StatusMessageComponent2 from "../shared/StatusMessageComponent2";
import CreateLoanDialogComponent from "./CreateLoanDialogComponent";
import "./LoansComponent.css";
import LoansQuickViewComponent from "./LoansQuickViewComponent";

class LoansComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: undefined,
      loans: [],
      isLoading: true,
      usersInGroup: [],
      statusMsg: undefined,
      loanQuickView: undefined,
    };
    this.onFailureHandler = this.onFailureHandler.bind(this);
    this.onSuccessHandler = this.onSuccessHandler.bind(this);
    this.fetchAllData = this.fetchAllData.bind(this);
    this.loanQuickViewHandler = this.loanQuickViewHandler.bind(this);
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      await this.fetchAllData();
    } catch (err) {
      this.onFailureHandler(err);
    }
    this.setState({ isLoading: false });
  }

  async onSuccessHandler() {
    try {
      this.setState({ mode: undefined });
      this.setState({
        statusMsg: {
          type: "success",
          text: "Loan successfully created.",
        },
      });
      await this.fetchAllData();
    } catch (err) {
      this.onFailureHandler(err);
    }
  }

  onFailureHandler(err) {
    if (err.status === 401) {
      this.props.doLogout();
    } else if (err.status === 400) {
      this.setState({
        mode: undefined,
        statusMsg: {
          type: "error",
          text: err.message,
        },
      });
    } else {
      console.error(err);
      this.setState({ mode: undefined });
      this.setState({
        statusMsg: {
          type: "error",
          text: "Whoops! Something went wrong.",
        },
      });
    }
  }

  async loanQuickViewHandler(id) {
    try {
      const loan = await getLoanById(this.props.user.groupid, id);
      this.setState({ loanQuickView: loan });
    } catch (err) {
      this.onFailureHandler(err);
    }
  }

  async fetchAllData() {
    const loans = await getLoans(this.props.user.groupid);
    this.setState({ loans });
  }

  render() {
    if (!this.props.user.groupid) {
      return <FirstTimeLoginComponent />;
    }

    if (this.state.isLoading) {
      return (
        <div
          style={{
            height: "100%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SpinnerComponent />
        </div>
      );
    }
    return (
      <div className="margin-center">
        <StatusMessageComponent2 message={this.state.statusMsg} />

        <div className="title-extra topMargin1P">Loans</div>

        <div>
          {this.state.mode === "create" && (
            <CreateLoanDialogComponent
              user={this.props.user}
              onSuccess={this.onSuccessHandler}
              onFailure={(err) => this.onFailureHandler(err)}
              onCancel={() => this.setState({ mode: undefined })}
            />
          )}
        </div>

        {this.state.loans.length === 0 && (
          <div className="divCenterAlign" style={{ minHeight: "300px" }}>
            <input
              type="button"
              className="fnxGreen"
              value="Add Loans"
              onClick={() => this.setState({ mode: "create" })}
            />
          </div>
        )}

        {this.state.loans.length > 0 && (
          <div className="divFlex">
            <div className="loans-panel-left-resize">
              <div className="loans-div-header">
                <a
                  className="hyperlink"
                  onClick={() => this.setState({ mode: "create" })}
                >
                  {" "}
                  Add Loan
                </a>
              </div>

              <div>
                <ol style={{ listStyle: "none" }}>
                  {this.state.loans.map((loan, i) => {
                    return (
                      <li
                        className={
                          loan.id === this.state.loanQuickView?.id
                            ? "tabbed-list-li notself-primary"
                            : "tabbed-list-li"
                        }
                        key={i}
                        onClick={() => this.loanQuickViewHandler(loan.id)}
                      >
                        <div className="divFlex">
                          <div style={{ flex: 1 }}>
                            <span>{i + 1}</span>
                          </div>
                          <div style={{ flex: 2 }}>
                            <strong>{loan.name}</strong>
                          </div>
                          <div style={{ flex: 1 }}>
                            <strong> Amount: </strong>
                            <span>
                              {formatToLocaleAmount(loan.pendingBalance)}{" "}
                            </span>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ol>
              </div>
            </div>

            {this.state.loanQuickView && (
              <div className="loans-panel-right">
                <LoansQuickViewComponent
                  user={this.props.user}
                  loan={this.state.loanQuickView}
                  onClose={() => this.setState({ loanQuickView: undefined })}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

LoansComponent.propTypes = {
  user: PropTypes.any.isRequired,
  doLogout: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { user } = state.app;
  return { user };
}

export default connect(mapStateToProps)(LoansComponent);
