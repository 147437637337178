import { usePlaidLink } from "react-plaid-link";
import React, { useEffect, useState } from "react";
import {
  connectPlaid,
  exchangePlaidtoken,
} from "../../../services/PlaidService";
import usePlaid from "./usePlaid";

export default function CreatePlaidComponent({
  user,
  onCreateSuccess,
  onCreateError,
  disabled,
}) {
  const [linkToken, setLinkToken] = useState();
  const [initiate, setInitiate] = useState();
  const { successData } = usePlaid({
    init: initiate,
    linkToken,
    onClose: () => setInitiate(false),
  });

  useEffect(() => {
    if (successData) {
      exchangePlaidtoken({
        bankName: successData.metadata.institution.name,
        bankId: successData.metadata.institution.institution_id,
        token: successData.public_token,
      })
        .then(onCreateSuccess)
        .catch(onCreateError);
    }
  }, [successData]);

  const initiateHandler = async () => {
    const result = await connectPlaid(user?.username);
    setLinkToken(result.link_token);
    setInitiate(true);
  };

  return (
    <div className="divSpread">
      <input
        type="button"
        className="bigButtonBlue"
        disabled={disabled}
        onClick={initiateHandler}
        value="Connect"
      />
    </div>
  );
}
