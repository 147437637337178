import React, { useEffect, useState } from "react";
import { addIncome } from "../../../services/IncomeService";
import PropTypes from "prop-types";
import TextInputComponent from "../../shared/TextInputComponent";
import store from "../../../store";
import {
  getGroupIncomeTotal,
  getUserIncomeTotal,
} from "../../../actions/incomeAction";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function AddIncomeComponent({ onBackHandler }) {
  const [statusMsg, setStatusMsg] = useState();

  const formik = useFormik({
    initialValues: {
      "income-source": "",
      "income-amount": "",
    },
    validationSchema: Yup.object({
      "income-source": Yup.string().required("Please enter source of income."),
      "income-amount": Yup.number()
        .typeError("Amount must be a number")
        .positive("Amount must be greater than zero")
        .required("Please enter amount."),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        await addIncome(values["income-source"], values["income-amount"]);
        store.dispatch(getUserIncomeTotal());
        store.dispatch(getGroupIncomeTotal());

        onBackHandler();
      } catch (err) {
        setStatusMsg({
          type: "error",
          text: "Whoops! Something went wrong. Please try again.",
        });
      } finally {
        resetForm();
      }
    },
  });

  return (
    <div className="margin-center">
      <div style={{ display: "flex" }}>
        <div className="subtitle" style={{ width: "80%" }}>
          Add Monthly Income
        </div>
        <div style={{ alignItems: "center", display: "flex" }}>
          <a onClick={onBackHandler} className="hyperlink">
            {" "}
            Back{" "}
          </a>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div style={{ minHeight: "1em" }} className="divCenter my-20">
          {statusMsg && (
            <span
              className={
                statusMsg?.type === "success" ? "successMsg" : "errorMsg"
              }
            >
              {statusMsg?.text}
            </span>
          )}
        </div>

        <div className="div-column-2">
          <div className="divFlex mb-20">
            <div style={{ flex: 1 }}>Income Source</div>
            <div style={{ flex: 2 }}>
              <TextInputComponent
                type="text"
                id="income-source"
                error={formik.errors["income-source"]}
                {...formik.getFieldProps("income-source")}
              />
            </div>
          </div>

          <div className="divFlex mb-20">
            <div style={{ flex: 1 }}>Amount</div>
            <div style={{ flex: 2 }}>
              <TextInputComponent
                type="text"
                id="income-amount"
                error={formik.errors["income-amount"]}
                {...formik.getFieldProps("income-amount")}
              />
            </div>
          </div>
        </div>

        <div className="divCenter">
          <input
            type="submit"
            className="fnxGreen"
            value="Add"
            id="addIncomeBtn"
            disabled={Object.keys(formik.errors).length > 0}
          />
        </div>
      </form>
    </div>
  );
}
