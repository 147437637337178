import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import LogoComponent from "./LogoComponent";

export default function MobileHeaderComponent({ onToggleOptions }) {
  return (
    <div
      style={{
        backgroundColor: "var(--primary)",
        display: "flex",
        width: "100%",
      }}
    >
      <div
        style={{ position: "absolute", left: 20, top: 80 }}
        className="divCenter"
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onToggleOptions}
          edge="start"
        >
          <MenuIcon sx={{ color: "white" }} />
        </IconButton>
      </div>

      <div className="divCenterAlign w-100">
        <LogoComponent />
      </div>
    </div>
  );
}
