import React from "react";
import PropTypes from "prop-types";
import "./AnalysisComponent.css";
import "./AnalysisComponentAnime.css";
import {
  getAnalyticsByCategory,
  getAnalyticsBySubCategory,
  getAnalyticsByAccounts,
  getAnalyticsByUsers,
} from "../../services/ExpenseAnalyticsService";
import { connect } from "react-redux";
import AnalysisCategoryComponent from "./AnalysisCategoryComponent";
import AnalysisSubCategoryComponent from "./AnalysisSubCategoryComponent";
import AnalysisUsersComponent from "./AnalysisUsersComponent";
import AnalysisAccountsComponent from "./AnalysisAccountsComponent";
import { Link } from "react-router-dom";
import GroupDisplayComponent from "../shared/GroupDisplayComponent";

class AnalysisComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      categoryData: [],
      subcategoryData: [],
      accountsData: [],
      usersData: [],
    };
    this.renderAnalyticsComponent = this.renderAnalyticsComponent.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  async componentDidMount() {
    await this.fetchData();
    this.toggleTabs(0);
  }

  async fetchData() {
    try {
      const categoryData = await getAnalyticsByCategory(this.props.groupid);
      this.setState({ categoryData });
      const subcategoryData = await getAnalyticsBySubCategory(
        this.props.groupid,
      );
      this.setState({ subcategoryData });
      const accountsData = await getAnalyticsByAccounts(this.props.groupid);
      this.setState({ accountsData });
      const usersData = await getAnalyticsByUsers(this.props.groupid);
      this.setState({ usersData });
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        // figure out a proper logger.
      }
    }
  }

  toggleTabs(index) {
    this.setState({ tabIndex: index });
    const tabElement = document.querySelector(".active");
    if (tabElement) {
      tabElement.setAttribute("class", `active moveTab${index}`);
    }
  }

  renderAnalyticsComponent(value) {
    switch (value) {
      case 1:
        return (
          <AnalysisSubCategoryComponent
            data={this.state.subcategoryData}
            user={this.props.user}
          />
        );
      case 2:
        return (
          <AnalysisUsersComponent
            data={this.state.usersData}
            user={this.props.user}
            groupUsers={this.props.groupUsers}
          />
        );
      case 3:
        return (
          <AnalysisAccountsComponent
            data={this.state.accountsData}
            user={this.props.user}
            groupUsers={this.props.groupUsers}
          />
        );
      default:
        return (
          <AnalysisCategoryComponent
            data={this.state.categoryData}
            user={this.props.user}
          />
        );
    }
  }

  render() {
    return (
      <div id="analysis-page">
        <div className="analysis-title">
          <div className="search">
            <Link to="/search">Search All</Link>
          </div>

          <div className="title">Analysis</div>

          <GroupDisplayComponent />
        </div>

        <div style={{ margin: "50px 20px" }}>
          <div
            id="analysis-menu-bar"
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <div
              className="analysis-tab-menu-item"
              onClick={() => this.toggleTabs(0)}
            >
              <span>Category</span>
            </div>
            <div
              className="analysis-tab-menu-item"
              onClick={() => this.toggleTabs(1)}
            >
              <span>SubCategory</span>
            </div>
            <div
              className="analysis-tab-menu-item"
              onClick={() => this.toggleTabs(2)}
            >
              <span>Users</span>
            </div>
            <div
              className="analysis-tab-menu-item"
              onClick={() => this.toggleTabs(3)}
            >
              <span>Accounts</span>
            </div>
          </div>
          <div className="active" />
          <div className="static" />
          <div>{this.renderAnalyticsComponent(this.state.tabIndex)}</div>
        </div>
      </div>
    );
  }
}

AnalysisComponent.propTypes = {
  user: PropTypes.any.isRequired,
  accounts: PropTypes.array,
  doLogout: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { user, navigate } = state.app;
  const { groupid, users } = state.group;
  return { user, navigate, groupid, groupUsers: users };
}

export default connect(mapStateToProps)(AnalysisComponent);
