import AuthConnection from "./AuthConnection";
import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export function inflateUser() {
  const url = new AuthConnection().getURL() + "/users/profile";
  return executeService(url);
}

export function postSession(bearerTokenOverride) {
  const url = new AuthConnection().getURL() + "/session";
  return executeService(
    url,
    "GET",
    undefined,
    200,
    "json",
    true,
    bearerTokenOverride,
  );
}

export function getUserByEmail(email) {
  const url = new FNXConnection().getURL() + "/user?email=" + email;
  return executeService(url, "GET", undefined, 200, "text");
}

export function getNoGroupUsers2(email) {
  const url = new AuthConnection().getURL() + "/users/search?email=" + email;
  return executeService(url);
}

export function updateUser(firstName, lastName, countryCode, username, phone) {
  const url = new AuthConnection().getURL() + "/users/profile";
  return executeService(
    url,
    "PUT",
    { firstName, lastName, countryCode, username, phone },
    200,
    "restrict",
  );
}

export function updateMyDefaultGroup(groupid) {
  const url = new FNXConnection().getURL() + "/groups/" + groupid + "/default";
  return executeService(url, "POST", undefined, 200, "restrict");
}

export function changePassword(password, newPassword) {
  const url = new AuthConnection().getURL() + "/users/settings/passwords";
  return executeService(url, "PUT", { password, newPassword }, 200, "restrict");
}
