export const ACTION_SET_GROUP_INCOME_TOTAL = "ACTION_SET_GROUP_INCOME_TOTAL";
export const ACTION_GET_GROUP_INCOME_TOTAL = "ACTION_GET_GROUP_INCOME_TOTAL";

export const ACTION_SET_USER_INCOME_TOTAL = "ACTION_SET_USER_INCOME_TOTAL";
export const ACTION_GET_USER_INCOME_TOTAL = "ACTION_GET_USER_INCOME_TOTAL";

export function setGroupIncomeTotal(payload) {
  return {
    type: ACTION_SET_GROUP_INCOME_TOTAL,
    payload,
  };
}

export function setUserIncomeTotal(payload) {
  return {
    type: ACTION_SET_USER_INCOME_TOTAL,
    payload,
  };
}

export function getGroupIncomeTotal() {
  return {
    type: ACTION_GET_GROUP_INCOME_TOTAL,
  };
}

export function getUserIncomeTotal() {
  return {
    type: ACTION_GET_USER_INCOME_TOTAL,
  };
}
