import React from "react";
import * as DateHelper from "../../helpers/DateHelper";
import { getIconForCategorySubcategory } from "../../helpers/IconHelper";
import { getCurrencySymbol } from "../../helpers/CurrencyHelper";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getExpensesForMonthYear } from "../../services/ExpenseService";
import { setEditExpenseId } from "../../actions/expensesAction";
import store from "../../store";
import { Link } from "react-router-dom";

class RecentExpenseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recentExpenses: [],
    };
  }

  async componentDidMount() {
    try {
      let recentExpenses = await getExpensesForMonthYear(
        this.props.user.groupid,
        this.props.selectedMonth,
        this.props.selectedYear,
      );
      recentExpenses = recentExpenses.filter(
        (exp) => exp.username === this.props.user?.username,
      );
      this.setState({ recentExpenses: recentExpenses.slice(0, 5) });
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    return (
      <div style={{ maxWidth: "70%", margin: "2% 0% 2% 2%" }}>
        <div>
          <h3>Last Added:</h3>
        </div>
        <ol className="noDiscList">
          {this.state.recentExpenses.map((expense, i) => {
            return (
              <li
                className={
                  expense.username === this.props.user.username
                    ? "tabbed-list-li"
                    : "tabbed-list-li notself"
                }
                key={i}
                style={{ padding: "2px 30px", cursor: "pointer" }}
              >
                <Link
                  to={`/editExpense`}
                  onClick={() => store.dispatch(setEditExpenseId(expense.id))}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div className="tabbed-list-li-div">
                    <div>
                      <img
                        src={getIconForCategorySubcategory(
                          expense.subcategory,
                          expense.category,
                        )}
                        width="30px"
                      />
                    </div>
                    <div className="tabbed-list-li-cell">
                      {DateHelper.getDateExcludingTime(expense.expensedate)}
                    </div>
                    <div className="tabbed-list-li-cell overflow-protect">
                      <span>{expense.comments}</span>
                    </div>
                    <div className="tabbed-list-li-cell bold-text">
                      {getCurrencySymbol(this.props.user) +
                        " " +
                        expense.amount}
                    </div>
                  </div>
                </Link>
              </li>
            );
          })}
        </ol>
      </div>
    );
  }
}

RecentExpenseComponent.propTypes = {
  user: PropTypes.any,
  selectedMonth: PropTypes.any,
  selectedYear: PropTypes.any,
};

function mapStateToProps(state) {
  const { user, selectedMonth, selectedYear } = state.app;
  return { user, selectedMonth, selectedYear };
}

export default connect(mapStateToProps)(RecentExpenseComponent);
