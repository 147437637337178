export const ACTION_SET_SETTINGS = "ACTION_SET_SETTINGS";
export const ACTION_UPDATE_SETTINGS = "ACTION_UPDATE_SETTINGS";
export const ACTION_GET_SETTINGS = "ACTION_GET_SETTINGS";
export const ACTION_SET_SETTINGS_ERROR = "ACTION_SET_SETTINGS_ERROR";

export function updateSettings(payload) {
  return {
    type: ACTION_UPDATE_SETTINGS,
    payload,
  };
}

export function setSettings(payload) {
  return {
    type: ACTION_SET_SETTINGS,
    payload,
  };
}

/**
 * Get settings action.
 * @returns
 */
export function getSettings() {
  return {
    type: ACTION_GET_SETTINGS,
  };
}

export function setSettingsError(payload) {
  return {
    type: ACTION_SET_SETTINGS_ERROR,
    payload,
  };
}
