export const ACTION_SET_SAVINGS_TOTAL = "ACTION_SET_SAVINGS_TOTAL";
export const ACTION_GET_SAVINGS_TOTAL = "ACTION_GET_SAVINGS_TOTAL";

export function setSavingsTotal(payload) {
  return {
    type: ACTION_SET_SAVINGS_TOTAL,
    payload,
  };
}

export function getSavingsTotal() {
  return {
    type: ACTION_GET_SAVINGS_TOTAL,
  };
}
