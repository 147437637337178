import React from "react";
import PropTypes from "prop-types";
import ChangePasswordComponent from "./changepassword/ChangePasswordComponent";
import OtherSecurityComponent from "./OtherSecurityComponent";
import { connect } from "react-redux";

class SecuritySettingsComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  renderLeftComponent() {
    return (
      <ChangePasswordComponent
        user={this.props.user}
        doLogout={this.props.doLogout}
      />
    );
  }

  render() {
    return (
      <div>
        <div className="title">Security Settings</div>

        <div style={styles.container}>
          <div style={styles.containerLeftPanel}>
            {this.renderLeftComponent()}
          </div>

          <div style={styles.containerRightPanel}>
            <OtherSecurityComponent doLogout={this.props.doLogout} />
          </div>
        </div>
      </div>
    );
  }
}

SecuritySettingsComponent.propTypes = {
  user: PropTypes.any,
  doLogout: PropTypes.func.isRequired,
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
  },
  containerLeftPanel: {
    width: "50%",
    border: "1px solid black",
    margin: "20px 10px",
    padding: "20px 10px",
    borderRadius: "10px",
    backgroundColor: "lightgoldenrodyellow",
  },
  containerRightPanel: {
    width: "50%",
    border: "1px solid black",
    margin: "20px 10px",
    borderRadius: "10px",
    backgroundColor: "lightcyan",
  },
};

function mapStateToProps(state) {
  const { user } = state.app;
  return { user };
}

export default connect(mapStateToProps)(SecuritySettingsComponent);
