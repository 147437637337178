import React, { useEffect, useState } from "react";
import {
  inflateExpense,
  updateMiscExpense,
} from "../../services/ExpenseService";
import { getLocalizedDate } from "../../helpers/DateHelper";
import PropTypes from "prop-types";
import CalendarInputComponent from "../shared/CalendarInputComponent";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import SpinnerComponent from "../shared/SpinnerComponent";
import TextInputComponent from "../shared/TextInputComponent2";

export default function EditMiscExpenseDialogComponent({
  user,
  groupid,
  editMiscExpenseId,
  onSuccess,
  onCancel,
}) {
  const [expense, setExpense] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const FORMAT = user.country_code === "USA" ? "MM/DD/YYYY" : "DD/MM/YYYY";
  const [friendlyDate, setFriendlyDate] = useState();

  useEffect(() => {
    if (editMiscExpenseId) {
      setIsLoading(true);
      inflateExpense(groupid, editMiscExpenseId)
        .then((e) => setExpense(e))
        .catch(() => setError(true))
        .finally(() => setIsLoading(false));
    }
  }, [editMiscExpenseId]);

  const formik = useFormik({
    initialValues: {
      description: expense?.comments,
      date: expense?.expensedate,
      amount: expense?.amount,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      description: Yup.string().required("Please enter description."),
      date: Yup.string().required("Please enter password."),
      amount: Yup.number()
        .typeError("Amount must be a number")
        .required("Please enter a name."),
    }),
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);

      updateMiscExpense(
        groupid,
        user.username,
        expense.id,
        moment(values.date).format("YYYY-MM-DD"),
        values.amount,
        values.description,
      )
        .then(() => {
          onSuccess();
        })
        .catch((err) => {
          let message = "Whoops! Please try later.";
          setStatusMsg({
            type: "error",
            text: message,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {
    if (formik.values.date) {
      setFriendlyDate(moment(formik.values.date).format(FORMAT));
    }
  }, [formik.values.date]);

  return (
    <div className="modal" id="edit-savings-dialog">
      <div className="modal-content savings-dialog-content">
        {isLoading && <SpinnerComponent size="small" />}

        {!isLoading && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <span className="close" onClick={onCancel}>
              &times;
            </span>
            <div style={{ textAlign: "center" }}>
              <h3> Edit Expense</h3>
            </div>
            <div className="savings-dialog-content-body">
              <table className="formTable">
                <thead>
                  <tr> </tr>
                  <tr> </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      Description <sup>*</sup>
                    </td>
                    <td>
                      {" "}
                      <input
                        type="text"
                        {...formik.getFieldProps("description")}
                      />{" "}
                    </td>
                  </tr>

                  {formik.errors.description && (
                    <tr>
                      <td></td>
                      <td>
                        <span className="errorMsg">
                          {formik.errors.description}
                        </span>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>
                      {" "}
                      Date <sup>*</sup>{" "}
                    </td>
                    <td>
                      <CalendarInputComponent
                        id="edit-savings-date"
                        value={friendlyDate}
                        textAlign={"flex-start"}
                        onChange={(dateText, value) => {
                          console.log("datetext", dateText, value);
                          formik.setFieldValue(
                            "date",
                            moment(value).format("YYYY-MM-DD"),
                          );
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {" "}
                      Amount <sup>*</sup>
                    </td>
                    <td>
                      {" "}
                      <input
                        type="text"
                        id="edit-savings-amount"
                        {...formik.getFieldProps("amount")}
                      />{" "}
                    </td>
                  </tr>
                  {formik.errors.amount && (
                    <tr>
                      <td></td>
                      <td>
                        <span className="errorMsg">{formik.errors.amount}</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div style={{ marginTop: "1em", textAlign: "center" }}>
              <input
                id="cancel"
                type="button"
                value="Cancel"
                className="fnxGrey"
                onClick={onCancel}
              />

              <input
                id="createSaving"
                type="submit"
                value="Update"
                className="fnxGreen"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
