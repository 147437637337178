import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

// supports multi groups.
export function getAllGroups() {
  const url = new FNXConnection().getURL() + "/groups";
  return executeService(url);
}

export function createGroup(name) {
  const url = new FNXConnection().getURL() + "/group";
  return executeService(url, "PUT", { name }, 200, "json");
}

export function inflateGroup(id) {
  const url = new FNXConnection().getURL() + "/groups/" + id;
  return executeService(url);
}

export function updateGroup(id, name) {
  const url = new FNXConnection().getURL() + "/group/" + id;
  return executeService(url, "POST", { name }, 200);
}

export function deleteGroup(id) {
  const url = `${new FNXConnection().getURL()}/group/${id}`;
  return executeService(url, "DELETE");
}

export function getUsersInGroups2(id, excludeMe) {
  const queryParams = excludeMe === true ? "excludeMe=true" : "";
  const url = `${new FNXConnection().getURL()}/groups/${id}/users2?${queryParams}`;
  return executeService(url);
}
