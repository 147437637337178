import React from "react";
import PropTypes from "prop-types";
/**
 * Common status message component.
 *
 * Properties
 * message - {type, text} Types are success | error. text is the message.
 *
 */
export default class StatusMessageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.show = false;
    this.mounted = false;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.message !== prevProps.message) {
      setTimeout(() => {
        this.show = false;
        if (this.mounted) {
          this.forceUpdate();
        }
      }, 4000);
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.message !== this.props.message) {
      this.show = true;
      return true;
    }
    return false;
  }

  render() {
    return (
      <div
        style={{
          backgroundColor:
            this.props.message?.type === "success"
              ? "var(--fnx-green)"
              : "#ff6c6c",
        }}
        className={this.show === true ? "status-msg-show" : "status-msg-hide"}
      >
        <span className="status-msg-text">{this.props.message?.text}</span>
      </div>
    );
  }
}

StatusMessageComponent.propTypes = {
  message: PropTypes.object,
};
