import React from "react";
import PropTypes from "prop-types";
import { formatToLocaleAmount } from "../../../helpers/NumberHelper";
import { connect } from "react-redux";
import SpinnerComponent, {
  SpinnerComponentColor,
} from "../../shared/SpinnerComponent";
import store from "../../../store";
import RadioButtonComponent from "../../shared/RadioButtonComponent";
import { updateSettings } from "../../../actions/settingsAction";
import { getEstimatedSavings } from "../../../helpers/SavingsHelper";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
class HomePageSavingsWidgetComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  async componentDidMount() {
    this.mounted = true;
    this.setState({ isLoading: true });
    setTimeout(() => this.mounted && this.setState({ isLoading: false }), 1500);
  }

  _updateSettings(payload) {
    store.dispatch(updateSettings(payload));
  }

  renderEstimatedSavings() {
    const { estSavings } = getEstimatedSavings(undefined, store);
    const estimatedSavings = formatToLocaleAmount(estSavings);
    if (this.props.userTotal > 0 || this.props.groupTotal > 0) {
      return (
        <span className="total" id="expense-total">
          {estimatedSavings}
        </span>
      );
    }
    return (
      <div className="div-column-2">
        <span className="errorMsg">Unavailable. </span>
        <span>
          Please add <Link to="/settings/income">Income</Link>
        </span>
      </div>
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    return (
      <div className="analysis-tile tile-small">
        <div className="analysis-tile-heading">Estd. Savings</div>
        <div className="analysis-tile-subheading">
          {this.state.isLoading && (
            <SpinnerComponent
              size="medium"
              color={SpinnerComponentColor.grey}
            />
          )}
          {!this.state.isLoading && this.renderEstimatedSavings()}
        </div>
        <div className="tile-body-spread">
          <div>
            <span> Group Inc </span>
            <RadioButtonComponent
              id="include-group-income"
              size="small"
              checked={this.props.include_group_income}
              onChange={(e) =>
                this._updateSettings({ include_group_income: e.target.checked })
              }
            />
          </div>

          <div>
            <span> Misc Exp </span>
            <RadioButtonComponent
              id="include-misc-expense"
              size="small"
              checked={this.props.include_misc_expense_for_savings}
              onChange={(e) =>
                this._updateSettings({
                  include_misc_expense_for_savings: e.target.checked,
                })
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

HomePageSavingsWidgetComponent.propTypes = {
  selectedMonth: PropTypes.number,
  selectedYear: PropTypes.number,
  savingsTotal: PropTypes.number,
  include_group_income: PropTypes.bool,
  include_misc_expense_for_savings: PropTypes.bool,
};

function mapStateToProps(state) {
  const { selectedMonth, selectedYear } = state.app;
  const { monthlyTotal, misc } = state.expense;
  const { total } = state.savings;
  /* eslint-disable camelcase */
  const { include_group_income, include_misc_expense_for_savings } =
    state.settings;
  const { groupTotal, userTotal } = state.income;
  return {
    selectedMonth,
    selectedYear,
    savingsTotal: total,
    include_group_income: include_group_income ?? false,
    include_misc_expense_for_savings: include_misc_expense_for_savings ?? false,
    monthlyTotal,
    miscTotal: misc?.total,
    groupTotal,
    userTotal,
  };
}

export default connect(mapStateToProps)(HomePageSavingsWidgetComponent);
