import React, { useEffect, useState } from "react";
import { authenticate, loginWithSSO } from "../services/LoginService";
import store from "../store";
import { loginSuccess, onLoginSuccess } from "../actions/userAction";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MobileLinksComponent from "./mobile-links/MobileLinksComponent";
import * as AppConfigHelper from "../helpers/AppConfigHelper";
import StatusMessageComponent2 from "./shared/StatusMessageComponent2";
import TextInputComponent2 from "./shared/TextInputComponent2";
import { CircularProgress } from "@mui/material";
import { getGoogleClientId } from "../services/FNXConnection";
import { downloadAndLoadGSIScript } from "../helpers/ResourceHelper";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function LoginComponent({ isMobile }) {
  const [statusMsg, setStatusMsg] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().required("Please enter email."),
      password: Yup.string().required("Please enter password."),
    }),
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);

      authenticate(values.email, values.password)
        .then((result) => {
          if (result.sign) {
            const sign = result.sign;
            AppConfigHelper.setSignature(sign);
            store.dispatch(loginSuccess(result));
            store.dispatch(onLoginSuccess());
          } else {
            throw new Error(
              `Whoops! Something went wrong on our side. Please try after sometime or contact the admin.`,
            );
          }
        })
        .catch((err) => {
          let message =
            err.message ||
            "The email or password you have entered is incorrect. Please try again.";
          if (message === "Failed to fetch") {
            message =
              "Whoops! Our servers appears to be down. Please try later.";
          }

          setStatusMsg({
            type: "error",
            text: message,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {
    downloadAndLoadGSIScript()
      .then(() => {
        window.google.accounts.id.initialize({
          client_id: getGoogleClientId(),
          callback: (response) => loginSSOHandler(response),
        });
        window.google.accounts.id.renderButton(
          document.querySelector(".sso-signin"),
          { theme: "dark", size: "medium" }, // customization attributes
        );
      })
      .catch(console.error);
  }, []);

  const loginSSOHandler = async (responseFromGmailAuth) => {
    try {
      setIsLoading(true);
      // result is user object.
      const result = await loginWithSSO(responseFromGmailAuth.credential);
      if (result.sign) {
        const sign = result.sign;
        AppConfigHelper.setSignature(sign);
        store.dispatch(loginSuccess(result));
        store.dispatch(onLoginSuccess());
      } else {
        throw new Error(
          `Whoops! Something went wrong on our side. Please try after sometime or contact the admin.`,
        );
      }
    } catch (err) {
      let message =
        err.message ||
        "The email or password you have entered is incorrect. Please try again.";
      if (message === "Failed to fetch") {
        message = "Whoops! Our servers appears to be down. Please try later.";
      }
      setStatusMsg({
        type: "error",
        text: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  console.log(formik.errors, formik.touched);

  return (
    <div className="margin-center">
      <StatusMessageComponent2 message={statusMsg} />

      {isMobile && (
        <div className="divCenterAlign">
          <Link to="/">
            <img
              src={"/assets/logo.png"}
              width={100}
              style={{ borderRadius: 20 }}
            />
          </Link>
        </div>
      )}

      <div className="title-extra topMargin1P my-40">Login</div>

      {isMobile && (
        <div
          className="divCenterAlign"
          style={{
            flexDirection: "column",
            backgroundColor: "var(--primary)",
            color: "white",
            borderRadius: 10,
            fontWeight: "bold",
          }}
        >
          <p>It appears you are using mobile browser.</p>
          <p>
            We recommend downloading our mobile apps for the best possible user
            experience.
          </p>
        </div>
      )}

      {!isMobile && (
        <div style={styles.tableContainer}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <table style={styles.formTable}>
              <tbody>
                <tr>
                  <td>Email</td>
                  <td>
                    <TextInputComponent2
                      id="email"
                      name="email"
                      type="text"
                      errorid="email-error"
                      error={formik.errors.email}
                      value={formik.values.email}
                      onChange={(e) =>
                        formik.setFieldValue("email", e.target.value)
                      }
                    />
                  </td>
                </tr>

                <tr>
                  <td>Password</td>
                  <td>
                    <TextInputComponent2
                      id="password"
                      name="password"
                      type="password"
                      errorid="password-error"
                      error={formik.errors.password}
                      value={formik.values.password}
                      onChange={(e) =>
                        formik.setFieldValue("password", e.target.value)
                      }
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    {" "}
                    <a
                      href="https://hub.expensehut.com/forgot-password"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Forgot Password
                    </a>{" "}
                  </td>
                  <td style={{ display: "flex" }}>
                    <input
                      id="login"
                      className="bigButtonGreen"
                      value="Login"
                      type="submit"
                      disabled={
                        isLoading || Object.keys(formik.errors).length > 0
                      }
                    />

                    {isLoading && (
                      <div
                        className="divCenterAlign"
                        style={{ marginLeft: "20px" }}
                      >
                        <CircularProgress
                          size={20}
                          thickness={5}
                          color={"secondary"}
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <div
                      className="sso-signin"
                      style={{ width: "200px" }}
                    ></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      )}

      <MobileLinksComponent isMobile={isMobile} />
    </div>
  );
}

const styles = {
  formTable: {
    borderSpacing: "2em",
    fontSize: "18px",
  },
  tableContainer: {
    display: "flex",
    justifyContent: "center",
  },
  sso: {
    display: "flex",
    justifyContent: "center",
  },
};
