import { put, takeEvery } from "redux-saga/effects";
import {
  ACTION_GET_GROUP_DETAILS,
  setDefaultGroup,
  setGroupUsers,
  UPDATE_DEFAULT_GROUP,
  ACTION_GET_GROUP_USERS,
} from "../actions/groupsAction";
import store from "../store";
import * as Api from "../services/GroupService";
import * as UserApi from "../services/UserService";

import { setGroupDetails } from "../actions/groupsAction";
import { doRefreshUser } from "../actions/userAction";

function* findGroupForUser() {
  try {
    const groupsRes = yield Api.getAllGroups();

    yield put(setGroupDetails(groupsRes.default, groupsRes.groups));
  } catch (e) {
    console.error(e);
  }
}

function* updateDefaultGroup(action) {
  try {
    const groupid = action.payload;
    yield UserApi.updateMyDefaultGroup(groupid);
    yield put(setDefaultGroup(groupid));
    yield put(doRefreshUser());
    yield put(getGroupUsers(groupid)); // whenenever default group changes, request users in group
  } catch (e) {
    console.error(e);
  }
}

function* getGroupUsers(action) {
  try {
    const users = yield Api.getUsersInGroups2(action.payload);
    yield put(setGroupUsers(users));
  } catch (e) {
    console.error(e);
  }
}

export default function* groupsSaga() {
  yield takeEvery(ACTION_GET_GROUP_DETAILS, findGroupForUser);
  yield takeEvery(UPDATE_DEFAULT_GROUP, updateDefaultGroup);
  yield takeEvery(ACTION_GET_GROUP_USERS, getGroupUsers);
}
