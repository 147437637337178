import React from "react";
import "./LeftPaneComponent.css";

export default function FooterComponent({ isMobile }) {
  return (
    <div className="footer">
      <div className="footer-copyright"> &copy; 2024 www.expensehut.com </div>
      <div
        className={isMobile ? "div-column-2" : "divSpread mx-20 mt-10"}
        style={{ fontSize: "small" }}
      >
        <a
          target="_blank"
          rel="noreferrer"
          style={{ color: "yellow", margin: "3px 0px" }}
          href="https://help.expensehut.com/terms"
        >
          Terms{" "}
        </a>{" "}
        <a
          target="_blank"
          rel="noreferrer"
          style={{ color: "yellow", margin: "3px 0px" }}
          href="https://help.expensehut.com/privacy"
        >
          Privacy
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ color: "yellow", margin: "3px 0px" }}
          href="https://help.expensehut.com/aup"
        >
          Acceptable Use{" "}
        </a>{" "}
      </div>
    </div>
  );
}
