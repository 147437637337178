export const ACTION_SET_EDIT_TRIP_ID = "SET_EDIT_TRIP_ID";
export const ACTION_GET_TRIPS = "GET_TRIPS";
export const ACTION_SET_TRIPS = "SET_TRIPS";

export function setEditTripId(payload) {
  return {
    type: ACTION_SET_EDIT_TRIP_ID,
    payload,
  };
}

export function getTrips() {
  return {
    type: ACTION_GET_TRIPS,
  };
}

export function setTrips(payload) {
  return {
    type: ACTION_SET_TRIPS,
    payload,
  };
}
