import React from "react";
import { Link } from "react-router-dom";

export default class NoAccountsComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <div className="no-accounts-page" style={{ padding: "1em" }}>
        <div>
          <div className="greeting">
            <h1> One moment,</h1>
          </div>

          <div className="greeting-body">
            <div>
              Looks like you don&apos;t have any accounts on our system.
            </div>
            <div>
              Accounts can be more useful to track expenses using our analytics
              platforms.
            </div>
            <div>
              It can be your bank&apos;s account name or name of your credit
              card and so on.
            </div>
            <div>
              <span className="errorMsg">
                * Please be sure not to add sensitive information such as credit
                card numbers.
              </span>
            </div>
            Click <Link to="/settings/accounts">here</Link> to add acounts.
          </div>
        </div>
      </div>
    );
  }
}
