import React from "react";
import "./TermsConditions.css";
import {
  shouldShowTermsConditions,
  hideShowTermsConditions,
} from "../../helpers/AppConfigHelper";

export default class TermsConditionsComponent extends React.Component {
  constructor(props) {
    super(props);
    this._closeTermsConditions = this._closeTermsConditions.bind(this);
  }

  _closeTermsConditions() {
    hideShowTermsConditions();
    this.forceUpdate();
  }

  render() {
    if (shouldShowTermsConditions()) {
      return (
        <div id="terms">
          <table>
            <tbody>
              <tr>
                <td>
                  This website uses cookies. For more on cookie policy, please
                  see visit
                  <a
                    target="_blank"
                    style={{ marginLeft: "5px" }}
                    rel="noreferrer"
                    href="https://help.expensehut.com/cookies"
                  >
                    cookies
                  </a>
                  .
                </td>
                <td>
                  <span className="close" onClick={this._closeTermsConditions}>
                    &times;
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  By visiting our website, you acknowledge that you agree to our
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ margin: "0px 5px" }}
                    href="https://help.expensehut.com/privacy"
                  >
                    privacy policy
                  </a>
                  and
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ margin: "0px 5px" }}
                    href="https://help.expensehut.com/terms"
                  >
                    terms & conditions
                  </a>
                  .
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  }
}
