import React from "react";
import PropTypes from "prop-types";
import CalendarInputComponent from "../../components/shared/CalendarInputComponent";
import { setSelectedAccount } from "../../actions/expensesAction";
import store from "../../store";
import { DeleteButtonComponent } from "../../components/shared/ButtonComponent";

const styles = {
  mischeckbox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "30px",
    width: "30px",
  },
};

export default class CreateExpenseRowComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      subcategories: [],
      selectedAmount: "",
      selectedDate: "",
      selectedComments: "",
    };
    this.getSubcategories = this.getSubcategories.bind(this);
    this.toggleMiscOnlyHandler = this.toggleMiscOnlyHandler.bind(this);
  }

  async componentDidMount() {
    try {
      if (this.props.defaultCategory) {
        const categorySelect = document.querySelector(
          "#category" + this.props.index,
        );
        categorySelect.value = this.props.defaultCategory;
        categorySelect.classList.remove("error-background");

        await this.getSubcategories(this.props.defaultCategory);

        const subcategorySelect = document.querySelector(
          "#subcategory" + this.props.index,
        );
        subcategorySelect.value = this.props.defaultSubCategory;
        subcategorySelect.classList.remove("error-background");
      }

      if (this.props.defaultDate) {
        this.setState({ selectedDate: this.props.defaultDate });
        const dateComp = document.querySelector(
          "#expenseDate" + this.props.index,
        );
        dateComp.classList.remove("error-background");
      }
    } catch (err) {
      console.error(err);
    }
  }

  async getSubcategories(category) {
    if (category && category.trim().length > 0) {
      this.setState({ subcategories: this.props.categoriesMap[category] });
    } else {
      this.setState({ subcategories: [] });
    }
  }

  toggleMiscOnlyHandler(toggle) {
    const categorySelect = document.querySelector(
      "#category" + this.props.index,
    );
    const subcategorySelect = document.querySelector(
      "#subcategory" + this.props.index,
    );
    const account = document.querySelector("#creditcard" + this.props.index);
    const comments = document.querySelector("#comments" + this.props.index);

    if (categorySelect) {
      categorySelect.disabled = toggle;
    }
    if (subcategorySelect) {
      subcategorySelect.disabled = toggle;
    }
    if (account) {
      account.disabled = toggle;
    }

    if (comments) {
      if (comments.value.trim() === "") {
        comments.classList.add("error-background");
      } else {
        comments.classList.remove("error-background");
      }
    }
    this.props.onInputChange();
  }

  handleAccountChange(e) {
    store.dispatch(setSelectedAccount(e.target.value));
  }

  handleDeleteRow(index) {
    // this.props.onDeleteRow(index);
    const categoryComp = document.querySelector("#category" + index);
    const subcategoryComp = document.querySelector("#subcategory" + index);
    const dateComp = document.querySelector("#expenseDate" + index);
    const amountComp = document.querySelector("#amount" + index);
    const commentsComp = document.querySelector("#comments" + index);

    const showConfirmation =
      categoryComp.value !== "" ||
      subcategoryComp.value !== "" ||
      dateComp.value.trim() !== "" ||
      amountComp.value.trim() !== "" ||
      commentsComp.value.trim() !== "";

    this.props.onDeleteRow(index, showConfirmation);
  }

  render() {
    return (
      <tr style={{ verticalAlign: "top" }}>
        <td>
          <DeleteButtonComponent
            id={"deleteBtn" + this.props.index}
            onClick={() => this.handleDeleteRow(this.props.index)}
          />
        </td>

        <td style={styles.mischeckbox}>
          <input
            type="checkbox"
            id={"miscOnly" + this.props.index}
            onClick={(e) => this.toggleMiscOnlyHandler(e.target.checked)}
            title="Add as miscellaneous expense."
          />
        </td>

        <td>
          <select
            id={"category" + this.props.index}
            style={{ width: "160px" }}
            className="error-background"
            onChange={async (e) => {
              await this.getSubcategories(e.target.value);
              this.props.onInputChange();
              if (e.target.value === "") {
                e.target.classList.add("error-background");
              } else {
                e.target.classList.remove("error-background");
                // remove subcategory classlist as well once category is fine.
                const subcategoryElement = document.querySelector(
                  "#subcategory" + this.props.index,
                );
                if (subcategoryElement) {
                  subcategoryElement.classList.remove("error-background");
                }
              }
            }}
          >
            <option value=""> --SELECT--</option>
            {this.props.categoriesMap &&
              Object.keys(this.props.categoriesMap).map((item, i) => {
                return (
                  <option value={item} key={i}>
                    {" "}
                    {item}{" "}
                  </option>
                );
              })}
          </select>
        </td>

        <td>
          <select
            id={"subcategory" + this.props.index}
            style={{ width: "160px" }}
            className="error-background"
            defaultValue={this.props.defaultSubCategory}
            onChange={(e) => {
              if (e.target.value === "") {
                e.target.classList.add("error-background");
              } else {
                e.target.classList.remove("error-background");
              }
            }}
          >
            {this.state.subcategories.map((item, i) => {
              return (
                <option value={item} key={i}>
                  {" "}
                  {item}{" "}
                </option>
              );
            })}
          </select>
        </td>

        <td>
          <CalendarInputComponent
            id={"expenseDate" + this.props.index}
            width={150}
            position="absolute"
            value={this.state.selectedDate}
            onChange={(value) => {
              this.setState({ selectedDate: value });
              this.props.onInputChange();
            }}
            showError
          />
        </td>

        <td>
          <input
            id={"amount" + this.props.index}
            type="text"
            className="error-background"
            value={this.state.selectedAmount}
            autoComplete="off"
            style={{ width: "50px" }}
            onChange={(e) => {
              const value = e.target.value;
              if (!value || isNaN(value)) {
                e.target.classList.add("error-background");
              } else {
                e.target.classList.remove("error-background");
              }
              this.setState({ selectedAmount: e.target.value });
              this.props.onInputChange();
            }}
            onFocus={() => this.props.onFetchRelatedExpenses(this.props.index)}
          />
        </td>

        <td>
          <input
            id={"comments" + this.props.index}
            type="text"
            value={this.state.selectedComments}
            autoComplete="off"
            maxLength="35"
            onChange={(e) => {
              this.setState({ selectedComments: e.target.value });
              const miscOnlyComponent = document.querySelector(
                "#miscOnly" + this.props.index,
              );

              const value = e.target.value;
              if (miscOnlyComponent.checked === true && value.trim() === "") {
                e.target.classList.add("error-background");
              } else {
                e.target.classList.remove("error-background");
              }

              this.props.onInputChange();
            }}
          />
        </td>

        <td>
          <select
            className={"fixedSelect"}
            id={"creditcard" + this.props.index}
            defaultValue={store.getState().expense.legacy?.selectedAccount}
            onChange={(e) => this.handleAccountChange(e)}
            style={{ maxWidth: 120 }}
          >
            {this.props.cards.map((card, i) => {
              return (
                <option className={"fixedSelect"} value={card.id} key={i}>
                  {card.name}
                </option>
              );
            })}
          </select>
        </td>
      </tr>
    );
  }
}

CreateExpenseRowComponent.propTypes = {
  index: PropTypes.number.isRequired,
  onDeleteRow: PropTypes.func.isRequired,
  onFetchRelatedExpenses: PropTypes.func.isRequired,
  user: PropTypes.any.isRequired,
  cards: PropTypes.any.isRequired,
  onInputChange: PropTypes.func,
  defaultDate: PropTypes.any,
  defaultCategory: PropTypes.any,
  defaultSubCategory: PropTypes.any,
  categoriesMap: PropTypes.object,
};
