import { ACTION_SET_TRANSACTIONS_PLAID_EXPENSE_ERROR } from "../actions/transactionAction";

const initState = {
  expenseErrors: [],
  balanceErrors: [],
};
export default function transactions(state = initState, action) {
  switch (action.type) {
    case ACTION_SET_TRANSACTIONS_PLAID_EXPENSE_ERROR:
      return {
        ...state,
        expenseErrors: action.payload,
      };
    default:
      return state;
  }
}
