import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export function getSettings(username) {
  const url = new FNXConnection().getURL() + "/users/" + username + "/settings";
  return executeService(url);
}

export function updateSettings(
  username,
  includeGroupIncome,
  includeMiscExpensesForSavings,
  includeMiscExpenseInTotal,
) {
  const url = new FNXConnection().getURL() + "/users/" + username + "/settings";
  return executeService(
    url,
    "POST",
    {
      include_group_income: includeGroupIncome,
      include_misc_expense_for_savings: includeMiscExpensesForSavings,
      include_misc_expense_in_total: includeMiscExpenseInTotal,
    },
    200,
    "json",
  );
}

export function enableSSO(username, flag) {
  const url =
    new FNXConnection().getURL() +
    "/users/" +
    username +
    "/enableSSO?enable=" +
    flag;
  return executeService(url, "POST", {}, 200, "json");
}
