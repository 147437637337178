import React from "react";
import PropTypes from "prop-types";
import { getGroupsIcon } from "../../helpers/IconHelper";
import store from "../../store";
import { connect } from "react-redux";
import { updateDefaultGroup } from "../../actions/groupsAction";

const styles = {
  container: {
    display: "flex",
    margin: "44px",
    flexDirection: "column",
  },
  header: {
    margin: "5% 0%",
  },
};

function ChooseGroupComponent({ groups }) {
  return (
    <div style={styles.container}>
      <div className="divCenter">
        <div className="divCenterAlign">
          <img
            src={getGroupsIcon()}
            style={{ width: 100, margin: "0px 10px" }}
          />
        </div>
        <div className="title">Select Group:</div>
      </div>

      <div className="divCenterAlign" style={styles.header}>
        <h3>
          {" "}
          It appears you linked to multiple groups. Please choose one to
          continue.{" "}
        </h3>
      </div>

      <div className="divCenterAlign">
        <select
          id="group-select"
          style={{ fontSize: "20px", minWidth: "200px", margin: "0px 20px" }}
          onChange={(e) => this.setState({ selectedGroupId: e.target.value })}
        >
          {this.props.groups &&
            this.props.groups.map((g, i) => {
              return (
                <option value={g.id} key={i}>
                  {g.name}
                </option>
              );
            })}
        </select>
        <input
          type="button"
          className="bigButtonGreen"
          value="Continue"
          onClick={() => this.updateGroupHandler()}
        />
      </div>

      <div className="divCenterAlign" style={{ margin: "5% 0%" }}>
        <span className="largeText blueMsg">
          Note: You can set a default group under Settings.
        </span>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { groups } = state.group;
  return { groups };
}

export default connect(mapStateToProps)(ChooseGroupComponent);
