import {
  ACTION_LOGIN_SUCCESS,
  ACTION_REFRESH_USER,
  ACTION_LOGOUT_USER,
} from "../actions/userAction";
import {
  ACTION_SET_SELECTED_MONTH,
  ACTION_SET_SELECTED_YEAR,
  ACTION_SET_EDIT_EXPENSE_ID,
  ACTION_SET_INTELLIGENT_MODE,
} from "../actions/expensesAction";
import { ACTION_SET_ACCOUNTS } from "../actions/accountsAction";
import { ACTION_SET_COUNTRIES } from "../actions/countriesAction";
import {
  ACTION_SET_CATEGORIES,
  ACTION_SET_CATEGORIES_MAP,
} from "../actions/categoriesAction";

const initState = {
  accounts: [],
  expensesMonthYear: [],
  countries: [],
  expense: {},
};
export default function app(state = initState, action) {
  switch (action.type) {
    case ACTION_LOGIN_SUCCESS:
    case ACTION_REFRESH_USER:
      return {
        ...state,
        user: action.payload,
        logoutMsg: undefined,
      };
    case ACTION_SET_SELECTED_MONTH:
      return {
        ...state,
        selectedMonth: action.payload,
      };
    case ACTION_SET_SELECTED_YEAR:
      return {
        ...state,
        selectedYear: action.payload,
      };
    case ACTION_SET_EDIT_EXPENSE_ID:
      return {
        ...state,
        expense: {
          ...state.expense,
          editExpenseId: action.payload,
        },
      };
    case ACTION_SET_INTELLIGENT_MODE:
      return {
        ...state,
        expense: {
          ...state.expense,
          intelligentMode: action.payload,
        },
      };
    case ACTION_SET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
      };
    case ACTION_SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case ACTION_SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case ACTION_SET_CATEGORIES_MAP:
      return {
        ...state,
        categoriesMap: action.payload,
      };
    case ACTION_LOGOUT_USER:
      return {
        ...state,
        user: undefined,
        logoutMsg: action.payload?.length ? action.payload : undefined,
      };
    default:
      return state;
  }
}
