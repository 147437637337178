import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export function getCreditCards(username) {
  const url = new FNXConnection().getURL() + "/user/" + username + "/cc";
  return executeService(url);
}

export function updateCreditCard(ccid, name, username) {
  const url = new FNXConnection().getURL() + "/cc";
  return executeService(url, "POST", { username, ccid, name }, 200, "json");
}

export function createCreditCard(name) {
  const url = new FNXConnection().getURL() + "/cc";
  return executeService(url, "PUT", { name }, 200, "json");
}

export function deleteCreditCard(username, ccid) {
  const url = `${new FNXConnection().getURL()}/user/${username}/cc/${ccid}`;
  return executeService(url, "DELETE");
}
