export const ACTION_SET_CATEGORIES = "SET_CATEGORIES";
export const ACTION_SET_CATEGORIES_MAP = "ACTION_SET_CATEGORIES_MAP";

export const ACTION_GET_CATEGORIES = "GET_CATEGORIES";
export const ACTION_GET_CATEGORIES_MAP = "ACTION_GET_CATEGORIES_MAP";

export function setCategoriesMap(payload) {
  return {
    type: ACTION_SET_CATEGORIES_MAP,
    payload,
  };
}

export function setCategories(payload) {
  return {
    type: ACTION_SET_CATEGORIES,
    payload,
  };
}

export function getCategories() {
  return {
    type: ACTION_GET_CATEGORIES,
  };
}

export function getCategoriesMap() {
  return {
    type: ACTION_GET_CATEGORIES_MAP,
  };
}
