import React from "react";
import { getCategoriesOnly } from "../../../services/CategoryService";
import { getCreditCards } from "../../../services/CreditCardService";
import { createExpenseAI } from "../../../services/ExpenseAIService";
import { getExpensesRelated } from "../../../services/ExpenseService";
import PropTypes from "prop-types";
import NoAccountsComponent from "../NoAccountsComponent";
import { getTodaysDate } from "../../../helpers/DateHelper";
import "./CreateExpenseAIComponent.css";
import AITextInputComponent from "./AITextInputComponent";
import { connect } from "react-redux";
import RelatedExpenseAIDialogComponent from "./RelatedExpenseAIDialogComponent";
import RecentExpenseComponent from "../RecentExpenseComponent";
import ExpenseAIMatchComponent from "./ExpenseAIMatchComponent";
import store from "../../../store";
import {
  getValidatePhraseJSONData,
  setValidatePhraseJSONData,
  setRelatedExpenses,
  getExpensesTotal,
  getMiscExpensesTotal,
} from "../../../actions/expensesAction";

class CreateExpenseAIComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      phrase: "",
      inlinePhraseMsgArr: [],
      relatedExpenses: [],
      showRelatedExpenses: false,
      isAcknowledged: false,
      recentExpenses: [],
    };
    this.createExpenseHandler = this.createExpenseHandler.bind(this);
    this.validatePhrase = this.validatePhrase.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.resetAll();
  }

  async componentDidMount() {
    try {
      const categories = await getCategoriesOnly();
      this.setState({ categories, rows: [] });
      const cards = await getCreditCards(this.props.user.username);
      this.setState({ cards });
      this.resetAll();
    } catch (err) {
      console.error(err);
      if (err.status === 401) {
        this.props.doLogout();
      }
    }
  }

  componentDidUpdate() {
    const errors = document.querySelectorAll("#expense-phrase-pane .errorMsg");
    const submitBtn = document.querySelector(".fnxGreen");
    if (errors.length > 0 || this.state.phrase === "") {
      if (submitBtn) {
        submitBtn.disabled = true;
      }
    } else {
      if (submitBtn) {
        submitBtn.disabled = false;
      }
    }
  }

  async createExpenseHandler() {
    try {
      await createExpenseAI(
        this.props.jsonData.phrase,
        this.props.user.username,
      );
      // these two should be called out to update the total values.
      store.dispatch(getExpensesTotal());
      store.dispatch(getMiscExpensesTotal());
      this.setState({
        statusMsg: {
          type: "success",
          text: "Expense created successfully.",
        },
      });
    } catch (err) {
      console.error(err);
      this.setState({
        statusMsg: {
          type: "error",
          text: "Whoops! Something went wrong. Please try again.",
        },
      });
    }
    this.resetAll();
  }

  async getRelatedExpenses(jsonData) {
    const category = jsonData?.category;
    const subcategory = jsonData?.subcategory;
    const expenseDate = jsonData?.expenseDate;

    if (category && subcategory && expenseDate) {
      const dateSplits = expenseDate.split("/");
      const month =
        this.props.user.country_code === "USA" ? dateSplits[0] : dateSplits[1];
      const year = dateSplits[2];
      return getExpensesRelated(
        this.props.user.groupid,
        year,
        month,
        category,
        subcategory,
      );
    }
    return Promise.resolve([]);
  }

  async validatePhrase() {
    const phraseText = this.state.phrase;
    try {
      store.dispatch(
        getValidatePhraseJSONData({
          phrase: phraseText,
          user: this.props.user,
        }),
      );
      // const jsonData = await validatePhrase(phraseText);
      const relatedExpenses = await this.getRelatedExpenses(
        this.props.jsonData,
      );
      this.setState({
        //  inlinePhraseMsgArr: _getInlineMessage(jsonData, this.props.user, phraseText),
        relatedExpenses,
        statusMsg: undefined, // set to undefined if previously set status message
      });
    } catch (err) {
      console.error(err);
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        console.error(err);
      }
    }
  }

  resetAll() {
    store.dispatch(setValidatePhraseJSONData(undefined));
    store.dispatch(setRelatedExpenses([]));
    this.setState({
      isAcknowledged: false,
      showRelatedExpenses: false,
      phrase: "",
      inlinePhraseMsgArr: [],
      relatedExpenses: [],
      jsonData: undefined,
    });
  }

  render() {
    if (this.props.accounts.length === 0) {
      return <NoAccountsComponent />;
    }

    return (
      <div className="margin-center">
        <div className="title">Add Expense - Intelligent Mode</div>

        {this.state.showRelatedExpenses && (
          <RelatedExpenseAIDialogComponent
            onConfirm={() =>
              this.setState({
                isAcknowledged: true,
                showRelatedExpenses: false,
              })
            }
            relatedExpenses={this.props.relatedExpenses}
            onCancel={() => this.setState({ showRelatedExpenses: false })}
          />
        )}

        <div style={{ marginTop: "3%", textAlign: "center" }}>
          {this.state.statusMsg && (
            <span
              className={
                this.state.statusMsg.type === "error"
                  ? "errorMsg"
                  : "successMsg"
              }
            >
              {this.state.statusMsg.text}
            </span>
          )}
        </div>

        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            margin: "20px 0px 44px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              minHeight: "30px",
              width: "65%",
              justifyContent: "flex-end",
            }}
          >
            {this.props.relatedExpenses.length > 0 &&
              this.state.isAcknowledged !== true && (
                <div className="expense-ai-relatedexpense-banner">
                  <span> Related/duplicate expenses may be found. </span>
                  Click{" "}
                  <a
                    className="hyperlink"
                    style={{ color: "cyan" }}
                    onClick={() => this.setState({ showRelatedExpenses: true })}
                  >
                    here{" "}
                  </a>{" "}
                  to view.
                </div>
              )}
          </div>

          <div>
            <span className="hyperlink" onClick={this.props.onLegacy}>
              Switch to Legacy
            </span>
          </div>
        </div>

        <div style={{ justifyContent: "center", display: "flex" }}>
          <div>
            <a
              href="https://help.expensehut.com/createExpenseHelp"
              rel="noreferrer"
              target="_blank"
              style={{ marginRight: "20px" }}
            >
              Help
            </a>
          </div>
          <div>
            <AITextInputComponent
              type="text"
              id="phrase-text"
              value={this.state.phrase}
              width="370px"
              placeholder="Enter expense phrase here..."
              onChange={(e) =>
                this.setState({ phrase: e.target.value }, this.validatePhrase)
              }
              inlineMessageArr={_getInlineMessage(
                this.props.jsonData,
                this.props.user,
                this.state.phrase,
              )}
            />
          </div>
          <div style={{ display: "flex" }}>
            <div id="expense-ai-input-container">
              <input
                type="button"
                className="fnxGreen"
                value="Create"
                title={
                  this.state.relatedExpenses.length > 0 &&
                  this.state.isAcknowledged !== true
                    ? "You can also click the above link to confirm duplicates."
                    : ""
                }
                onClick={this.createExpenseHandler}
              />
              <a
                className="hyperlink"
                style={{ color: "red" }}
                onClick={this.resetAll}
              >
                {" "}
                Clear{" "}
              </a>
            </div>
          </div>
        </div>

        <div>
          {this.props.jsonData && (
            <ExpenseAIMatchComponent
              jsonData={this.props.jsonData}
              category={this.props.jsonData.category}
              subcategory={this.props.jsonData.subcategory}
              expenseDate={this.props.jsonData.expenseDate}
              amount={this.props.jsonData.amount}
              comments={this.props.jsonData.comments}
              card={this.props.jsonData.card}
            />
          )}
        </div>

        <div>{!this.props.jsonData && <RecentExpenseComponent />}</div>
      </div>
    );
  }
}

CreateExpenseAIComponent.propTypes = {
  doLogout: PropTypes.func.isRequired,
  user: PropTypes.any.isRequired,
  onLegacy: PropTypes.func.isRequired,
  accounts: PropTypes.array,
  selectedMonth: PropTypes.any,
  selectedYear: PropTypes.any,
  jsonData: PropTypes.any,
  relatedExpenses: PropTypes.array,
};

function _getInlineMessage(jsonData, user, phraseText) {
  const messages = [];
  if (phraseText.trim().length > 0 && jsonData) {
    if (!jsonData["category"] || !jsonData["subcategory"]) {
      messages.push({
        type: "error",
        text: "Enter a category or subcategory name.",
      });
    }
    if (jsonData["subcategory"]) {
      const subcat = jsonData["subcategory"];
      if (subcat.includes(",")) {
        messages.push({
          type: "error",
          text: "Choose one of the subcategory as shown in the Category column",
        });
      }
    }
    if (
      !jsonData["amount"] ||
      (jsonData["amount"] && jsonData["amount"] === 0)
    ) {
      messages.push({
        text: "Enter a valid amount.",
        type: "error",
      });
    }
    if (jsonData["expenseDate"] === getTodaysDate(user)) {
      const dateType =
        user.country_code !== "USA" ? "dd/mm/yyyy" : "mm/dd/yyyy";
      messages.push({
        type: "warn",
        text: `If no date is specified, todays date will be used. You can provide date in ${dateType} format.`,
      });
    }
    if (
      !jsonData["comments"] ||
      (jsonData["comments"] && jsonData["comments"].trim() === "")
    ) {
      messages.push({
        type: "warn",
        text: "Comments are optional, but are useful during search for expenses.",
      });
    }
    if (!jsonData["cardId"]) {
      messages.push({
        type: "error",
        text: "Enter account name to attach this expense with.",
      });
    }
  } else {
    /* messages.push({
      type: 'warn',
      text: 'Go ahead and enter the expense in few words!'
    }); */
  }

  return messages;
}

function mapStateToProps(state) {
  const { user, accounts, selectedMonth, selectedYear } = state.app;
  const { ai, relatedExpenses } = state.expense;
  return {
    user,
    accounts,
    selectedMonth,
    selectedYear,
    relatedExpenses,
    jsonData: ai?.json,
    jsonDataError: ai?.error,
  };
}

export default connect(mapStateToProps)(CreateExpenseAIComponent);
