import React, { useCallback, useEffect, useState } from "react";
import {
  getSubscriptions,
  createSubscriptions,
  deleteSubscription,
} from "../../../services/SubscriptionsService";
import store from "../../../store";
import StatusMessageComponent2 from "../../shared/StatusMessageComponent2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getCategoriesMap } from "../../../services/CategoryService";
import Alert from "@mui/material/Alert";
import { getCurrencySymbol } from "../../../helpers/CurrencyHelper";
import { DeleteButtonComponent } from "../../shared/ButtonComponent";
import ConfirmationDialog from "../../shared/ConfirmationDialogComponent";
import { capitalize, getSuperScript } from "../../../helpers/StringHelper";
import {
  getGroupDetails,
  setDefaultGroup,
} from "../../../actions/groupsAction";

export default function SubscriptionsComponent({ user }) {
  const [subscriptions, setSubscriptions] = useState([]);
  const [categoriesMap, setCategoriesMap] = useState([]);

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubCategories] = useState([]);
  const [statusMsg, setStatusMsg] = useState();
  const groupid = store.getState().group?.groupid;
  const accounts = store.getState().app?.accounts;
  const [deleteId, setDeleteId] = useState();

  useEffect(() => {
    getCategoriesMap().then((res) => setCategoriesMap(res));
  }, []);

  useEffect(() => {
    if (categoriesMap) {
      setCategories(Object.keys(categoriesMap));
    }
  }, [categoriesMap]);

  useEffect(() => {
    if (user && groupid) {
      fetchAll();
    }
  }, [user, groupid]);

  const fetchAll = () => {
    getSubscriptions()
      .then((res) => setSubscriptions(res))
      .catch(() =>
        setStatusMsg({ type: "error", text: "Error fetching subscriptions." }),
      );
  };

  const formik = useFormik({
    initialValues: {
      category: "",
      subcategory: "",
      amount: "",
      dayOfMonth: "",
      name: "",
      creditCard: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name."),
      category: Yup.string().required("Please select category."),
      subcategory: Yup.string().required("Please select subcategory."),
      amount: Yup.number()
        .typeError("Amount must be a number")
        .required("Please add amount."),
      dayOfMonth: Yup.string().required(
        "Please select which day of the month.",
      ),
      creditCard: Yup.string().required("Please select an account."),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      setStatusMsg();

      createSubscriptions(
        values.category,
        values.subcategory,
        values.amount,
        "M",
        values.dayOfMonth,
        values.name,
        values.creditCard,
      )
        .then(() => {
          formik.resetForm();
          setStatusMsg({
            type: "success",
            text: "Subscription successfully created",
          });
          fetchAll();
        })
        .catch((e) => {
          console.error(e);

          setStatusMsg({
            type: "error",
            text: "Whoops! Something went wrong. Please try again.",
          });
        });
    },
  });

  useEffect(() => {
    if (formik.values.category) {
      setSubCategories(categoriesMap[formik.values.category]);
    } else {
      setSubCategories([]);
    }
  }, [formik.values.category, categoriesMap]);

  const handleDelete = useCallback(() => {
    deleteSubscription(deleteId)
      .then(() => {
        setStatusMsg({
          type: "success",
          text: "Subscription deleted successfully.",
        });
        fetchAll();
      })
      .catch((err) => {
        console.error(err);
        setStatusMsg({
          type: "error",
          text: "Whoops! Something went wrong. Please try again.",
        });
      })
      .finally(() => setDeleteId());
  }, [deleteId]);

  return (
    <div className="margin-center">
      <div className="title">Subscriptions</div>

      <StatusMessageComponent2 message={statusMsg} />

      <ConfirmationDialog
        title="Delete subscription?"
        open={deleteId}
        onProceed={handleDelete}
        onClose={() => setDeleteId()}
      />

      <div className="trip-page">
        <div className="trip-page-left-panel">
          <ol className="noDiscList">
            {subscriptions.map((sub, i) => {
              return (
                <li
                  className="tabbed-list-li trip-list-li"
                  key={i}
                  onClick={() => {}}
                >
                  <div className="divSpread">
                    <div className="divCenterAlign">
                      <h1 style={{ color: "lightgrey" }}>{sub.day_of_month}</h1>
                      <sup style={{ color: "lightgrey" }}>
                        {getSuperScript(sub.day_of_month)}
                      </sup>
                    </div>

                    <div className="divCenterAlign">
                      <h3 className="trip-name">{capitalize(sub.name)}</h3>
                    </div>

                    <div className="divCenterAlign">
                      <h3>
                        {getCurrencySymbol(user)} {sub.amount || 0}
                      </h3>
                    </div>

                    <div className="divCenterAlign">
                      <DeleteButtonComponent
                        onClick={() => setDeleteId(sub.id)}
                      />
                    </div>
                  </div>
                </li>
              );
            })}
          </ol>
        </div>

        <div className="add-trip-panel">
          <div className="subtitle">Add Subscription</div>

          {Object.keys(formik.errors).length > 0 && (
            <Alert severity={"error"}>
              {formik.errors[Object.keys(formik.errors)[0]]}
            </Alert>
          )}

          <form
            onSubmit={formik.handleSubmit}
            className="divAlignItemsOnly divColumn px-20 pb-40"
          >
            <div className="divColumn">
              <div className="divFlex">
                <div style={{ width: 200 }}>Name</div>
                <input type="text" {...formik.getFieldProps("name")} />
              </div>

              <div className="divFlex">
                <div style={{ width: 200 }}>Category</div>

                <select
                  {...formik.getFieldProps("category")}
                  id="category"
                  style={{ width: 210 }}
                >
                  <option value={""}>--</option>
                  {categories?.map((cat, i) => {
                    return (
                      <option key={i} value={cat}>
                        {cat}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="divFlex">
                <div style={{ width: 200 }}>SubCategory</div>
                <select
                  {...formik.getFieldProps("subcategory")}
                  style={{ width: 210 }}
                >
                  <option value={""}>--</option>
                  {subcategories?.map((cat, i) => {
                    return (
                      <option key={i} value={cat}>
                        {cat}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="divFlex">
                <div style={{ width: 200 }}>Amount</div>
                <input type="text" {...formik.getFieldProps("amount")} />
              </div>

              <div className="divFlex">
                <div style={{ width: 200 }}>Day of month?</div>
                <select
                  {...formik.getFieldProps("dayOfMonth")}
                  style={{ width: 210 }}
                >
                  <option value={""}>--</option>
                  {[
                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                    18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
                  ].map((date, i) => {
                    return (
                      <option key={i} value={date}>
                        {date}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="divFlex">
                <div style={{ width: 200 }}>Credit Card</div>
                <select
                  style={{ width: 210 }}
                  {...formik.getFieldProps("creditCard")}
                >
                  <option value={""}>--</option>
                  {accounts?.map((acc, i) => {
                    return (
                      <option key={i} value={acc.id}>
                        {acc.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="trips-footer">
              <input
                type="submit"
                className="fnxGreen"
                value="Add"
                onClick={formik.handleSubmit}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
