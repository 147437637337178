export default class FNXConnection {
  getURL() {
    return process.env.REACT_APP_DS_URL || "http://localhost:3001";
  }

  getServicesURL() {
    return this.getURL() + "/exhut-be";
  }
}

export const getGoogleClientId = () =>
  process.env.REACT_APP_EXHUT_GOOGLE_CLIENT_ID || "";
