import AuthConnection from "./AuthConnection";
import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export function authenticate(email, password) {
  const url = new AuthConnection().getURL() + "/auth";
  return executeService(url, "POST", { email, password }, 200, "json", false);
}

export function loginWithSSO(gmailToken) {
  const url = new AuthConnection().getURL() + "/auth/sso";
  return executeService(
    url,
    "POST",
    { token: gmailToken, type: "google" },
    200,
    "json",
    false,
  );
}

export function logout() {
  const url = new AuthConnection().getURL() + "/logout";
  return executeService(url, "POST", {}, 200, "restrict");
}

export function logoutAll() {
  const url = new AuthConnection().getURL() + "/logout?all=true";
  return executeService(url, "POST", {}, 200, "restrict");
}

export function deactivateAccount(username) {
  const url = new FNXConnection().getURL() + "/user/" + username;
  return executeService(url, "DELETE");
}
