import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  getExpensesTotal,
  getMiscExpensesTotal,
  setIntelligentMode,
} from "../../actions/expensesAction";
import {
  createExpenseMulti,
  getExpensesRelated,
} from "../../services/ExpenseService";
import { postSession } from "../../services/UserService";
import store from "../../store";
import FirstTimeLoginComponent from "../FirstTimeLoginComponent";
import GroupDisplayComponent from "../shared/GroupDisplayComponent";
import ModalDialogComponent from "../shared/ModalDialogComponent";
import SnackBarComponent from "../shared/snackbar-component/SnackBarComponent";
import CreateExpenseRowComponent from "./CreateExpenseRowComponent";
import NoAccountsComponent from "./NoAccountsComponent";
import RecentExpenseComponent from "./RecentExpenseComponent";
import RelatedExpenseComponent from "./RelatedExpenseComponent";
import CreateExpenseAIComponent from "./ai/CreateExpenseAIComponent";

class CreateExpenseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      subcategories: [],
      rows: [],
      statusMsg: undefined,
      relatedExpenses: [],
      intelligent: false,
      deleteRowIndex: -1,
      defaultDateForExpenseRow: undefined,
      defaultCategorySubCategoryForExpenseRow: undefined,
    };
    this.rowIndex = 0;
    this.insertTableRow = this.insertTableRow.bind(this);
    this.createExpenseHandler = this.createExpenseHandler.bind(this);
    this.getRelatedExpensesHandler = this.getRelatedExpensesHandler.bind(this);
    this.validateInputHandler = this.validateInputHandler.bind(this);
    this.handleTableRowDelete = this.handleTableRowDelete.bind(this);
    this.copyTableRowWithDate = this.copyTableRowWithDate.bind(this);
    this.copyTableRowWithCategorySubCategory =
      this.copyTableRowWithCategorySubCategory.bind(this);
  }

  async insertTableRow() {
    const rows = this.state.rows;
    rows.push(this.rowIndex++);
    try {
      await postSession();
      this.setState({
        rows,
        statusMsg: undefined,
        relatedExpenses: [],
        defaultDateForExpenseRow: undefined,
      });
      this.validateInputHandler();
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        console.error(err);
      }
    }
  }

  async copyTableRowWithDate() {
    const rows = this.state.rows;
    try {
      await postSession();
      const prevRowDateElement = document.querySelector(
        "#expenseDate" + rows[rows.length - 1],
      );
      const prevRowDateValue = prevRowDateElement.value;
      rows.push(this.rowIndex++);
      this.setState({
        rows,
        statusMsg: undefined,
        relatedExpenses: [],
        defaultDateForExpenseRow: {
          rowIndex: rows[rows.length - 1],
          value: prevRowDateValue,
        },
        defaultCategorySubCategoryForExpenseRow: undefined,
      });

      this.validateInputHandler();
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        console.error(err);
      }
    }
  }

  async copyTableRowWithCategorySubCategory() {
    const rows = this.state.rows;
    try {
      await postSession();
      const prevRowDateElement = document.querySelector(
        "#expenseDate" + rows[rows.length - 1],
      );
      const prevRowDateValue = prevRowDateElement.value;
      const prevRowCategoryElement = document.querySelector(
        "#category" + rows[rows.length - 1],
      );
      const prevRowCategoryElementValue = prevRowCategoryElement.value;
      const prevRowSubCategoryElement = document.querySelector(
        "#subcategory" + rows[rows.length - 1],
      );
      const prevRowSubCategoryElementValue = prevRowSubCategoryElement.value;
      rows.push(this.rowIndex++);
      this.setState({
        rows,
        statusMsg: undefined,
        relatedExpenses: [],
        defaultCategorySubCategoryForExpenseRow: {
          rowIndex: rows[rows.length - 1],
          category: prevRowCategoryElementValue,
          subcategory: prevRowSubCategoryElementValue,
        },
        defaultDateForExpenseRow: {
          rowIndex: rows[rows.length - 1],
          value: prevRowDateValue,
        },
      });

      this.validateInputHandler();
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        console.error(err);
      }
    }
  }

  deleteTableRow(index) {
    const rows = this.state.rows;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i] === index) {
        rows.splice(i, 1);
      }
    }
    this.setState({ rows, relatedExpenses: [], deleteRowIndex: -1 });
    this.validateInputHandler();
  }

  handleTableRowDelete(index, confirm) {
    if (confirm) {
      this.setState({ deleteRowIndex: index });
    } else {
      this.deleteTableRow(index);
    }
  }

  validateInputHandler() {
    const createButton = document.querySelector("#createBtn");
    const erroredElements = document.querySelectorAll(
      "*.error-background:not([disabled])",
    );

    createButton.disabled = erroredElements.length > 0;
  }

  async createExpenseHandler() {
    const user = this.props.user;
    const maxRows = this.rowIndex;
    const expenseArrayPayload = [];
    for (let i = 0; i < maxRows + 1; i++) {
      // loop though maxRow times in the DOM to check the fields using the index.
      // Find if one of the input exists, it means the expense row exists.
      const categoryElem = document.querySelector("#category" + i);
      if (categoryElem) {
        const category = categoryElem.value;
        const subcategory = document.querySelector("#subcategory" + i).value;
        const expenseDate = document.querySelector("#expenseDate" + i).value;
        const amount = document.querySelector("#amount" + i).value;
        const comments = document.querySelector("#comments" + i).value;
        const creditcard = document.querySelector("#creditcard" + i).value;
        const miscOnly = document.querySelector("#miscOnly" + i).checked;

        const dateSplits = expenseDate.split("/");

        const monthComponent =
          user.country_code === "USA" ? dateSplits[0] : dateSplits[1];
        const dateComponent =
          user.country_code === "USA" ? dateSplits[1] : dateSplits[0];
        const yearComponent = dateSplits[2];

        if (miscOnly) {
          expenseArrayPayload.push({
            date: new Date(yearComponent, monthComponent - 1, dateComponent),
            amount,
            name: comments,
            type: "misc",
          });
        } else {
          expenseArrayPayload.push({
            category,
            subcategory,
            date: new Date(yearComponent, monthComponent - 1, dateComponent),
            amount,
            creditcard,
            comments,
          });
        }
      }
    }
    try {
      await createExpenseMulti(expenseArrayPayload);
      // these two should be called out to update the total values.
      store.dispatch(getExpensesTotal());
      store.dispatch(getMiscExpensesTotal());
      this.rowIndex = 0; // reset this count to zero so when you add next time, the components will be 0.
      this.setState({
        statusMsg: {
          type: "success",
          text: "All expenses created successfully.",
        },
        rows: [],
        relatedExpenses: [],
      });
    } catch (err) {
      if (err.status === 210) {
        const errorMessageResp = err.message;
        if (errorMessageResp && Array.isArray(errorMessageResp)) {
          const successExpenseIndices = [];

          // pull out success messages to remove those items from the table rows.
          for (let i = 0; i < errorMessageResp.length; i++) {
            if (errorMessageResp[i].status === 200) {
              successExpenseIndices.push(i);
            }
          }

          for (const i of successExpenseIndices) {
            this.deleteTableRow(i);
          }

          this.setState({
            statusMsg: {
              type: "error",
              text: `The following expenses were not created.`,
            },
          });
        } else {
          this.setState({
            statusMsg: {
              type: "error",
              text: `One or more of the expenses was not created!`,
            },
          });
        }
      } else {
        this.setState({
          statusMsg: {
            type: "error",
            text: "One or more expenses failed to create. Please try after sometime or contact the admin.",
          },
        });
      }
    }
  }

  async getRelatedExpensesHandler(i) {
    const category = document.querySelector("#category" + i).value;
    const subcategory = document.querySelector("#subcategory" + i).value;
    const expenseDate = document.querySelector("#expenseDate" + i).value;
    try {
      const dateSplits = expenseDate.split("/");
      const month =
        this.props.user.country_code === "USA" ? dateSplits[0] : dateSplits[1];
      const year = dateSplits[2];
      const relatedExpenses = await getExpensesRelated(
        this.props.groupid,
        year,
        month,
        category,
        subcategory,
      );
      this.setState({ relatedExpenses });
    } catch (err) {
      console.error(err);
      if (err.status === 401) {
        this.props.doLogout();
      } else {
      }
    }
  }

  render() {
    if (this.props.accounts?.length === 0) {
      return <NoAccountsComponent />;
    }

    if (!this.props.groupid || this.props.groups.length === 0) {
      return <FirstTimeLoginComponent />;
    }

    if (this.props.intelligentMode === true) {
      return (
        <CreateExpenseAIComponent
          user={this.props.user}
          doLogout={this.props.doLogout}
          onLegacy={() => store.dispatch(setIntelligentMode(false))}
        />
      );
    }

    return (
      <div className="margin-center">
        <GroupDisplayComponent />

        <div className="title">Add Expense</div>

        <div style={{ marginTop: "3%", textAlign: "center", minHeight: "1em" }}>
          {this.state.statusMsg && (
            <span
              id="createExpenseError"
              className={
                this.state.statusMsg.type === "error"
                  ? "errorMsg"
                  : "successMsg"
              }
            >
              {this.state.statusMsg.text}
            </span>
          )}
        </div>

        <div>
          {this.state.deleteRowIndex >= 0 && (
            <ModalDialogComponent
              title="Delete row"
              message="You have unsaved data. Are you sure you want to delete this row?"
              onSuccess={() => this.deleteTableRow(this.state.deleteRowIndex)}
              onCancel={() => this.setState({ deleteRowIndex: -1 })}
            />
          )}
        </div>

        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <div className="divFlex">
              <input
                type="button"
                className="plusButton"
                onClick={this.insertTableRow}
                title="Add New Row"
              />
              {this.state.rows.length > 0 && (
                <>
                  <input
                    type="button"
                    className="plusDateButton"
                    onClick={this.copyTableRowWithDate}
                    title="Copy date from previous row."
                  />
                  <input
                    type="button"
                    className="plusCategoryButton"
                    onClick={this.copyTableRowWithCategorySubCategory}
                    title="Copy category & date from prev row."
                  />
                </>
              )}
            </div>
            <SnackBarComponent
              message="Click here to add expenses."
              id="snackbar-add-expense"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          ></div>
        </div>

        <div style={{ marginTop: "1%" }} hidden={this.state.rows.length == 0}>
          <table id="expensesTable" style={{ borderSpacing: "0.5em" }}>
            <thead>
              <tr>
                <th> </th>
                <th> Misc </th>
                <th>Category </th>
                <th> SubCategory </th>
                <th> Date </th>
                <th> Amount </th>
                <th> Comments </th>
                <th> Account </th>
              </tr>
            </thead>

            <tbody>
              {this.state.rows.map((index) => {
                return (
                  <CreateExpenseRowComponent
                    key={index}
                    index={index}
                    cards={this.props.accounts}
                    categoriesMap={this.props.categoriesMap}
                    user={this.props.user}
                    onDeleteRow={(index, confirm) =>
                      this.handleTableRowDelete(index, confirm)
                    }
                    onFetchRelatedExpenses={(index) =>
                      this.getRelatedExpensesHandler(index)
                    }
                    onInputChange={this.validateInputHandler}
                    defaultDate={
                      index === this.state.defaultDateForExpenseRow?.rowIndex
                        ? this.state.defaultDateForExpenseRow?.value
                        : undefined
                    }
                    defaultCategory={
                      index ===
                      this.state.defaultCategorySubCategoryForExpenseRow
                        ?.rowIndex
                        ? this.state.defaultCategorySubCategoryForExpenseRow
                            ?.category
                        : undefined
                    }
                    defaultSubCategory={
                      index ===
                      this.state.defaultCategorySubCategoryForExpenseRow
                        ?.rowIndex
                        ? this.state.defaultCategorySubCategoryForExpenseRow
                            ?.subcategory
                        : undefined
                    }
                  />
                );
              })}
            </tbody>
          </table>

          <div className="table-footer">
            <input
              type="button"
              id="createBtn"
              value="Create"
              className="fnxGreen"
              onClick={this.createExpenseHandler}
            />
          </div>
        </div>

        <div>
          <RelatedExpenseComponent
            relatedExpenses={this.state.relatedExpenses}
          />
        </div>

        <div style={{ marginTop: "15%" }}>
          {this.state.relatedExpenses.length === 0 &&
            this.state.rows.length < 10 && <RecentExpenseComponent />}
        </div>
      </div>
    );
  }
}

CreateExpenseComponent.propTypes = {
  doLogout: PropTypes.func.isRequired,
  user: PropTypes.any.isRequired,
  intelligentMode: PropTypes.bool,
  accounts: PropTypes.array,
  categoriesMap: PropTypes.object,
};

function mapStateToProps(state) {
  const { user, expense, accounts, categoriesMap } = state.app;
  const { groupid, groups } = state.group;
  return {
    user,
    accounts,
    intelligentMode: expense?.intelligentMode,
    categoriesMap,
    groups,
    groupid,
  };
}

export default connect(mapStateToProps)(CreateExpenseComponent);
