import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export async function validatePhrase(phrase) {
  const url = `${new FNXConnection().getURL()}/expense/ai/validate`;
  return executeService(url, "POST", { phrase }, 200, "json");
}

export async function createExpenseAI(phrase, username) {
  const url = `${new FNXConnection().getURL()}/expense/ai`;
  return executeService(url, "PUT", { phrase, username }, 200, "restrict");
}
