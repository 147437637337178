import React from "react";
import {
  getTripById,
  getTripExpenses,
  deleteTrip,
  getTripExpensesTotal,
  updateTrip,
} from "../../services/TripService";
import { deleteExpense } from "../../services/ExpenseService";
import ModalDialogComponent from "../shared/ModalDialogComponent";
import PropTypes from "prop-types";
import "./TripsComponent.css";
import CalendarInputComponent from "../shared/CalendarInputComponent";
import { getLocalizedDate } from "../../helpers/DateHelper";
import { getCurrencySymbol } from "../../helpers/CurrencyHelper";
import { connect } from "react-redux";
import CreateTripExpenseDialogComponent from "./CreateTripExpenseDialogComponent";
import { DeleteButtonComponent } from "../shared/ButtonComponent";

class TripsFullViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trip: undefined,
      tripExpenses: [],
      statusMsg: undefined,
      tripStatusMsg: undefined,
      deleteTripId: undefined,
      deleteTripExpenseId: undefined,
      tripNameEditText: "",
      tripDestinationEditText: "",
      tripFromDateEditText: "",
      tripToDateEditText: "",
      isInputEdited: false,
      createMode: false,
    };
    this.deleteTripHandler = this.deleteTripHandler.bind(this);
    this.updateTripHandler = this.updateTripHandler.bind(this);
    this.inflateTrip = this.inflateTrip.bind(this);
    this.fetchTripTotal = this.fetchTripTotal.bind(this);
  }

  async componentDidMount() {
    await this.inflateTrip();
  }

  async inflateTrip() {
    try {
      const trip = await getTripById(this.props.groupid, this.props.editTripId);
      this.setState({
        trip,
        tripNameEditText: trip.name,
        tripDestinationEditText: trip.destination,
        tripFromDateEditText: getLocalizedDate(trip.fromdate, this.props.user),
        tripToDateEditText: getLocalizedDate(trip.todate, this.props.user),
      });
      const tripExpenses = await getTripExpenses(this.props.groupid, trip.id);
      this.setState({ tripExpenses });
      await this.fetchTripTotal();
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        console.error(err);
        this.setState({
          statusMsg: {
            text: "Whoops! Something went wrong. Please try after sometime.",
            type: "error",
          },
        });
      }
    }
  }

  async fetchTripTotal() {
    try {
      const totalRes = await getTripExpensesTotal(
        this.props.groupid,
        this.props.editTripId,
      );
      this.setState({ tripExpenseTotal: totalRes.total });
    } catch (err) {
      this.setState({
        statusMsg: {
          text: "Whoops! Could not calculate trip total. Please try after sometime.",
          type: "error",
        },
      });
    }
  }

  async getExpensesForTrip() {
    try {
      const tripExpenses = await getTripExpenses(
        this.props.groupid,
        this.props.editTripId,
      );
      this.setState({ tripExpenses });
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        console.error(err);
      }
    }
  }

  async deleteTripHandler() {
    try {
      await deleteTrip(this.state.trip.id);
      this.props.handleBack();
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        this.setState({
          tripStatusMsg: {
            text: "Whoops! Something went wrong. Please try after sometime.",
            type: "error",
          },
          deleteTripId: undefined,
        });
      }
    }
  }

  async deleteTripExpenseHandler() {
    try {
      await deleteExpense(this.state.deleteTripExpenseId);
      await this.fetchTripTotal();
      this.setState({
        deleteTripExpenseId: undefined,
        statusMsg: {
          type: "success",
          text: "Expense successfully removed for this trip.",
        },
      });
      await this.getExpensesForTrip();
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        console.error(err);
        this.setState({
          statusMsg: {
            text: "Whoops! Something went wrong. Please try after sometime.",
            type: "error",
          },
        });
      }
    }
  }

  async updateTripHandler() {
    try {
      await updateTrip(
        this.state.trip.id,
        this.props.user.username,
        this.state.tripNameEditText,
        this.state.tripFromDateEditText,
        this.state.tripToDateEditText,
        this.state.tripDestinationEditText,
      );
      this.setState({
        tripStatusMsg: {
          text: "Trip successfully updated.",
          type: "success",
        },
        accountNameEditText: "",
      });
    } catch (err) {
      console.error(err);
      if (err.status === 401) {
        this.props.doLogout();
      } else if (err.status === 400) {
        this.setState({
          tripStatusMsg: {
            text: "Whoops! Invalid input. Please try again.",
            type: "error",
          },
        });
      } else {
        this.setState({
          tripStatusMsg: {
            text: "Whoops! Something unexpected happened. Please try again.",
            type: "error",
          },
        });
      }
    }
  }

  render() {
    return (
      <div className="margin-center">
        <div className="title">Trip ({this.state.trip?.name})</div>

        <div style={{ height: "2em", textAlign: "center", margin: "0px 0px" }}>
          {this.state.statusMsg && (
            <span
              className={
                this.state.statusMsg.type === "success"
                  ? "successMsg"
                  : "errorMsg"
              }
            >
              {this.state.statusMsg.text}
            </span>
          )}
        </div>

        <div>
          {this.state.createMode && (
            <CreateTripExpenseDialogComponent
              user={this.props.user}
              doLogout={this.props.doLogout}
              onSuccess={async () => {
                this.setState({ createMode: false });
                await this.getExpensesForTrip();
                await this.fetchTripTotal();
              }}
              onFailure={() => this.setState({ createMode: false })}
              onCancel={() => this.setState({ createMode: false })}
              tripid={this.props.editTripId}
            />
          )}
        </div>

        <div>
          {this.state.deleteTripId && (
            <ModalDialogComponent
              title={"Delete Trip"}
              message={
                "Are you sure you want to delete this trip?" +
                " The expenses associated with this trip will also be removed."
              }
              onSuccessTitle={"Yes"}
              onCancelTitle={"No"}
              onSuccess={() => this.deleteTripHandler()}
              onCancel={() => this.setState({ deleteTripId: undefined })}
            />
          )}

          {this.state.deleteTripExpenseId && (
            <ModalDialogComponent
              title={"Delete Trip Expense"}
              message={"Are you sure?"}
              onSuccessTitle={"Yes"}
              onCancelTitle={"No"}
              onSuccess={() => this.deleteTripExpenseHandler()}
              onCancel={() => this.setState({ deleteTripExpenseId: undefined })}
            />
          )}
        </div>

        <div>
          <a onClick={this.props.handleBack} className="hyperlink">
            Back
          </a>
        </div>

        <div className="trip-page">
          <div className="trip-page-left-panel">
            <div
              className="analysis-tile"
              style={{ maxWidth: "80%", margin: "0px 20px" }}
            >
              {this.state.tripStatusMsg && (
                <div className="divCenterAlign">
                  <span
                    className={
                      this.state.tripStatusMsg.type === "success"
                        ? "successMsg"
                        : "errorMsg"
                    }
                  >
                    {this.state.tripStatusMsg.text}
                  </span>
                </div>
              )}

              <div className="analysis-tile-body" id="update-trip-panel">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <span style={{ fontSize: "x-large" }}>Total</span>
                      </th>
                      <th>
                        <span
                          id="trip-expense-total"
                          style={{ fontSize: "x-large" }}
                        >
                          {getCurrencySymbol(this.props.user)}{" "}
                          {this.state.tripExpenseTotal}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          {" "}
                          Trip name <sup> * </sup>{" "}
                        </span>
                      </td>
                      <td>
                        <input
                          type="text"
                          maxLength={40}
                          id="tripname"
                          value={this.state.tripNameEditText}
                          onChange={(e) =>
                            this.setState(
                              { tripNameEditText: e.target.value },
                              () => this.setState({ isInputEdited: true }),
                            )
                          }
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span> Destination </span>
                      </td>
                      <td>
                        <input
                          type="text"
                          maxLength={40}
                          id="tripdest"
                          value={this.state.tripDestinationEditText}
                          onChange={(e) =>
                            this.setState(
                              { tripDestinationEditText: e.target.value },
                              () => this.setState({ isInputEdited: true }),
                            )
                          }
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>
                          {" "}
                          From Date <sup> * </sup>
                        </span>
                      </td>
                      <td>
                        <CalendarInputComponent
                          id="tripFrom"
                          value={this.state.tripFromDateEditText}
                          onChange={(dateText) =>
                            this.setState(
                              { tripFromDateEditText: dateText },
                              () => this.setState({ isInputEdited: true }),
                            )
                          }
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>
                          {" "}
                          To Date <sup> * </sup>{" "}
                        </span>
                      </td>
                      <td>
                        <CalendarInputComponent
                          id="tripTo"
                          value={this.state.tripToDateEditText}
                          onChange={(dateText) =>
                            this.setState(
                              { tripToDateEditText: dateText },
                              () => this.setState({ isInputEdited: true }),
                            )
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {this.state.trip?.username === this.props.user.username && (
                <div className="trips-footer">
                  <input
                    type="button"
                    className="fnxRed"
                    value="Delete"
                    onClick={() =>
                      this.setState({ deleteTripId: this.state.trip.id })
                    }
                  />

                  <input
                    type="button"
                    className="fnxGreen"
                    value="Update"
                    onClick={this.updateTripHandler}
                    disabled={!this.state.isInputEdited}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="trip-page-right-panel">
            <div>
              <a
                className="hyperlink"
                onClick={() => this.setState({ createMode: true })}
              >
                {" "}
                Add Expenses To Trip
              </a>
            </div>

            <ol>
              {this.state.tripExpenses.map((exp, i) => {
                return (
                  <li key={i}>
                    <div className="trip-page-expense-row">
                      <div style={{ flex: 1 }}>
                        <DeleteButtonComponent
                          onClick={() =>
                            this.setState({ deleteTripExpenseId: exp.id })
                          }
                        />
                      </div>

                      <div style={{ flex: 1 }}>
                        <img src={"/assets/icons/banknote.svg"} width="50px" />
                      </div>

                      <div style={{ flex: 2 }}>
                        <span> {exp.comments}</span>
                      </div>

                      <div style={{ flex: 2 }}>
                        <span>
                          {" "}
                          {getLocalizedDate(exp.expensedate, this.props.user)}
                        </span>
                      </div>

                      <div style={{ flex: 1 }}>
                        <span>
                          {" "}
                          {getCurrencySymbol(this.props.user) +
                            " " +
                            exp.amount}
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

TripsFullViewComponent.propTypes = {
  user: PropTypes.any.isRequired,
  doLogout: PropTypes.func.isRequired,
  editTripId: PropTypes.string,
  handleBack: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { editTripId } = state.trip;
  const { user } = state.app;
  const { groupid } = state.group;
  return { editTripId, user, groupid };
}

export default connect(mapStateToProps)(TripsFullViewComponent);
