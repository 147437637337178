import {
  ACCOUNTS_ACTION_STATUS,
  ACTION_EDIT_ACCOUNT,
} from "../actions/accountsAction";

const initState = {
  accounts: [],
};
export default function accounts(state = initState, action) {
  switch (action.type) {
    case ACCOUNTS_ACTION_STATUS:
      return {
        ...state,
        accounts_action_status: action.payload,
      };
    case ACTION_EDIT_ACCOUNT:
      return {
        ...state,
        editAccount: action.payload,
      };
    default:
      return state;
  }
}
