import React from "react";
import PropTypes from "prop-types";

/**
 * Modal dialog component.
 *
 * title {String} - title for the dialog.
 *
 * message {String} - message body
 *
 * renderBody {Function} - render body component.
 *
 * id {String} - id of the dialog
 *
 * onSuccessTitle {String} - Title for button that performs a success operation.
 * For example, "Yes", "Confirm". Default is "Yes".
 *
 * onSuccess {Function)} - Callback for onSuccess operation.
 *
 * onCancelTitle {String} -Title for cancel button. For example, "No", "Cancel". Default is "No".
 *
 * onCancel {Function} - Callback for onCancel
 *
 * onBackdrop {Function} - Callback for onbackdrop press. If not provided, onCancel will be used.
 *
 */
export default class ModalDialogComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="modal" id={this.props.id}>
        <div className="modal-content">
          <span
            className="close"
            onClick={this.props.onBackdrop ?? this.props.onCancel}
          >
            &times;
          </span>
          <div style={{ marginTop: "1em", textAlign: "center" }}>
            <h2> {this.props.title} </h2>
          </div>

          <div style={{ textAlign: "center", marginTop: "0.5em" }}>
            <p> {this.props.message} </p>
            {this.props.renderBody && this.props.renderBody()}
          </div>
          <div style={{ marginTop: "3em", textAlign: "center" }}>
            <input
              type="button"
              value={this.props.onCancelTitle || "No"}
              className="fnxGrey"
              style={{ color: "var(--fnx-white)" }}
              onClick={this.props.onCancel}
            />

            <input
              type="button"
              style={{ marginLeft: "3em " }}
              value={this.props.onSuccessTitle || "Yes"}
              className="fnxRed"
              onClick={this.props.onSuccess}
            />
          </div>
        </div>
      </div>
    );
  }
}

ModalDialogComponent.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  renderBody: PropTypes.any,
  onSuccessTitle: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  onCancelTitle: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  error: PropTypes.string,
  onBackdrop: PropTypes.func,
};
