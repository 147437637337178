import React from "react";
import {
  getIncomeForGroup,
  getIncomeForUser,
  deleteIncome,
} from "../../../services/IncomeService";
import PropTypes from "prop-types";
import RadioButtonComponent from "../../shared/RadioButtonComponent";
import "./IncomeSettingsComponent.css";
import store from "../../../store";
import { getSettings, updateSettings } from "../../../actions/settingsAction";
import IncomeRowComponent from "./IncomeRowComponent";
import AddIncomeComponent from "./AddIncomeComponent";
import SpinnerComponent from "../../shared/SpinnerComponent";
import {
  getGroupIncomeTotal,
  getUserIncomeTotal,
} from "../../../actions/incomeAction";
import { connect } from "react-redux";

class IncomeSettingsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      incomesByGroup: [],
      incomesByUser: [],
      incomeEditText: "",
      mode: "viewIncome",
      isLoading: false,
    };
    this.fetchSettings = this.fetchAllData.bind(this);
  }

  async componentDidMount() {
    await this.fetchAllData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.groupid !== prevProps.groupid) {
      this.fetchAllData();
    }
  }

  async fetchAllData() {
    try {
      this.setState({ isLoading: true });
      store.dispatch(getSettings());
      const incomesByUser = await getIncomeForUser(
        this.props.groupid,
        this.props.user.username,
      );
      this.setState({ incomesByUser: incomesByUser.incomes });
      const incomesByGroup = await getIncomeForGroup(this.props.groupid);
      this.setState({ incomesByGroup: incomesByGroup.incomes });
      setTimeout(() => this.setState({ isLoading: false }), 1000);
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        console.error(err);
      }
      this.setState({ isLoading: false });
    }
  }

  updateSettings(payload) {
    store.dispatch(updateSettings(payload));
  }

  async deleteIncomeHandler(id) {
    try {
      await deleteIncome(this.props.groupid, this.props.user.username, id);
      store.dispatch(getUserIncomeTotal());
      store.dispatch(getGroupIncomeTotal());
      await this.fetchAllData();
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        console.error(err);
      }
    }
  }

  renderIncomePanel() {
    if (this.state.isLoading) {
      return <SpinnerComponent size="medium" />;
    }
    if (this.state.incomesByGroup.length) {
      return this.state.incomesByGroup.map((income, index) => {
        return (
          <IncomeRowComponent
            key={index}
            income={income}
            onDeleteClick={(id) => this.deleteIncomeHandler(id)}
          />
        );
      });
    } else {
      return (
        <div className="divCenterAlign">
          <span className="errorMsg">
            No data found. Please Click Add to add income.
          </span>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="margin-center">
        <div className="title">Income Settings</div>

        <div className="preferences-container">
          <div className="preferences-left-pane">
            <div className="subtitle">
              <span>Preferences </span>
            </div>

            <div className="preferences-row">
              <div className="preferences-row-title">
                <div className="preferences-row-title-heading">
                  <span>Include Misc Expense in Total</span>
                </div>

                <RadioButtonComponent
                  id="includeMiscInTotal"
                  onChange={(e) =>
                    this.updateSettings({
                      include_misc_expense_in_total: e.target.checked,
                    })
                  }
                  checked={this.props.include_misc_expense_in_total}
                />
              </div>
              <div className="preferences-row-desc">
                This will add misc expenses to the monthly total shown at the
                home page.
              </div>
            </div>

            <div className="preferences-row">
              <div className="preferences-row-title">
                <div className="preferences-row-title-heading">
                  <span>Include Group Income</span>
                </div>
                <div>
                  <RadioButtonComponent
                    id="includeGroupIncome"
                    onChange={(e) =>
                      this.updateSettings({
                        include_group_income: e.target.checked,
                      })
                    }
                    checked={this.props.include_group_income}
                  />
                </div>
              </div>
              <div className="preferences-row-desc">
                This will include other user&lsquo;s income and use it for
                savings calculation.
              </div>
            </div>

            <div className="preferences-row">
              <div className="preferences-row-title">
                <div className="preferences-row-title-heading">
                  <span>Include Misc Expense for Estimated Savings</span>
                </div>
                <div>
                  <RadioButtonComponent
                    id="includeMiscExpensesInSavings"
                    onChange={(e) =>
                      this.updateSettings({
                        include_misc_expense_for_savings: e.target.checked,
                      })
                    }
                    checked={this.props.include_misc_expense_for_savings}
                  />
                </div>
              </div>
              <div className="preferences-row-desc">
                This will include misc expenses along with monthly expenses for
                savings calculation.
              </div>
            </div>
          </div>
          <div className="preferences-right-pane">
            {this.state.mode === "viewIncome" && (
              <div>
                <div
                  style={{
                    justifyContent: "space-around",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <span className="subtitle">All Income(s)</span>
                  <a
                    style={{ color: "green" }}
                    className="hyperlink"
                    onClick={() => this.setState({ mode: "addIncome" })}
                  >
                    Add
                  </a>
                </div>

                {this.renderIncomePanel()}
              </div>
            )}

            {this.state.mode === "addIncome" && (
              <AddIncomeComponent
                onBackHandler={async () => {
                  this.setState({ mode: "viewIncome" });
                  await this.fetchAllData();
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

IncomeSettingsComponent.propTypes = {
  user: PropTypes.any.isRequired,
  doLogout: PropTypes.func.isRequired,
  include_misc_expense_for_savings: PropTypes.bool,
  include_misc_expense_in_total: PropTypes.bool,
  include_group_income: PropTypes.bool,
};

function mapStateToProps(state) {
  /* eslint-disable camelcase */
  const {
    include_group_income,
    include_misc_expense_in_total,
    include_misc_expense_for_savings,
  } = state.settings;
  const { groupid } = state.group;
  return {
    include_group_income,
    include_misc_expense_for_savings,
    include_misc_expense_in_total,
    groupid,
  };
}

export default connect(mapStateToProps)(IncomeSettingsComponent);
