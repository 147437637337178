import { Dialog } from "@mui/material";

export default function ConfirmationDialog({
  open,
  onClose,
  title,
  onProceed,
}) {
  return (
    <Dialog open={open} onClose={onClose} id="confirm-dialog">
      <div className="p-40">
        <h2 className="divCenterAlign">{title} </h2>
        <div className="divCenterAlign">
          <p style={{ margin: "0px 20px" }}>
            Are you sure you would like to proceed?
          </p>
        </div>

        <div className="divSpread" style={{ marginTop: 40 }}>
          <button className="fnxGrey" onClick={onClose}>
            {"No"}
          </button>
          <button className={"fnxRed"} onClick={onProceed}>
            {"Yes"}
          </button>
        </div>
      </div>
    </Dialog>
  );
}
