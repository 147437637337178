export const ACTION_SET_COUNTRIES = "SET_COUNTRIES";
export const ACTION_GET_COUNTRIES = "GET_COUNTRIES";

export function setCountries(payload) {
  return {
    type: ACTION_SET_COUNTRIES,
    payload,
  };
}

export function getCountries() {
  return {
    type: ACTION_GET_COUNTRIES,
  };
}
