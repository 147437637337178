import React from "react";
import TextInputComponent from "../shared/TextInputComponent";
import "./SearchComponent.css";
import { searchExpense } from "../../services/ExpenseService";
import RadioButtonComponent from "../shared/RadioButtonComponent";
import SpinnerComponent from "../shared/SpinnerComponent";
import PropTypes from "prop-types";
import { getLocalizedDate } from "../../helpers/DateHelper";
import { roundTo2Decimals } from "../../helpers/NumberHelper";
import { getCurrencySymbol } from "../../helpers/CurrencyHelper";

export default class SearchComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      miscOnly: false,
      tripOnly: false,
      allTypes: true,
      searchResults: [],
      errorMsg: undefined,
      isLoading: false,
      calcTotal: false,
      total: 0,
    };
    this.searchExpenseHandler = this.searchExpenseHandler.bind(this);
    this.miscOnlyOnChangeHandler = this.miscOnlyOnChangeHandler.bind(this);
    this.tripOnlyOnChangeHandler = this.tripOnlyOnChangeHandler.bind(this);
    this.handleSortResults = this.handleSortResults.bind(this);
  }

  async searchExpenseHandler() {
    try {
      this.setState({ isLoading: true });
      const searchResults = await searchExpense(
        this.props.user.groupid,
        this.state.searchText,
        this.state.miscOnly,
        this.state.tripOnly,
      );
      let total = 0;
      if (this.state.calcTotal) {
        for (const e of searchResults) {
          total += e.amount;
        }
      }
      this.setState({
        searchResults,
        total: roundTo2Decimals(total),
        errorMsg:
          searchResults.length === 0 ? "Sorry, no results found!" : undefined,
      });
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        console.error(err);
        this.setState({
          errorMsg: "Whoops! Something unexpected happened. Please try again.",
        });
      }
    }
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
  }

  miscOnlyOnChangeHandler() {
    this.setState({ miscOnly: !this.state.miscOnly, tripOnly: false });
  }

  tripOnlyOnChangeHandler() {
    this.setState({ tripOnly: !this.state.tripOnly, miscOnly: false });
  }

  handleSortResults(e) {
    const results = this.state.searchResults;
    if (e.target.value === "amounthtol") {
      results.sort((a, b) => b.amount - a.amount);
    } else if (e.target.value === "amountltoh") {
      results.sort((a, b) => a.amount - b.amount);
    } else {
      // by date
      results.sort(
        (a, b) =>
          new Date(b.expensedate).getTime() - new Date(a.expensedate).getTime(),
      );
    }
    this.setState({ searchResults: results });
  }

  render() {
    return (
      <div>
        <div className="title">Search</div>

        <div className="search-panel">
          <div className="divFlex">
            <div>
              <TextInputComponent
                type="text"
                id="searchtext"
                width="300px"
                onChange={(e) => this.setState({ searchText: e.target.value })}
                value={this.state.searchText}
              />
            </div>
            <div>
              <input
                id="searchExpense"
                type="button"
                value="Search"
                className="fnxGreen"
                onClick={this.searchExpenseHandler}
              />
            </div>
          </div>
          <div className="search-panel-options">
            <div className="mediumText divCenterAlign">
              Calculate Total
              <input
                type="checkbox"
                checked={this.state.calcTotal}
                onChange={() =>
                  this.setState({ calcTotal: !this.state.calcTotal })
                }
              />
            </div>
            <div className="mediumText divCenterAlign">
              Misc Only
              <RadioButtonComponent
                size="small"
                checked={this.state.miscOnly}
                onChange={this.miscOnlyOnChangeHandler}
              />
            </div>
            <div className="mediumText divCenterAlign">
              Trip Expenses only
              <RadioButtonComponent
                size="small"
                checked={this.state.tripOnly}
                onChange={this.tripOnlyOnChangeHandler}
              />
            </div>
          </div>
        </div>

        <div className="search-results">
          <div className="search-results-table-of-contents">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="divCenter">
                {this.state.total > 0 && !this.state.isLoading && (
                  <span className="boldText">
                    {" "}
                    Total: {getCurrencySymbol(this.props.user)}{" "}
                    {this.state.total}{" "}
                  </span>
                )}
              </div>

              <div className="search-results-sort-panel">
                {this.state.searchResults.length > 0 && (
                  <>
                    <span> Sort By </span>
                    <select
                      onChange={(e) => this.handleSortResults(e)}
                      defaultValue={"date"}
                    >
                      <option value={"date"}>Date</option>
                      <option value={"amounthtol"}>Amount (High to Low)</option>
                      <option value={"amountltoh"}>Amount (Low to High)</option>
                    </select>
                  </>
                )}
              </div>
            </div>

            <table className="exhutTable">
              <thead>
                <tr>
                  <th></th>
                  <th>Type</th>
                  <th>Category</th>
                  <th>SubCategory</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Comments</th>
                </tr>

                {!this.state.isLoading &&
                  this.state.searchResults.map((l, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td></td>
                        <td>{l.category || ""}</td>
                        <td>{l.subcategory || ""}</td>
                        <td>
                          {getLocalizedDate(l.expensedate, this.props.user)}
                        </td>
                        <td>{l.amount}</td>
                        <td>{l.comments}</td>
                      </tr>
                    );
                  })}
              </thead>
            </table>

            {this.state.isLoading && <SpinnerComponent size="medium" />}

            {this.state.errorMsg && !this.state.isLoading && (
              <div className="divCenter">
                <span className="errorMsg">{this.state.errorMsg}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SearchComponent.propTypes = {
  user: PropTypes.any.isRequired,
  doLogout: PropTypes.func.isRequired,
};
