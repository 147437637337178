import React from "react";
import PropTypes from "prop-types";

/**
 * Radio button component (big blue radio button).
 *
 * id {string} : id attribute
 *
 * onChange {function} : callback for on change.
 *
 * checked {boolen} : true if to be checked.
 *
 */
export default class RadioButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    const { container, handle } = this._geSize(this.props.size);
    this.containerSize = container;
    this.handleSize = handle;
  }

  _geSize(size) {
    switch (size) {
      case "small":
        return {
          container: {
            width: "30px",
            height: "16px",
          },
          handle: {
            width: "10px",
            height: "10px",
            bottom: "3px",
          },
        };
      default:
        return {
          container: {
            width: "60px",
            height: "34px",
          },
          handle: {
            width: "26px",
            height: "26px",
            bottom: "4px",
          },
        };
    }
  }

  render() {
    return (
      <label className="switch" style={this.containerSize} id={this.props.id}>
        <input
          type="checkbox"
          onChange={this.props.onChange}
          checked={this.props.checked}
        />
        <span className="slider round">
          <div style={this.handleSize}></div>
        </span>
      </label>
    );
  }
}

RadioButtonComponent.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  size: PropTypes.string,
};
