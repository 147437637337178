import {
  ACTION_SET_EDIT_TRIP_ID,
  ACTION_SET_TRIPS,
} from "../actions/tripAction";

const initState = {};

export default function trip(state = initState, action) {
  switch (action.type) {
    case ACTION_SET_EDIT_TRIP_ID:
      return {
        ...state,
        editTripId: action.payload,
      };
    case ACTION_SET_TRIPS:
      return {
        ...state,
        trips: action.payload,
      };
    default:
      return state;
  }
}
