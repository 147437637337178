import app from "./reducers/appReducer";
import { ACTION_LOGOUT_USER } from "./actions/userAction";
import expense from "./reducers/expenseReducer";
import settings from "./reducers/settingsReducer";
import income from "./reducers/incomeReducer";
import savings from "./reducers/savingsReducer";
import accounts from "./reducers/accountsReducer";
import trip from "./reducers/tripReducer";
import ledger from "./reducers/ledgerReducer";
import group from "./reducers/groupsReducer";
import loans from "./reducers/loansReducer";
import transactions from "./reducers/transactionsReducer";

import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import expensesSaga from "./sagas/expensesSaga";
import expenseAISaga from "./sagas/expenseAISaga";
import settingsSaga from "./sagas/settingsSaga";
import incomeSaga from "./sagas/incomeSaga";
import savingsSaga from "./sagas/savingsSaga";
import accountsSaga from "./sagas/accountsSaga";
import categoriesSaga from "./sagas/categoriesSaga";
import userSaga from "./sagas/userSaga";
import groupsSaga from "./sagas/groupsSaga";
import loansSaga from "./sagas/loansSaga";
import transactionsSaga from "./sagas/transactionsSaga";
import tripsSaga from "./sagas/tripsSaga";
import ledgersSaga from "./sagas/ledgersSaga";

const sagaMiddleware = createSagaMiddleware();

const rootReducers = combineReducers({
  app,
  expense,
  trip,
  ledger,
  settings,
  income,
  savings,
  accounts,
  group,
  loan: loans,
  transactions,
});

const reducers = (state, action) => {
  if (action.type === ACTION_LOGOUT_USER) {
    return { app: app({}, action) };
  }
  return rootReducers(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(expenseAISaga);
sagaMiddleware.run(settingsSaga);
sagaMiddleware.run(expensesSaga);
sagaMiddleware.run(incomeSaga);
sagaMiddleware.run(savingsSaga);
sagaMiddleware.run(accountsSaga);
sagaMiddleware.run(categoriesSaga);
sagaMiddleware.run(userSaga);
sagaMiddleware.run(groupsSaga);
sagaMiddleware.run(loansSaga);
sagaMiddleware.run(transactionsSaga);
sagaMiddleware.run(tripsSaga);
sagaMiddleware.run(ledgersSaga);

export default store;
