/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { changePassword } from "../../../../services/UserService";
import TextInputComponent from "../../../shared/TextInputComponent";
import StatusMessageComponent from "../../../shared/StatusMessageComponent";
import * as Yup from "yup";
import { useFormik } from "formik";

export default function ChangePasswordComponent({ user, doLogout }) {
  const [statusMsg, setStatusMsg] = useState();

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Password is required."),
      newPassword: Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Must contain atleast 1 special character, 1 number and upto 8 characters",
        )
        .required("New Password is required"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        "Passwords do not match",
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      changePassword(values.currentPassword, values.newPassword)
        .then(() => {
          setStatusMsg({
            type: "success",
            text: "Password successfully updated",
          });
        })
        .catch((err) => {
          if (err.status == 401) {
            doLogout();
          } else {
            setStatusMsg({
              type: "error",
              text: err.message,
            });
          }
        })
        .finally(() => resetForm());
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div id="change-password-panel">
        <div className="smalltitle">Change Password</div>

        <StatusMessageComponent message={statusMsg} />

        <div>
          <div className="divFlex topMargin5P">
            <div style={{ flex: 1, fontSize: "large" }} className="divCenter">
              Old Password
            </div>
            <div style={{ flex: 2 }}>
              <TextInputComponent
                id="currentPassword"
                errorid="current-password-error"
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                error={formik.errors.currentPassword}
              />
            </div>
          </div>

          <div className="divFlex topMargin5P">
            <div style={{ flex: 1, fontSize: "large" }} className="divCenter">
              New Password
            </div>
            <div style={{ flex: 2 }}>
              <TextInputComponent
                id="newPassword"
                errorid="current-password-error"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                error={formik.errors.newPassword}
              />
            </div>
          </div>

          <div className="divFlex topMargin5P">
            <div style={{ flex: 1, fontSize: "large" }} className="divCenter">
              Confirm Password
            </div>
            <div style={{ flex: 2 }}>
              <TextInputComponent
                id="confirmPassword"
                errorid="current-password-error"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                error={formik.errors.confirmPassword}
              />
            </div>
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          <button type="submit" className="fnxGreen">
            Update
          </button>
        </div>
      </div>
    </form>
  );
}
