import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getEmptyBox } from "../../../helpers/IconHelper";
import { getExpensesForMonthYear } from "../../../services/ExpenseService";
import SpinnerComponent from "../../shared/SpinnerComponent";
import ChartSelectedComponent from "./ChartSelectedComponent";
import HomePageLineChartComponent from "./HomePageLineChartComponent";
import HomePagePieChartComponent from "./HomePagePieChartComponent";
import { getMonthNameByIndex } from "../../../helpers/DateHelper";

class HomePageChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      pieChartSelectedDefn: undefined,
      lineChartSelectedDefn: undefined,
      filteredExpenses: [],
    };
    this.getFilteredExpensesOverPieChart =
      this.getFilteredExpensesOverPieChart.bind(this);
    this.getFilteredExpensesOverLineChart =
      this.getFilteredExpensesOverLineChart.bind(this);
  }

  async componentDidMount() {
    await this.getFilteredExpensesOverPieChart();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedMonth !== prevProps.selectedMonth ||
      this.props.selectedYear !== prevProps.selectedYear
    ) {
      this.setState({
        pieChartSelectedDefn: undefined,
        lineChartSelectedDefn: undefined,
      });
    }
    if (
      this.state.pieChartSelectedDefn &&
      this.state.pieChartSelectedDefn !== prevState.pieChartSelectedDefn
    ) {
      await this.getFilteredExpensesOverPieChart();
    }

    if (
      this.state.lineChartSelectedDefn &&
      this.state.lineChartSelectedDefn !== prevState.lineChartSelectedDefn
    ) {
      await this.getFilteredExpensesOverLineChart();
    }
  }

  async getFilteredExpensesOverPieChart() {
    try {
      const filteredExpenses = await getExpensesForMonthYear(
        this.props.groupid,
        this.props.selectedMonth,
        this.props.selectedYear,
        undefined,
        this.state.pieChartSelectedDefn?.category,
      );
      this.setState({ filteredExpenses });
    } catch (err) {
      console.error(err);
    }
  }

  async getFilteredExpensesOverLineChart() {
    try {
      let month = this.state.lineChartSelectedDefn.previousMonth
        ? this.props.selectedMonth - 1
        : this.props.selectedMonth;
      let year = this.props.selectedYear;

      if (month === 0) {
        month = 12;
        year = this.props.selectedYear - 1;
      }
      const filteredExpenses = await getExpensesForMonthYear(
        this.props.user.groupid,
        month,
        year,
        undefined,
        this.state.lineChartSelectedDefn.category,
        this.state.lineChartSelectedDefn.subcategory,
      );
      this.setState({ filteredExpenses });
    } catch (err) {
      console.error(err);
    }
  }

  renderChartComponent() {
    let month = this.state.lineChartSelectedDefn?.previousMonth
      ? this.props.selectedMonth - 1
      : this.props.selectedMonth;
    let year = this.props.selectedYear;

    if (month === 0) {
      month = 12;
      year = this.props.selectedYear - 1;
    }

    if (this.props.monthlyTotal > 0) {
      return (
        <>
          {this.state.lineChartSelectedDefn && (
            <ChartSelectedComponent
              filteredExpenses={this.state.filteredExpenses}
              user={this.props.user}
              renderTitle={() => (
                <div>
                  <strong>
                    {this.state.lineChartSelectedDefn.category}(
                    {this.state.lineChartSelectedDefn.subcategory})
                  </strong>
                  <span>for</span>
                  <strong>
                    {getMonthNameByIndex(month)} {year}
                  </strong>
                </div>
              )}
              onCancel={() =>
                this.setState({ lineChartSelectedDefn: undefined })
              }
            />
          )}
          <HomePagePieChartComponent
            show={!this.state.lineChartSelectedDefn}
            onSelect={({ chartWrapper }) => {
              const chart = chartWrapper.getChart();
              const selection = chart.getSelection();
              if (selection.length === 1) {
                const [selectedItem] = selection;
                const dataTable = chartWrapper.getDataTable();
                const { row } = selectedItem;
                this.setState({
                  pieChartSelectedDefn: {
                    category: dataTable.getValue(row, 0),
                  },
                });
              }
            }}
          />

          {this.state.pieChartSelectedDefn && (
            <ChartSelectedComponent
              filteredExpenses={this.state.filteredExpenses}
              user={this.props.user}
              renderTitle={() => (
                <div>
                  <span>Category </span>
                  <strong>{this.state.pieChartSelectedDefn.category}</strong>
                  <span>for</span>
                  <strong>
                    {getMonthNameByIndex(this.props.selectedMonth)}{" "}
                    {this.props.selectedYear}
                  </strong>
                </div>
              )}
              onCancel={() =>
                this.setState({ pieChartSelectedDefn: undefined })
              }
            />
          )}

          <HomePageLineChartComponent
            show={!this.state.pieChartSelectedDefn}
            onSelect={({ chartWrapper }) => {
              const chart = chartWrapper.getChart();
              const selection = chart.getSelection();
              if (selection.length === 1) {
                const [selectedItem] = selection;
                const { row, column } = selectedItem;
                const dataTable = chartWrapper.getDataTable();
                const categorySubcategory = dataTable
                  .getValue(row, 0)
                  .split("-");
                // if column is 1 then current month. If 2, then prev month.
                this.setState({
                  lineChartSelectedDefn: {
                    previousMonth: column === 2,
                    category: categorySubcategory[0].trim(),
                    subcategory: categorySubcategory[0].trim(),
                  },
                });
              }
            }}
          />
        </>
      );
    } else {
      return (
        <div className="divCenterAlign" style={{ width: "100%" }}>
          <img
            src={getEmptyBox()}
            style={{ width: 100, height: 100, marginRight: "40px" }}
          />
          <span className="subtitle">Nothing to show this month!</span>
        </div>
      );
    }
  }

  render() {
    return (
      <>
        <hr className="shadowed" />
        {this.state.isLoading && <SpinnerComponent />}
        {!this.state.isLoading && (
          <div className="home-chart-wrapper">
            {this.renderChartComponent()}
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user, selectedMonth, selectedYear } = state.app;
  const { groupid } = state.group;
  const { monthlyTotal } = state.expense;
  return { monthlyTotal, user, selectedMonth, selectedYear, groupid };
}

HomePageChartComponent.propTypes = {
  monthlyTotal: PropTypes.number,
  user: PropTypes.object,
  selectedMonth: PropTypes.any,
  selectedYear: PropTypes.any,
};

export default connect(mapStateToProps)(HomePageChartComponent);
