import React from "react";
import { getSavingsById, updateSavings } from "../../services/SavingsService";
import { getLocalizedDate } from "../../helpers/DateHelper";
import PropTypes from "prop-types";
import CalendarInputComponent from "../shared/CalendarInputComponent";
import "./SavingsDialogComponent.css";
import TextInputComponent from "../shared/TextInputComponent";

export default class EditSavingsDialogComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: "",
      selectedDesc: "",
      selectedAmount: "",
      shareableUsers: [],
    };
    this.updateSavingsHandler = this.updateSavingsHandler.bind(this);
    this.inflateSavings = this.inflateSavings.bind(this);
  }

  async componentDidMount() {
    await this.inflateSavings();
  }

  async inflateSavings() {
    try {
      const savings = await getSavingsById(
        this.props.groupid,
        this.props.editSavingsId,
      );
      this.setState({
        selectedDesc: savings.description,
        selectedAmount: savings.amount,
        selectedDate: getLocalizedDate(savings.savingsdate, this.props.user),
        shareableUsers: savings.users || [],
      });
    } catch (err) {
      this.props.onFailure(err);
    }
  }

  async updateSavingsHandler() {
    try {
      await updateSavings(
        this.props.user.groupid,
        this.props.editSavingsId,
        this.state.selectedDesc,
        this.state.selectedDate,
        this.state.selectedAmount,
        this.state.shareableUsers,
      );
      this.props.onSuccess();
    } catch (err) {
      console.error(err);
      this.props.onFailure(err);
    }
  }

  handleShareableUsers(user, e) {
    const usersSet = new Set([...this.state.shareableUsers]);
    if (e.target.checked === true) {
      usersSet.add(user.username);
    } else {
      usersSet.delete(user.username);
    }
    this.setState({ shareableUsers: [...usersSet] });
  }

  render() {
    return (
      <div className="modal" id="edit-savings-dialog">
        <div className="modal-content savings-dialog-content">
          <span className="close" onClick={this.props.onCancel}>
            &times;
          </span>
          <div style={{ textAlign: "center" }}>
            <h3> Edit Savings</h3>
          </div>
          <div className="savings-dialog-content-body">
            <table className="formTable">
              <thead>
                <tr> </tr>
                <tr> </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {" "}
                    Description <sup>*</sup>
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      id="edit-savings-desc"
                      value={this.state.selectedDesc}
                      onChange={(e) =>
                        this.setState({ selectedDesc: e.target.value })
                      }
                      onClick={() => this.setState({ showDate: false })}
                    />{" "}
                  </td>
                </tr>

                <tr>
                  <td>
                    {" "}
                    Date <sup>*</sup>{" "}
                  </td>
                  <td>
                    <CalendarInputComponent
                      id="edit-savings-date"
                      value={this.state.selectedDate}
                      textAlign={"flex-start"}
                      onChange={(dateText) =>
                        this.setState({ selectedDate: dateText })
                      }
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    {" "}
                    Amount <sup>*</sup>
                  </td>
                  <td>
                    <TextInputComponent
                      type="text"
                      id="edit-savings-amount"
                      onChange={(e) =>
                        this.setState({ selectedAmount: e.target.value })
                      }
                      onClick={() => this.setState({ showDate: false })}
                      value={this.state.selectedAmount}
                      error={
                        isNaN(this.state.selectedAmount)
                          ? "Please enter valid amount."
                          : undefined
                      }
                    />
                  </td>
                </tr>

                <tr>
                  {this.props.usersInGroup.length > 0 && (
                    <>
                      <td> Share with?</td>
                      <td>
                        <div id="shared-users-panel">
                          <ul>
                            {this.props.usersInGroup.map((user, i) => (
                              <li key={i}>
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    this.handleShareableUsers(user, e)
                                  }
                                  id={`sharedUser${i}`}
                                  checked={this.state.shareableUsers.includes(
                                    user.username,
                                  )}
                                />
                                {user.firstname || user.email}{" "}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: "1em", textAlign: "center" }}>
            <input
              id="cancel"
              type="button"
              value="Cancel"
              className="fnxGrey"
              onClick={this.props.onCancel}
            />

            <input
              id="createSaving"
              type="button"
              value="Update"
              className="fnxGreen"
              onClick={this.updateSavingsHandler}
            />
          </div>
        </div>
      </div>
    );
  }
}

EditSavingsDialogComponent.propTypes = {
  user: PropTypes.any.isRequired,
  doLogout: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  editSavingsId: PropTypes.string.isRequired,
  usersInGroup: PropTypes.array,
};
