import React from "react";
import AccountsRowComponent from "./AccountsRowComponent";
import ModalDialogComponent from "../../shared/ModalDialogComponent";
import PropTypes from "prop-types";
import store from "../../../store";
import {
  getAccounts,
  deleteAccount,
  addAccount,
  setStatus,
} from "../../../actions/accountsAction";
import { connect } from "react-redux";
import StatusMessageComponent2 from "../../shared/StatusMessageComponent2";
import CreatePlaidAccount from "../plaid/CreatePlaidAccount";
import CreateAccountComponent from "./CreateAccountComponent";

class AccountsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      deleteCardId: undefined,
      statusMsg: undefined,
      accountNameEditText: "",
    };
    this.deleteAccountHandler = this.deleteAccountHandler.bind(this);
    this.createAccountHandler = this.createAccountHandler.bind(this);
  }

  async componentDidMount() {
    store.dispatch(getAccounts());
    store.dispatch(setStatus(undefined));
  }

  async deleteAccountHandler(id) {
    store.dispatch(deleteAccount(id));
    this.setState({ deleteCardId: undefined });
  }

  async createAccountHandler() {
    if (this.state.accountNameEditText.trim().length === 0) {
      this.setState({
        statusMsg: {
          text: "Please enter a valid card name.",
          type: "error",
        },
      });
      return;
    }
    store.dispatch(addAccount(this.state.accountNameEditText));
    this.setState({ accountNameEditText: "" });
  }

  render() {
    return (
      <div className="margin-center">
        <StatusMessageComponent2 message={this.state.statusMsg} />

        <div className="title-extra topMargin2P">Accounts</div>

        <div>
          {this.state.deleteCardId && (
            <ModalDialogComponent
              title={"Delete Account"}
              message={"Are you sure you want to remove this account?"}
              onSuccessTitle={"Yes"}
              onCancelTitle={"No"}
              onSuccess={() =>
                this.deleteAccountHandler(this.state.deleteCardId)
              }
              onCancel={() => this.setState({ deleteCardId: undefined })}
            />
          )}
        </div>

        <div
          style={{
            marginTop: "2%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <CreateAccountComponent
              onSuccess={() => {
                this.setState({
                  statusMsg: {
                    text: "Account successfully added.",
                    type: "success",
                  },
                });
              }}
              onError={(e) => {
                this.setState({
                  statusMsg: {
                    text:
                      e.status === 409
                        ? "Whoops! Perhaps you have entered a duplicate name."
                        : "Whoops! Please try again.",
                    type: "error",
                  },
                });
              }}
            />

            <div className="divSpreadCol" style={{ marginTop: "20%" }}>
              {this.props.user.entitlement > 2 && (
                <div>
                  <hr className="shadowed" />

                  <div className="divSpread divCenterAlign">
                    <h3>Connect to banks</h3>

                    <a
                      href="https://help.expensehut.com/plaidAccount"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Help
                    </a>
                  </div>

                  <div>
                    <p>
                      Connect to your bank account and relax as we add the
                      transactions automatically everyday.
                    </p>
                  </div>

                  <div style={{ marginTop: "5%" }}>
                    <CreatePlaidAccount
                      user={this.props.user}
                      onCreateSuccess={() => {
                        store.dispatch(getAccounts());
                        this.setState({
                          statusMsg: {
                            text: "Account created successfully.",
                            type: "success",
                          },
                        });
                      }}
                      onCreateError={(err) => {
                        console.error(err);
                        if (err.status === 409 || err.status === 403) {
                          this.setState({
                            statusMsg: {
                              text: "It appears there is already an existing account with this name.",
                              type: "error",
                            },
                          });
                        } else {
                          this.setState({
                            statusMsg: {
                              text: "Whoops! Something went wrong. Please try again.",
                              type: "error",
                            },
                          });
                        }
                      }}
                      disabled={false}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div style={{ width: "50%" }}>
            <ol style={{ listStyle: "none" }}>
              {this.props.accounts.map((acc, i) => {
                return (
                  <AccountsRowComponent
                    key={i}
                    acc={acc}
                    index={i}
                    user={this.props.user}
                    onDeleteClick={(id) => this.setState({ deleteCardId: id })}
                  />
                );
              })}
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { accounts, user } = state.app;
  return {
    user,
    accounts,
  };
}

export default connect(mapStateToProps)(AccountsComponent);
