import React from "react";
import PropTypes from "prop-types";

export const SpinnerComponentColor = {
  orange: "var(--fnx-orange)",
  blue: "var(--fnx-blue)",
  green: "var(--fnx-total-green)",
  grey: "var(--fnx-dark-grey)",
  default: "var(--primary-light)",
};

/**
 *
 * @property size (string) : small | medium | large
 * @property color {SpinnerComponentColor} : Use SpinnerComponentColor
 */
export default class SpinnerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.width = this._getWidth();
  }

  _getWidth() {
    switch (this.props.size) {
      case "medium":
        return 32;
      case "small":
        return 20;
      default:
        return 64;
    }
  }

  _getBorderWidth() {
    switch (this.props.size) {
      case "medium":
        return 3;
      default:
        return 5;
    }
  }

  _getDivStyles() {
    return {
      width: this.width,
      height: this.width,
      borderWidth: this._getBorderWidth(),
      borderColor:
        (this.props.color || "var(--primary-light)") +
        " transparent transparent transparent",
    };
  }

  _getContainerStyles() {
    switch (this.props.size) {
      case "small":
        return { width: 20, height: 20 };
      case "medium":
        return { width: 40, height: 40 };
      default:
        return { width: 80, height: 80 };
    }
  }

  render() {
    return (
      <div style={styles.container}>
        <div className="lds-ring" style={this._getContainerStyles()}>
          <div style={this._getDivStyles()}></div>
          <div style={this._getDivStyles()}></div>
          <div style={this._getDivStyles()}></div>
          <div style={this._getDivStyles()}></div>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
  },
};

SpinnerComponent.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};
