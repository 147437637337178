import { call, put, takeLatest } from "redux-saga/effects";
import * as Api from "../services/SavingsService";
import {
  ACTION_GET_SAVINGS_TOTAL,
  setSavingsTotal,
} from "../actions/savingsAction";
import store from "../store";

function* fetchSavingsTotal() {
  try {
    const groupid = yield store.getState().group?.groupid;
    if (groupid) {
      const incomes = yield call(Api.getSavingsTotal, groupid);
      yield put(setSavingsTotal(incomes.total));
    }
  } catch (e) {
    console.error(e);
    yield put(setSavingsTotal(0));
  }
}

export default function* savingsSaga() {
  yield takeLatest(ACTION_GET_SAVINGS_TOTAL, fetchSavingsTotal);
}
