export const ACTION_SET_EDIT_LEDGER_ID = "SET_EDIT_LEDGER_ID";
export const ACTION_GET_LEDGERS = "GET_LEDGERS";
export const ACTION_SET_LEDGERS = "SET_LEDGERS";

export function setEditLedgerId(payload) {
  return {
    type: ACTION_SET_EDIT_LEDGER_ID,
    payload,
  };
}

export function getLedgers() {
  return {
    type: ACTION_GET_LEDGERS,
  };
}

export function setLedgers(payload) {
  return {
    type: ACTION_SET_LEDGERS,
    payload,
  };
}
