import { call, put, takeEvery } from "redux-saga/effects";
import * as Api from "../services/CategoryService";
import {
  setCategories,
  ACTION_GET_CATEGORIES,
  setCategoriesMap,
  ACTION_GET_CATEGORIES_MAP,
} from "../actions/categoriesAction";

function* fetchCategories() {
  try {
    const categories = yield call(Api.getCategoriesOnly);
    yield put(setCategories(categories));
  } catch (e) {
    yield put(setCategories([]));
  }
}

// get category to subcategory map
function* fetchCategoriesMap() {
  try {
    const categories = yield call(Api.getCategoriesMap);
    yield put(setCategoriesMap(categories));
  } catch (e) {
    yield put(setCategoriesMap([]));
  }
}

export default function* categoriesSaga() {
  yield takeEvery(ACTION_GET_CATEGORIES, fetchCategories);
  yield takeEvery(ACTION_GET_CATEGORIES_MAP, fetchCategoriesMap);
}
