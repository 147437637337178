import React from "react";
import { createSavings } from "../../services/SavingsService";
import PropTypes from "prop-types";
import CalendarInputComponent from "../shared/CalendarInputComponent";
import TextInputComponent from "../shared/TextInputComponent";
import "./SavingsDialogComponent.css";
import moment from "moment";

export default class CreateSavingsDialogComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: "",
      selectedDateSQL: "",
      descEditText: "",
      amountEditText: "",
      isDataValid: false,
    };
    this.shareableUsers = [];
    this.createSavingsHandler = this.createSavingsHandler.bind(this);
  }

  async createSavingsHandler() {
    try {
      await createSavings(
        this.props.groupid,
        this.state.descEditText,
        moment(this.state.selectedDateSQL).format("YYYY-MM-DD"),
        this.state.amountEditText,
        this.shareableUsers,
      );
      this.props.onSuccess();
    } catch (err) {
      console.error(err);
      this.props.onFailure(err);
    }
  }

  async inputValidator() {
    if (
      this.state.descEditText.trim().length &&
      this.state.selectedDate.trim().length &&
      this.state.amountEditText.length &&
      !isNaN(this.state.amountEditText)
    ) {
      this.setState({ isDataValid: true });
    } else {
      this.setState({ isDataValid: false });
    }
  }

  handleShareableUsers(user, e) {
    if (e.target.checked === true) {
      this.shareableUsers.push(user.username);
    } else {
      const indexOfUsername = this.shareableUsers.indexOf(user.username);
      this.shareableUsers.splice(indexOfUsername, 1);
    }
  }

  render() {
    return (
      <div className="modal" id="create-savings-dialog">
        <div className="modal-content savings-dialog-content">
          <span className="close" onClick={this.props.onCancel}>
            &times;
          </span>
          <div style={{ textAlign: "center" }}>
            <h3> Add Savings</h3>
          </div>
          <div className="savings-dialog-content-body">
            <table
              className="formTable"
              style={{ width: "100%", borderSpacing: "20px" }}
            >
              <tbody>
                <tr>
                  <td>
                    {" "}
                    Description <sup>*</sup>
                  </td>
                  <td style={{ display: "flex" }}>
                    {" "}
                    <input
                      type="text"
                      id="savingsdesc"
                      value={this.state.descEditText}
                      onChange={(e) =>
                        this.setState(
                          { descEditText: e.target.value },
                          this.inputValidator,
                        )
                      }
                      onClick={() => this.setState({ showDate: false })}
                    />{" "}
                  </td>
                </tr>

                <tr>
                  <td>
                    {" "}
                    Date <sup>*</sup>{" "}
                  </td>
                  <td style={{ display: "flex" }}>
                    <CalendarInputComponent
                      id="savingsdate"
                      value={this.state.selectedDate}
                      onChange={(localizedDate, dateText) =>
                        this.setState(
                          {
                            selectedDate: localizedDate,
                            selectedDateSQL: dateText,
                          },
                          this.inputValidator,
                        )
                      }
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    {" "}
                    Amount <sup>*</sup>
                  </td>
                  <td>
                    <TextInputComponent
                      type="text"
                      id="savingsamount"
                      onChange={(e) =>
                        this.setState(
                          { amountEditText: e.target.value },
                          this.inputValidator,
                        )
                      }
                      onClick={() => this.setState({ showDate: false })}
                      value={this.state.amountEditText}
                      error={
                        isNaN(this.state.amountEditText)
                          ? "Please enter valid amount."
                          : undefined
                      }
                    />
                  </td>
                </tr>

                <tr>
                  {this.props.usersInGroup.length > 0 && (
                    <>
                      <td> Share with?</td>
                      <td>
                        <div id="shared-users-panel">
                          <ul>
                            {this.props.usersInGroup.map((user, i) => (
                              <li key={i}>
                                <input
                                  type="checkbox"
                                  onClick={(e) =>
                                    this.handleShareableUsers(user, e)
                                  }
                                  id={
                                    "shareduser_" +
                                    user.email
                                      .replace("@", "_")
                                      .replace(".", "_")
                                  }
                                />
                                {user.firstname || user.email}{" "}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: "1em", textAlign: "center" }}>
            <input
              id="cancel"
              type="button"
              value="Cancel"
              className="fnxGrey"
              onClick={this.props.onCancel}
            />

            <input
              id="createSaving"
              type="button"
              value="Create"
              className="fnxGreen"
              disabled={!this.state.isDataValid}
              onClick={this.createSavingsHandler}
            />
          </div>
        </div>
      </div>
    );
  }
}

CreateSavingsDialogComponent.propTypes = {
  user: PropTypes.any.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  usersInGroup: PropTypes.array,
};
