import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";
import AuthConnection from "./AuthConnection";

export function registerUser(email, password) {
  const url = new AuthConnection().getURL() + "/register";
  return executeService(url, "POST", { email, password }, 200, "restrict");
}

export function registerWithSSO(token) {
  const url = new FNXConnection().getURL() + "/register/sso";
  return executeService(url, "POST", { token }, 200, "json");
}

export function verifyRegistration(candidateId, regkey, email) {
  const url = new AuthConnection().getURL() + "/register/verify";
  return executeService(
    url,
    "POST",
    { cid: candidateId, regkey, email },
    200,
    "restrict",
  );
}
