import React from "react";
import store from "../../store";
import { Link } from "react-router-dom";

export default function GroupsRowComponent({ group, key, defaultGroupId }) {
  return (
    <Link to={`/settings/groups/${group.id}`}>
      <li
        className={
          group.id === defaultGroupId
            ? "tabbed-list-li selected"
            : "tabbed-list-li"
        }
        key={key}
      >
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className="groups-right-panel-list"
        >
          <div style={{ width: "50%" }}>
            <img src={"/assets/icons/groups.svg"} width="50px" />
          </div>

          <div style={{ width: "50%", textAlign: "end" }}>
            <div>
              <strong className="groups-right-panel-list-groupname">
                {group.name}
              </strong>
            </div>
          </div>
        </div>
      </li>
    </Link>
  );
}
