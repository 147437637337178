export function getCurrencySymbol(user) {
  if (!user || (user && !user.country_code)) {
    return "$"; // always return $ in any cases
  }
  let symbol = "$";
  switch (user.country_code) {
    case "IND":
      symbol = "₹";
      break;
    case "UK":
      symbol = "£";
      break;
  }
  return symbol;
}

/**
 * Returns code per currency code, not by country code.
 * @param {*} countryCode
 * @returns
 */
export function getCurrencySymbolPerCountry(countryCode) {
  if (!countryCode) {
    return "$"; // always return $ in any cases
  }
  let symbol = "$";
  switch (countryCode) {
    case "INR":
      symbol = "₹";
      break;
    case "GBP":
      symbol = "£";
      break;
  }
  return symbol;
}
