export const ACTION_GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const ACTION_SET_TRANSACTIONS_PLAID_EXPENSE_ERROR = "SET_TRANSACTIONS";

export function getTransactions() {
  return {
    type: ACTION_GET_TRANSACTIONS,
  };
}

export function setTransactionsPlaidExpenseError(payload) {
  return {
    type: ACTION_SET_TRANSACTIONS_PLAID_EXPENSE_ERROR,
    payload,
  };
}
