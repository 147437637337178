import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export function getTripsForMonthYear(groupid, year, month) {
  const url =
    new FNXConnection().getURL() +
    "/trips/" +
    groupid +
    "/month/" +
    year +
    "/" +
    month;
  return executeService(url);
}

export function getTrips(groupid) {
  const url = new FNXConnection().getURL() + "/trips/" + groupid;
  return executeService(url);
}

export function getTripById(groupid, tripid) {
  const url = new FNXConnection().getURL() + "/trips/" + groupid + "/" + tripid;
  return executeService(url);
}

export function createTrip(name, fromDate, toDate, destination) {
  const url = new FNXConnection().getURL() + "/trips";
  return executeService(
    url,
    "PUT",
    { name, fromDate, toDate, destination },
    201,
    "json",
  );
}

export function updateTrip(id, username, name, fromDate, toDate, destination) {
  const url = new FNXConnection().getURL() + "/trips/" + id;
  return executeService(
    url,
    "POST",
    { username, name, fromDate, toDate, destination },
    200,
    "json",
  );
}

export function deleteTrip(id) {
  const url = `${new FNXConnection().getURL()}/trips/${id}`;
  return executeService(url, "DELETE", undefined, 204, "restrict");
}

export function getTripExpenses(groupid, tripid) {
  const url =
    new FNXConnection().getURL() +
    "/trips/" +
    groupid +
    "/" +
    tripid +
    "/expenses";
  return executeService(url);
}

export function getTripExpensesTotal(groupid, tripid) {
  const url =
    new FNXConnection().getURL() +
    "/trips/" +
    groupid +
    "/" +
    tripid +
    "/expenses/total";
  return executeService(url);
}

export function createTripExpense(username, name, date, amount, tripid) {
  const url = new FNXConnection().getURL() + "/expense";
  return executeService(
    url,
    "PUT",
    { username, name, date, amount, type: "trip", tripid },
    201,
    "json",
  );
}
