import React from "react";
import {
  deleteExpense,
  getMiscExpensesForMonth,
} from "../../services/ExpenseService";
import * as DateHelper from "../../helpers/DateHelper";
import { connect } from "react-redux";
import ModalDialogComponent from "../shared/ModalDialogComponent";
import PropTypes from "prop-types";
import StatusMessageComponent from "../shared/StatusMessageComponent2";
import { Link } from "react-router-dom";
import EditMiscExpenseDialogComponent from "./EditMiscExpenseDialogComponent";
import MonthYearComponent from "../shared/month-year-component/MonthYearComponent";
import { DeleteButtonComponent } from "../shared/ButtonComponent";
import store from "../../store";
import { getMiscExpensesTotal } from "../../actions/expensesAction";

class MiscExpensesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusMsg: undefined,
      miscExpenses: [],
      deleteMiscExpenseId: undefined,
      editMiscExpenseId: undefined,
    };
    this.fetchData = this.fetchData.bind(this);
    this.deleteExpenseHandler = this.deleteExpenseHandler.bind(this);
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.selectedMonth !== prevProps.selectedMonth ||
      this.props.selectedYear !== prevProps.selectedYear ||
      this.props.groupid !== prevProps.groupid
    ) {
      await this.fetchData();
    }
  }

  async fetchData() {
    try {
      const miscExpenses = await getMiscExpensesForMonth(
        this.props.groupid,
        this.props.selectedMonth,
        this.props.selectedYear,
      );
      this.setState({ miscExpenses });
      store.dispatch(getMiscExpensesTotal());
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        this.setState({
          statusMsg: {
            type: "error",
            text: `Whoops! Something unexpected happened. Please go back and try again.`,
          },
        });
      }
    }
  }

  async deleteExpenseHandler() {
    try {
      this.setState({ deleteMiscExpenseId: undefined });
      await deleteExpense(this.state.deleteMiscExpenseId);
      await this.fetchData();
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        this.setState({
          statusMsg: {
            type: "text",
            text: err.message,
          },
        });
      }
    }
  }

  render() {
    return (
      <div style={{ margin: "5%", marginTop: "0%" }}>
        <StatusMessageComponent message={this.state.statusMsg} />

        <div className="title">Misc Expenses</div>

        {this.state.editMiscExpenseId && (
          <EditMiscExpenseDialogComponent
            user={this.props.user}
            groupid={this.props.groupid}
            onCancel={() => this.setState({ editMiscExpenseId: undefined })}
            editMiscExpenseId={this.state.editMiscExpenseId}
            onSuccess={async () => {
              this.setState({
                editMiscExpenseId: undefined,
                statusMsg: {
                  type: "success",
                  text: "Expense successfully updated.",
                },
              });
              await this.fetchData();
            }}
            onFailure={() => {
              this.setState({
                editMiscExpenseId: undefined,
                statusMsg: {
                  type: "error",
                  text: "Whoops! Error updating expense. Please try again.",
                },
              });
            }}
          />
        )}

        <div className="divSpread">
          <Link to="/home">Back</Link>
          <MonthYearComponent />
        </div>

        <div>
          {this.state.deleteMiscExpenseId && (
            <ModalDialogComponent
              title="Delete misc expense"
              message="Are you sure?"
              onSuccess={this.deleteExpenseHandler}
              onCancel={() => this.setState({ deleteMiscExpenseId: undefined })}
            />
          )}
        </div>

        <div style={{ marginTop: "5%" }}>
          These are expenses that you may not want to include to current
          month&apos;s total, but you still want to track them.
        </div>

        <div style={{ marginTop: "5%" }}>
          <b>
            {" "}
            {DateHelper.getMonthNameByIndex(this.props.selectedMonth) +
              " " +
              this.props.selectedYear}{" "}
          </b>
        </div>

        <div style={{ marginTop: "2%" }}>
          <table className="exhutTable">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Created By</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.miscExpenses.map((mExpense, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <a
                        className="hyperlink"
                        onClick={() =>
                          this.setState({ editMiscExpenseId: mExpense.id })
                        }
                      >
                        Edit
                      </a>
                    </td>
                    <td>{mExpense.comments}</td>
                    <td>
                      {DateHelper.getDateExcludingTime(mExpense.expensedate)}
                    </td>
                    <td>{mExpense.amount}</td>
                    <td>
                      {
                        this.props.groupUsers.find(
                          (user) => user.username === mExpense.username,
                        )?.firstName
                      }
                    </td>
                    <td>
                      <DeleteButtonComponent
                        onClick={() =>
                          this.setState({ deleteMiscExpenseId: mExpense.id })
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

MiscExpensesComponent.propTypes = {
  user: PropTypes.any.isRequired,
  selectedMonth: PropTypes.string,
  selectedYear: PropTypes.string,
  doLogout: PropTypes.func.isRequired,
  groupUsers: PropTypes.array,
};

function mapStateToProps(state) {
  const { user, navigate, selectedMonth, selectedYear } = state.app;
  const { groupid, users: groupUsers } = state.group;
  return { user, navigate, selectedMonth, selectedYear, groupid, groupUsers };
}

export default connect(mapStateToProps)(MiscExpensesComponent);
