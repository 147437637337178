import React from "react";
import PropTypes from "prop-types";
import "./AnalysisComponent.css";
import AnalysisTileComponent from "./AnalysisTileComponent";
import { getCurrencySymbol } from "../../helpers/CurrencyHelper";
import { getFullName } from "../../helpers/UserHelper";

export default class AnalysisUsersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      categoryData: undefined,
      subcategoryData: undefined,
    };
  }

  fabricateAnalyticsDataToArray(payload) {
    const resultArray = [];
    for (const p of payload) {
      const rowArray = [];
      const user = this.props.groupUsers.find((u) => u.username === p.username);
      rowArray.push(getFullName(user));
      rowArray.push(p.total);
      resultArray.push(rowArray);
    }
    return resultArray;
  }

  render() {
    return (
      <div style={{ display: "flex", float: "left", flexFlow: "wrap" }}>
        {this.props.data.map((d, i) => {
          return (
            <AnalysisTileComponent
              key={i}
              payload={d}
              headerText={d.monthName + " " + d.year}
              subHeaderText={getCurrencySymbol(this.props.user) + " " + d.total}
              primaryColumn={"User"}
              tableContents={this.fabricateAnalyticsDataToArray(d.data)}
            />
          );
        })}
      </div>
    );
  }
}

AnalysisUsersComponent.propTypes = {
  data: PropTypes.array.isRequired,
  user: PropTypes.any.isRequired,
  groupUsers: PropTypes.array.isRequired,
};
