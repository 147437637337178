import FNXConnection from "./FNXConnection";
import { executeService } from "./FetchService";

export function getTransactions() {
  const url =
    new FNXConnection().getURL() + "/user/" + username + "/transaction/scan";
  return executeService(url, "GET");
}

export function getErrorTransactions(username) {
  const url =
    new FNXConnection().getServicesURL() +
    "/transactions?type=PLAID_GET_EXPENSE_ERROR";
  return executeService(url, "GET");
}

export function deleteErrorTransactions(username, id) {
  const url =
    new FNXConnection().getURL() +
    "/user/" +
    username +
    "/transaction/plaid/" +
    id;
  return executeService(url, "DELETE", undefined, 204, "restrict");
}
