import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as Api from "../services/SettingsService";
import {
  setSettings,
  getSettings,
  setSettingsError,
  ACTION_GET_SETTINGS,
  ACTION_UPDATE_SETTINGS,
} from "../actions/settingsAction";
import store from "../store";

function* fetchSettings() {
  try {
    const username = yield store.getState().app.user?.username;
    if (username) {
      const settings = yield call(Api.getSettings, username);
      yield put(setSettings(settings));
    } else {
      console.debug("Cannot fetch settings for null username");
    }
  } catch (e) {
    console.error(e);
    yield put(setSettings({}));
  }
}

function* updateSettingsSaga(action) {
  try {
    const username = yield store.getState().app.user?.username;
    const {
      include_group_income,
      include_misc_expense_in_total,
      include_misc_expense_for_savings,
    } = action.payload;

    yield call(
      Api.updateSettings,
      username,
      include_group_income,
      include_misc_expense_for_savings,
      include_misc_expense_in_total,
    );
    yield put(getSettings());
  } catch (e) {
    console.error(e);
    yield put(setSettingsError(e.message));
  }
}

export default function* settingsSaga() {
  yield takeEvery(ACTION_GET_SETTINGS, fetchSettings);
  yield takeLatest(ACTION_UPDATE_SETTINGS, updateSettingsSaga);
}
