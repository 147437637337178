import React from "react";
import { Link } from "react-router-dom";

export default class FirstTimeLoginComponent extends React.Component {
  render() {
    return (
      <div id="firstTimePage">
        <div>
          <div className="greeting">
            <h1> Hi there,</h1>
          </div>

          <div className="greeting-body">
            It seems we don&lsquo;t have any data to show you yet. To get
            started, first you need to create a
            <Link to="/settings/groups" style={{ marginLeft: "10px" }}>
              group
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
