import store from "../store";
import { getCurrencySymbol } from "./CurrencyHelper";

export function roundTo2Decimals(num) {
  if (num) {
    return num.toFixed(2);
  }

  return "";
}

/**
 * Formats amount using THOUSANDS separators and CURRENCYbased on user's country.
 * @param {Number} amount - amount in floating.
 */
export function formatToLocaleAmount(amount) {
  const user = store.getState().app.user;
  const country = user?.country_code || "USA";
  let localeAmount = 0;
  if (amount) {
    localeAmount =
      country === "IND"
        ? amount.toLocaleString("en-IN", { maximumFractionDigits: 2 })
        : amount.toLocaleString("en-US", { maximumFractionDigits: 2 });
  }
  return getCurrencySymbol(user) + " " + localeAmount;
}
