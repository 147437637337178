export async function downloadAndLoadGSIScript() {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      resolve(script);
    });
    script.addEventListener("error", () => {
      reject(new Error("Error loading gsi script"));
    });
  });
}
