import React from "react";
import PropTypes from "prop-types";
import * as DateHelper from "../../../helpers/DateHelper";

/**
 * Modal dialog component.
 *
 * title {String} - title for the dialog.
 *
 * message {String} - message body
 *
 * onSuccessTitle {String} - Title for button that performs a success operation.
 * For example, "Yes", "Confirm". Default is "Yes".
 *
 * onSuccess {Function)} - Callback for onSuccess operation.
 *
 * onCancelTitle {String} -Title for cancel button. For example, "No", "Cancel". Default is "No".
 *
 * onCancel {Function} - Callback for onCancel
 *
 */
export default class RelatedExpensAIComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="modal">
        <div className="modal-content-xl">
          <span className="close" onClick={this.props.onCancel}>
            &times;
          </span>
          <div style={{ marginTop: "1em", textAlign: "center" }}>
            <h2> Duplicate Expenses? </h2>
          </div>

          <div>
            <table className="exhutTable">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
                {this.props.relatedExpenses.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.category + "- (" + item.subcategory + ")"}</td>
                      <td>
                        {DateHelper.getDateExcludingTime(item.expensedate)}
                      </td>
                      <td>{item.amount}</td>
                      <td>{item.comments}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div style={{ textAlign: "center", margin: "40px 0px 20px 0px" }}>
            <span style={{ color: "blue" }}>
              {" "}
              Please confirm if they are NOT duplicate{" "}
            </span>
          </div>

          <div style={{ textAlign: "center" }}>
            <input
              type="button"
              value={"Go Back"}
              className="fnxGrey"
              onClick={this.props.onCancel}
            />

            <input
              type="button"
              style={{ marginLeft: "3em " }}
              value={"Confirm"}
              className="fnxRed"
              onClick={this.props.onConfirm}
            />
          </div>
        </div>
      </div>
    );
  }
}

RelatedExpensAIComponent.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  relatedExpenses: PropTypes.array,
};
