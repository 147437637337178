import { call, put, debounce } from "redux-saga/effects";
import * as Api from "../services/ExpenseAIService";
import * as ExpenseApi from "../services/ExpenseService";
import {
  setValidatePhraseJSONData,
  ACTION_GET_AI_JSON_DATA,
  ACTION_GET_RELATED_EXPENSES,
  getRelatedExpenses,
  setRelatedExpenses,
  setValidatePhraseJSONDataError,
} from "../actions/expensesAction";
import { logoutUser } from "../actions/userAction";

function* fetchValidateAIPhrase(action) {
  try {
    const json = yield call(Api.validatePhrase, action.payload.phrase);
    yield put(setValidatePhraseJSONData(json));
    yield put(
      getRelatedExpenses({ jsonData: json, user: action.payload.user }),
    );
  } catch (e) {
    if (e.status === 401) {
      yield put(logoutUser());
    } else {
      console.error("Error fetching validate phrase", e);
      yield put(setValidatePhraseJSONDataError());
    }
  }
}

function* fetchRelatedExpenses(action) {
  try {
    const { jsonData, user } = action.payload;
    const category = jsonData?.category;
    const subcategory = jsonData?.subcategory;
    const expenseDate = jsonData?.expenseDate;

    if (category && subcategory && expenseDate && user) {
      const dateSplits = expenseDate.split("/");
      const month = user.country_code === "USA" ? dateSplits[0] : dateSplits[1];
      const year = dateSplits[2];
      const relatedExpenses = yield call(
        ExpenseApi.getExpensesRelated,
        user.groupid,
        year,
        month,
        category,
        subcategory,
      );
      yield put(setRelatedExpenses(relatedExpenses));
    } else {
      yield put(setRelatedExpenses([]));
    }
  } catch (err) {
    console.error(err);
  }
}

export default function* expenseAISaga() {
  yield debounce(500, ACTION_GET_AI_JSON_DATA, fetchValidateAIPhrase);
  yield debounce(500, ACTION_GET_RELATED_EXPENSES, fetchRelatedExpenses);
}
