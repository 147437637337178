import React from "react";
import HomePageExpenseListComponent from "./HomePageExpenseListComponent";
import "./HomeComponent.css";
import store from "../../store";
import { getAccounts } from "../../actions/accountsAction";
import {
  getExpensesTotal,
  setSelectedMonth,
  setSelectedYear,
} from "../../actions/expensesAction";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TermsConditionsComponent from "../other/TermsConditionsComponent";
import { Link } from "react-router-dom";
import FirstTimeLoginComponent from "../FirstTimeLoginComponent";
import AllExpensesComponent from "../expenses/AllExpensesComponent";
import HomePageTotalWidgetComponent from "./widgets/HomePageTotalWidgetComponent";
import HomePageMiscExpensesWidgetComponent from "./widgets/HomePageMiscExpensesWidgetComponent";
import HomePageTripsWidgetComponent from "./widgets/HomePageTripsWidgetComponent";
import HomePageSavingsWidgetComponent from "./widgets/HomePageSavingsWidgetComponent";
import {
  getGroupIncomeTotal,
  getUserIncomeTotal,
} from "../../actions/incomeAction";
import { getSavingsTotal } from "../../actions/savingsAction";
import { getSettings } from "../../actions/settingsAction";
import MonthYearComponent from "../shared/month-year-component/MonthYearComponent";
import HomePageChartComponent from "./charts/HomePageChartComponent";
import {
  getCategories,
  getCategoriesMap,
} from "../../actions/categoriesAction";
import ChooseGroupComponent from "../shared/ChooseGroupComponent";
import { getGroupDetails, getGroupUsers } from "../../actions/groupsAction";
import GroupDisplayComponent from "../shared/GroupDisplayComponent";
import { getLoans } from "../../actions/loansAction";
import { getTransactions } from "../../actions/transactionAction";
import { getTrips } from "../../actions/tripAction";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import { getLedgers } from "../../actions/ledgerAction";

class HomeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: undefined,
      expenses: [],
      pieChartData: [],
      lineChartData: [],
      tillDateExpenses: [],
      accounts: [],
      allExpensesView: false,
      isLoading: false,
      mode: undefined,
      groups: [],
    };
    this.fetchData = this.fetchData.bind(this);
    this.showAllExpenses = this.showAllExpenses.bind(this);
  }

  async componentDidMount() {
    if (!this.props.user) {
      this.props.doLogout();
      return;
    }

    // if selectedMonth/year is already set in store, then leave it as is.
    // This is because to persist selected month/year through out the app.
    // Set it only during first time login.
    if (!store.getState().app.selectedMonth) {
      store.dispatch(setSelectedMonth(new Date().getMonth() + 1));
    }
    if (!store.getState().app.selectedYear) {
      store.dispatch(setSelectedYear(new Date().getFullYear()));
    }

    this.fetchData();

    try {
      // A good place to start all saga here.
      store.dispatch(getGroupDetails());
      store.dispatch(getGroupIncomeTotal());
      store.dispatch(getUserIncomeTotal());
      store.dispatch(getSavingsTotal());
      store.dispatch(getAccounts());
      store.dispatch(getSettings());
      store.dispatch(getCategories());
      store.dispatch(getCategoriesMap());
      store.dispatch(getLoans());
      store.dispatch(getTransactions());
      store.dispatch(getTrips());
      store.dispatch(getLedgers());
    } catch (err) {
      if (err.status === 401) {
        this.props.doLogout();
      } else {
        console.error(err);
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.selectedMonth !== prevProps.selectedMonth ||
      this.props.selectedYear !== prevProps.selectedYear ||
      this.props.groupid !== prevProps.groupid
    ) {
      await this.fetchData();
    }
  }

  async fetchData() {
    store.dispatch(getExpensesTotal());
    store.dispatch(getGroupUsers(this.props.groupid));
  }

  showAllExpenses(selectedMonth, selectedCategory, selectedSubCategory) {
    const month = selectedMonth || store.getState().app.selectedMonth;
    this.setState({
      allExpensesView: true,
      selectedMonth: month,
      selectedCategory,
      selectedSubCategory,
    });
  }

  render() {
    if (!this.props.groupid && this.props.groups?.length) {
      return <ChooseGroupComponent />;
    }

    if (!this.props.groupid && this.props.groups?.length === 0) {
      return <FirstTimeLoginComponent />;
    }

    if (this.state.allExpensesView) {
      return (
        <AllExpensesComponent
          onBack={() => this.setState({ allExpensesView: false })}
          doLogout={this.props.doLogout}
          selectedMonth={this.state.selectedMonth}
          selectedCategory={this.state.selectedCategory}
          selectedSubCategory={this.state.selectedSubCategory}
        />
      );
    }

    return (
      <div className="margin-center">
        <GroupDisplayComponent />
        <div className="title">Expenses</div>
        <div className="home-profileSection">
          <MonthYearComponent />

          <div className="divFlex">
            {this.props.user.entitlement > 2 && (
              <Badge
                badgeContent={this.props.expenseErrors?.length || 0}
                color="error"
                className="notifications"
              >
                <Link to="/notifications" style={{ marginLeft: "5px" }}>
                  <MailIcon color="action" />
                </Link>
              </Badge>
            )}
            <div>
              Hi,
              <Link to="/settings/profile" style={{ marginLeft: "5px" }}>
                {this.props.user?.firstname
                  ? this.props.user.firstname
                  : "Guest"}
              </Link>
            </div>
          </div>
        </div>

        <hr className="shadowed" style={{ marginTop: "15px" }} />

        <div>
          <span className="errorMsg">{this.state.errorMsg}</span>
        </div>

        <div className={"home-toprow"}>
          <HomePageTotalWidgetComponent />
          <HomePageMiscExpensesWidgetComponent />
          <HomePageSavingsWidgetComponent />
          <HomePageTripsWidgetComponent />
        </div>

        <HomePageChartComponent />

        <HomePageExpenseListComponent
          expenses={this.state.expenses}
          user={this.props.user}
          selectedMonth={this.props.selectedMonth}
          selectedYear={this.props.selectedYear}
          categories={this.props.categories}
          categoriesMap={this.props.categoriesMap}
          groupid={this.props.groupid}
        />

        <div>
          <TermsConditionsComponent />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, selectedMonth, selectedYear, categories, categoriesMap } =
    state.app;
  const { groupid, groups } = state.group;
  const { expenseErrors } = state.transactions;
  return {
    user,
    selectedMonth,
    selectedYear,
    categories,
    groupid,
    groups,
    expenseErrors,
    categoriesMap,
  };
}

HomeComponent.propTypes = {
  groupid: PropTypes.string,
  groups: PropTypes.array,
  user: PropTypes.any.isRequired,
  doLogout: PropTypes.func.isRequired,
  selectedMonth: PropTypes.any,
  selectedYear: PropTypes.any,
  categories: PropTypes.array,
  expenseErrors: PropTypes.array,
  categoriesMap: PropTypes.array,
};

export default connect(mapStateToProps)(HomeComponent);
