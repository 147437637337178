import React from "react";
import { Link } from "react-router-dom";

export default class FirstTimeLoginComponent extends React.Component {
  render() {
    return (
      <div id="res-not-found">
        <div className="title" style={{ color: "red" }}>
          <span>Ouch!</span>
        </div>

        <div className="subtitle">
          It appears you are accessing a resource you are not entitled to.
          Please go
          <Link to="/home" style={{ marginLeft: "10px" }}>
            back
          </Link>{" "}
          and try again.
        </div>
      </div>
    );
  }
}
