import React from "react";
import PropTypes from "prop-types";
import { Chart } from "react-google-charts";
import { getExpenseCategoryForPieChart } from "../../../services/ExpenseService";
import { connect } from "react-redux";
import store from "../../../store";
import SpinnerComponent from "../../shared/SpinnerComponent";

class HomePagePieChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pieChartQuanitativeFormat: "percent",
      expensesDataPie: [],
      isLoading: false,
    };
    this.togglePieChartQuantitativeFormat =
      this.togglePieChartQuantitativeFormat.bind(this);
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.selectedMonth !== prevProps.selectedMonth ||
      this.props.selectedYear !== prevProps.selectedYear
    ) {
      await this.fetchData();
    }
  }

  async fetchData() {
    try {
      this.setState({ isLoading: true });
      const _selectedMonth = store.getState().app.selectedMonth;
      const _selectedYear = store.getState().app.selectedYear;

      const pieChartData = await getExpenseCategoryForPieChart(
        this.props.groupid,
        _selectedMonth,
        _selectedYear,
      );
      const expensesDataPie = this._populateDataArrayForPieChart(pieChartData);
      this.setState({ expensesDataPie });
    } catch (err) {
      console.error(err);
    }
    this.setState({ isLoading: false });
  }

  _populateDataArrayForPieChart(json) {
    const dataArray = [["category", "total"]];
    for (const d of json) {
      if (d.amount > 0) {
        const tempArray = [];
        tempArray.push(d.category);
        tempArray.push(d.amount);
        dataArray.push(tempArray);
      }
    }
    return dataArray;
  }

  togglePieChartQuantitativeFormat() {
    this.setState({
      pieChartQuanitativeFormat:
        this.state.pieChartQuanitativeFormat === "number"
          ? "percent"
          : "number",
    });
  }

  renderChartComponent() {
    if (this.state.isLoading) {
      return (
        <div
          style={{ width: "100%", height: "100%" }}
          className="divCenterAlign"
        >
          <SpinnerComponent />
        </div>
      );
    }
    return (
      <div>
        <div>
          <a
            className="hyperlink mediumText"
            onClick={() => this.togglePieChartQuantitativeFormat()}
          >
            {this.state.pieChartQuanitativeFormat === "number"
              ? "Show Percent"
              : "Show Value"}
          </a>
        </div>
        <Chart
          width={"500px"}
          height={"300px"}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={this.state.expensesDataPie}
          options={{
            title: "Expense total by category",
            pieSliceText:
              this.state.pieChartQuanitativeFormat === "number"
                ? "value"
                : "percent",
          }}
          rootProps={{ "data-testid": "1" }}
          chartEvents={[
            {
              eventName: "select",
              callback: ({ chartWrapper }) =>
                this.props.onSelect({ chartWrapper }),
            },
          ]}
        />
      </div>
    );
  }

  render() {
    return (
      <div style={{ width: "50%" }} hidden={!this.props.show}>
        {this.renderChartComponent()}
      </div>
    );
  }
}

HomePagePieChartComponent.propTypes = {
  onSelect: PropTypes.func.isRequired,
  user: PropTypes.object,
  selectedMonth: PropTypes.any,
  selectedYear: PropTypes.any,
  show: PropTypes.bool,
};

function mapStateToProps(state) {
  const { user, selectedMonth, selectedYear } = state.app;
  const { groupid } = state.group;
  return { user, selectedMonth, selectedYear, groupid };
}

export default connect(mapStateToProps)(HomePagePieChartComponent);
