import React from "react";
import {
  createLoan,
  getLoanById,
  updateLoan,
} from "../../services/LoanService";
import PropTypes from "prop-types";
import * as DateHelper from "../../helpers/DateHelper";
import TextInputComponent from "../shared/TextInputComponent";
import "./LoansDialogComponent.css";
import { getUsersInGroups2 } from "../../services/GroupService";

export default class CreateLoanDialogComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interestEditText: "",
      startDateEditText: "",
      nameEditText: "",
      amountEditText: "",
      monthsEditText: "",
      isDataValid: false,
      isStartDateValid: false,
      shareWithEnabled: false,
      usersInGroup: [],
    };
    this.shareableUsers = [];
    this.createLoansHandler = this.createLoansHandler.bind(this);
    this.validateStartDate = this.validateStartDate.bind(this);
    this.updateLoansHandler = this.updateLoansHandler.bind(this);
  }

  async componentDidMount() {
    try {
      const usersInGroup = await getUsersInGroups2(
        this.props.user.groupid,
        true,
      );
      this.setState({ usersInGroup });

      if (this.props.editLoanId) {
        const loan = await getLoanById(
          this.props.user.groupid,
          this.props.editLoanId,
        );
        this.setState({
          nameEditText: loan.name,
          amountEditText: loan.amount?.toString(),
          interestEditText: loan.interest?.toString(),
          startDateEditText: DateHelper.getLocalizedDate(
            loan.start_date?.toString(),
            this.props.user,
          ),
          monthsEditText: loan.months?.toString(),
        });
        this.inputValidator();
        this.validateStartDate();
      }
    } catch (err) {
      console.error(err);
      this.props.onFailure(err);
    }
  }

  async createLoansHandler() {
    try {
      await createLoan(
        this.props.user.groupid,
        this.state.nameEditText,
        this.state.amountEditText,
        this.state.monthsEditText,
        this.state.interestEditText,
        this.state.startDateEditText,
        this.shareableUsers,
      );
      this.props.onSuccess();
    } catch (err) {
      console.error(err);
      this.props.onFailure(err);
    }
  }

  async updateLoansHandler() {
    try {
      await updateLoan(
        this.props.user.groupid,
        this.props.editLoanId,
        this.state.nameEditText,
        this.state.amountEditText,
        this.state.monthsEditText,
        this.state.interestEditText,
        this.state.startDateEditText,
        this.shareableUsers,
      );
      this.props.onSuccess();
    } catch (err) {
      console.error(err);
      this.props.onFailure(err);
    }
  }

  inputValidator() {
    if (
      this.state.nameEditText.trim().length &&
      this.state.amountEditText.trim().length &&
      this.state.monthsEditText.length &&
      !isNaN(this.state.interestEditText) &&
      !isNaN(this.state.monthsEditText)
    ) {
      this.setState({ isDataValid: true });
    } else {
      this.setState({ isDataValid: false });
    }
  }

  validateStartDate() {
    const startdate = this.state.startDateEditText;
    const expression =
      this.props.user.country_code === "USA"
        ? /[0-9]{2}\/[0-3][0-9]\/\d{4}/
        : /[0-3][0-9]\/[0-9]{2}\/\d{4}/;
    const isValidDate =
      new RegExp(expression).test(startdate) &&
      !isNaN(new Date(startdate).getTime());

    this.setState({ isStartDateValid: isValidDate });
  }

  handleShareableUsers(user, e) {
    if (e.target.checked === true) {
      this.shareableUsers.push(user.username);
    } else {
      const indexOfUsername = this.shareableUsers.indexOf(user.username);
      this.shareableUsers.splice(indexOfUsername, 1);
    }
  }

  render() {
    return (
      <div className="modal" id="create-loans-dialog">
        <div
          className="modal-content loans-dialog-content"
          style={{ width: this.state.shareWithEnabled ? "40%" : "30%" }}
        >
          <span className="close" onClick={this.props.onCancel}>
            &times;
          </span>
          <div style={{ textAlign: "center" }}>
            <h3> Add Loan</h3>
          </div>
          <div className="divFlex">
            <div>
              <table
                className="formTable"
                style={{ width: "100%", borderSpacing: "20px" }}
              >
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      Description <sup>*</sup>
                    </td>
                    <td style={{ display: "flex" }}>
                      <input
                        type="text"
                        id="loan-name"
                        value={this.state.nameEditText}
                        onChange={(e) =>
                          this.setState(
                            { nameEditText: e.target.value },
                            this.inputValidator,
                          )
                        }
                      />{" "}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {" "}
                      Loan Start Date <sup>*</sup>{" "}
                    </td>
                    <td style={{ display: "flex" }}>
                      <TextInputComponent
                        maxLength={10}
                        type="text"
                        id="loan-start-date"
                        placeholder={
                          this.props.user.country_code === "USA"
                            ? "MM/DD/YYYY"
                            : "DD/MM/YYYY"
                        }
                        onChange={(e) =>
                          this.setState(
                            { startDateEditText: e.target.value },
                            this.validateStartDate,
                          )
                        }
                        value={this.state.startDateEditText}
                        error={
                          !this.state.isStartDateValid
                            ? "Enter valid date."
                            : undefined
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {" "}
                      Loan Amount <sup>*</sup>
                    </td>
                    <td>
                      <TextInputComponent
                        type="text"
                        id="loan-amount"
                        onChange={(e) =>
                          this.setState(
                            { amountEditText: e.target.value },
                            this.inputValidator,
                          )
                        }
                        value={this.state.amountEditText}
                        error={
                          isNaN(this.state.amountEditText)
                            ? "Please enter valid amount."
                            : undefined
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {" "}
                      Interest (%) <sup>*</sup>
                    </td>
                    <td>
                      <TextInputComponent
                        type="text"
                        id="loan-interest"
                        onChange={(e) =>
                          this.setState(
                            { interestEditText: e.target.value },
                            this.inputValidator,
                          )
                        }
                        value={this.state.interestEditText}
                        error={
                          isNaN(this.state.interestEditText)
                            ? "Please enter valid number."
                            : undefined
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {" "}
                      Duration (months) <sup>*</sup>
                    </td>
                    <td>
                      <TextInputComponent
                        type="text"
                        id="loan-months"
                        placeholder="in months.."
                        onChange={(e) =>
                          this.setState(
                            { monthsEditText: e.target.value },
                            this.inputValidator,
                          )
                        }
                        value={this.state.monthsEditText}
                        error={
                          isNaN(this.state.monthsEditText)
                            ? "Please enter valid number."
                            : undefined
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td></td>

                    <td>
                      {!this.state.shareWithEnabled &&
                        this.state.usersInGroup.length > 0 && (
                          <a
                            className="hyperlink"
                            onClick={() =>
                              this.setState({
                                shareWithEnabled: !this.state.shareWithEnabled,
                              })
                            }
                          >
                            {" "}
                            Share with?
                          </a>
                        )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {this.state.shareWithEnabled &&
              this.state.usersInGroup.length > 0 && (
                <div
                  style={{
                    border: "1px solid var(--fnx-light-grey3)",
                    borderRadius: "5px",
                    backgroundColor: "var(--fnx-light-grey3)",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <strong> Users in group</strong>
                  </div>
                  <ul style={{ listStyle: "none" }}>
                    {this.state.usersInGroup.map((user, i) => (
                      <li key={i}>
                        <input
                          type="checkbox"
                          onClick={(e) => this.handleShareableUsers(user, e)}
                        />
                        {user.firstname || user.useremail}{" "}
                      </li>
                    ))}
                  </ul>

                  <div>
                    <a
                      className="hyperlink"
                      onClick={() => this.setState({ shareWithEnabled: false })}
                    >
                      {" "}
                      &#60;&#60; Collapse{" "}
                    </a>
                  </div>
                </div>
              )}
          </div>
          <div style={{ marginTop: "1em", textAlign: "center" }}>
            <input
              id="cancel"
              type="button"
              value="Cancel"
              className="fnxGrey"
              onClick={this.props.onCancel}
            />

            <input
              id="createSaving"
              type="button"
              value={this.props.editLoanId ? "Update" : "Create"}
              className="fnxGreen"
              disabled={!this.state.isDataValid}
              onClick={
                this.props.editLoanId
                  ? this.updateLoansHandler
                  : this.createLoansHandler
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

CreateLoanDialogComponent.propTypes = {
  user: PropTypes.any.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  editLoanId: PropTypes.string,
};
